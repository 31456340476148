import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

const Loader = () => {
  return (
    <ClipLoader
      color={"#484848"}
      css="display: block; margin: 0 auto;"
    />
  );
};

Loader.propTypes = {
  
};

export default Loader;