import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../core/context"
import DeleteSessionModal from "./DeleteSessionModal";
import Dropdown from "../../core/components/Dropdown";
import editIcon from "../../images/edit.svg";
import deleteIcon from "../../images/delete.svg";
import { timeString, durationString } from "../../core/time";
import classNames from "classnames";

const SessionRow = props => {
  /**
   * A row representing a session.
   */

  const {session, category, setShowingDropdown, showCategory, selected, setSelectedSession} = props;
  
  const [showModal, setShowModal] = useState(false);

  const user = useContext(UserContext);

  const deleteClicked = () => {
    setShowModal(true);
  }

  const links = [
    {href: `/time/sessions/${session.id}/edit/`, icon: editIcon, text: "Edit"},
    {action: deleteClicked, icon: deleteIcon, text: "Delete"}
  ]

  const className = classNames({"session-row": true, selected, selectable: setSelectedSession});
  let time = timeString(session.start * 1000, session.timezone, user.timezone);
  if (showCategory) {
    let end = timeString(
      (session.start + (session.duration * 60) )* 1000, session.timezone, user.timezone
    ).split(" ").slice(4).join(" ");
    time = time.split(" ").slice(1).join(" ") + " - " + end;
  }
  return (
    <div className={className} onClick={() => setSelectedSession && setSelectedSession(session.id)}>
      {showCategory && <div className="cell value">
        <div className="circle" style={{backgroundColor: session.color}} />
      </div>}
      <div className="cell value">{durationString(session.duration)}</div>
      <div className="cell">{time}</div>
      
      <div className="cell description">{session.description}</div>
      <div className="cell">
        <Dropdown links={links} setShowingDropdown={setShowingDropdown} />
      </div>
      <DeleteSessionModal
        category={category}
        session={session}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </div>
  );
}

SessionRow.propTypes = {
  session: PropTypes.object.isRequired,
  category: PropTypes.object.isRequired,
  setShowingDropdown: PropTypes.func.isRequired
}

export default SessionRow;