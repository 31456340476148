import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import classnames from "classnames";
import { DELETE_USER } from "../mutations";
import { TokenContext } from "../context";
import WhiteBox from "../components/WhiteBox";
import { createErrorObject } from "../forms";

const UserDeletionForm = () => {
  /**
   * An interface for letting a user delete their account.
   */

  const [showDangerZone, setShowDangerZone] = useState(false);
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({password: "", general: ""});
  const setToken = useContext(TokenContext);

  const [deleteUser, deleteUserMutation] = useMutation(DELETE_USER, {
    onError: ({graphQLErrors}) => {
      setErrors(createErrorObject(errors, graphQLErrors));
    },
    onCompleted: () => {
      setToken(null);
      localStorage.removeItem("token");
      window.location.href = "/"
    }
  });
  
  const submitDeletion = e => {
    e.preventDefault();
    deleteUser({variables: {password}})
  }

  const className = classnames({
    "user-deletion-form": true, cocked: showDangerZone
  })
  

  return (
    <WhiteBox className={className}>
      <h2>Danger Zone</h2>
      <button className="delete" onClick={() => setShowDangerZone(!showDangerZone)}>Delete Account</button>
      <div className="danger-zone">
        <p>
          Are you sure you want to delete your account? This can't be undone.
        </p>
        <div className="options">
          <div className="stay">
            <button onClick={() => setShowDangerZone(false)}>No, stay</button>
          </div>
          <form className="go" onSubmit={submitDeletion}>
            {errors.general && <div className="general-error error">{errors.general}</div>}
            {errors.password && <div className="error">{errors.password}</div>}
            <input
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              disabled={deleteUserMutation.loading}
              placeholder={"Password"}
              autoComplete="none"
              required={true}
            />
            <input type="submit" value="Yes, delete" />

          </form>
        </div>
      </div>
    </WhiteBox>
  )
}

export default UserDeletionForm;