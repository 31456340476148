import gql from "graphql-tag";

export const FOOD = gql`query food($id: ID!) {
  user { food(id: $id) {
    id name notes isPortion portionDefinition units calories carbohydrates
    sugar protein fat fibre salt
  } }
}`;

export const FOOD_HISTORY = gql`query foodHistory($id: ID!) {
  user { food(id: $id) {
    id isPortion portionDefinition units
    series { name units resolution seriesType data {
      datetime timezone value components { datetime timezone value }
    } }
  } }
}`;

export const MEAL = gql`query meal($id: ID!) { user {
  meal(id: $id) { id datetime timezone notes rawCalories rawCarbohydrates
    rawSugar rawProtein rawFat rawFibre rawSalt components { edges { node {
    id amount isPortion food { id name units }
  } } } }
  foods { edges { node {
    id name isPortion portionDefinition units 
  } } }
} }`;

export const FOODS = gql`query {
  user { foods { edges { node {
    id name isPortion portionDefinition units
  } } } }
}`;

export const FOOD_DAY = gql`query foodDay($date: String) {
  user { meals(day: $date) {
    calories carbohydrates sugar protein fat fibre salt
  } }
}`;

export const FOOD_DAY_WITH_MEALS = gql`query foodDay($date: String) {
  user { meals(day: $date) {
    calories carbohydrates sugar protein fat fibre salt edges { node {
      id datetime timezone notes calories carbohydrates sugar protein fat
      fibre salt components { edges { node {
        id amount isPortion food { id name units }
      } } }
    } }
  } }
}`;

export const NUTRIENT = gql`query nutrientQuery($name: String!) {
  user { nutrient(name: $name) {
    series { name units resolution seriesType data {
      datetime timezone value components { datetime timezone value }
    } }
  } }
}`;