import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { useMutation } from "@apollo/react-hooks";
import Select from "react-select";
import Toggle from "react-toggle";
import classNames from "classnames";
import moment from "moment-timezone";
import { ACCOUNTS, ACCOUNT, PINNED_ACCOUNTS } from "../queries";
import { CREATE_ACCOUNT, UPDATE_ACCOUNT } from "../mutations";
import WhiteBox from "../../core/components/WhiteBox";
import { createErrorObject } from "../../core/forms";
import DatetimeInput from "../../core/components/DatetimeInput";
import { getCurrentDay } from "../../core/time";
import { UserContext } from "../../core/context";
import { getCurrencySymbol } from "../../core/numbers";

const AccountForm = props => {
  /**
   * An interface for creating or editing a account.
   */

  const account = props.account;

  const history = useHistory();
  const [name, setName] = useState(account ? account.name : "");
  const [description, setDescription] = useState(account ? account.description : "");
  const [currency, setCurrency] = useState(account ? account.currency : "");
  const [startDate, setStartDate] = useState(account ? moment(account.startDate).valueOf() : "");
  const [startBalance, setStartBalance] = useState(account ? account.startBalance : 0);
  const [hasTimes, setHasTimes] = useState(account ? account.hasTimes : false);
  const [pinned, setPinned] = useState(account ? account.pinned : true);
  const [errors, setErrors] = useState({general: ""});
  
  // Values to display without existing measurement until the user selects something
  const user = useContext(UserContext);
  const currentDay = moment.utc(getCurrentDay(user, "D")).valueOf();

  const [createAccount, createAccountMutation] = useMutation(CREATE_ACCOUNT, {
    onError: ({graphQLErrors}) => {
      setErrors(createErrorObject(errors, graphQLErrors));
    },
    refetchQueries: [{query: ACCOUNTS, variables: {last: 150}}, {query: PINNED_ACCOUNTS}],
    onCompleted: ({createAccount: {account: {id}}}) => {
      history.push(`/money/${id}/`);
    }
  });

  const [updateAccount, updateAccountMutation] = useMutation(UPDATE_ACCOUNT, {
    onError: ({graphQLErrors}) => {
      setErrors(createErrorObject(errors, graphQLErrors));
    },
    refetchQueries: [
      {query: ACCOUNTS}, {query: PINNED_ACCOUNTS},
      {query: ACCOUNT, variables: {id: account && account.id}}
    ],
    onCompleted: ({updateAccount: {account: {id}}}) => {
      history.push(`/money/${id}/`);
    }
  });

  const formSubmit = e => {
    e.preventDefault();
    if (account) {
      updateAccount({
        variables: {
          id: account.id, name, currency, description, pinned, startBalance, hasTimes,
          startDate: moment(parseInt(startDate || currentDay)).format("YYYY-MM-DD")
        }
      });
    } else {
      createAccount({
        variables: {
          name, currency, description, pinned, startBalance, hasTimes,
          startDate: moment(parseInt(startDate || currentDay)).format("YYYY-MM-DD")
        }
      });
    }
  }

  const currencies = [
    {value: "GBP", label: "GBP (£)"}, {value: "USD", label: "USD ($)"},
    {value: "EUR", label: "EUR (€)"},
  ]
  const actualCurrency = currency ? currencies.filter(c => c.value === currency)[0] : null;
  const className = classNames({"account-form": true, "model-form": true});
  return (
    <WhiteBox className={className}>
      {account ? <h1>Edit Account</h1> : <h1>Create New Account</h1>}

      <form onSubmit={formSubmit}>
        {errors.general && <div className="general-error error">{errors.general}</div>}
        <div className="top-row">
          <div className="input">
            <label htmlFor="name">Account Name</label>
            {errors.name && <div className="error">{errors.name}</div>}
            <input
              id="name"
              value={name}
              onChange={e => setName(e.target.value)}
              disabled={createAccountMutation.loading || updateAccountMutation.loading}
              required
              placeholder="e.g Current, credit card, savings etc."
            />
          </div>
          <div className="input">
            <label>Currency:</label>
            <Select
              options={currencies}
              value={actualCurrency}
              onChange={option => setCurrency(option.value)}
              className="react-select"
              classNamePrefix="react-select"
            />
          </div>
        </div>
        <div className="input">
          <label htmlFor="name">Description</label>
          {errors.description && <div className="error">{errors.description}</div>}
          <input
            id="description"
            value={description}
            onChange={e => setDescription(e.target.value)}
            disabled={createAccountMutation.loading || updateAccountMutation.loading}
          />
        </div>

        <p>
          We need to know any single date from the lifetime of the account and the balance at the <em>start</em> of that day.
          It could be the date it was opened, today, or any day in between.
        </p>


        <div className="start-row">      
          <div className="input">
            <label htmlFor="name">Date</label>
            {errors.description && <div className="error">{errors.startDate}</div>}
            <DatetimeInput
              id="date"
              resolution={"D"}
              datetime={!startDate ? currentDay : startDate}
              timezone="UTC"
              setDatetime={setStartDate}
              setTimezone={() => {}}
              disabled={createAccountMutation.loading || updateAccountMutation.loading}
            />
          </div>

          <div className="input">
            <label htmlFor="name">Balance on Date</label>
            {errors.description && <div className="error">{errors.startBalance}</div>}
            <input
              id="startBalance"
              value={startBalance}
              onChange={e => setStartBalance(e.target.value)}
              type="number"
              step="0.01"
              disabled={createAccountMutation.loading || updateAccountMutation.loading}
            />
          </div>

        </div>

        <div className="toggle-row">
          <div className="input toggle-input">
            <Toggle
                id="pinned"
                checked={pinned}
                onChange={() => setPinned(!pinned)}
            />
            <label htmlFor="pinned">Pinned Account</label>
          </div>

          {!account && <div className="input toggle-input">
            <Toggle
                id="hasTimes"
                checked={hasTimes}
                onChange={() => setHasTimes(!hasTimes)}
            />
            <label htmlFor="pinned">Transactions Have Times</label>
          </div>}

        </div>

    

        <input
          type="submit"
          value="Save" 
          disabled={createAccountMutation.loading || updateAccountMutation.loading}
        />
      </form>

    </WhiteBox>
  )
}

AccountForm.propTypes = {
  account: PropTypes.object,
}

export default AccountForm;