import React, { useEffect } from "react";
import Base from "../components/Base";

const BaseNotFoundPage = () => {
  /**
   * The page which shows when logged in and the url doesn't match.
   */

  useEffect(() => {
    document.title = "Page Not Found - lytiko";
  });

  return (
    <Base className="base-not-found-page">
      Page Not Found
    </Base>
  )
}

export default BaseNotFoundPage;