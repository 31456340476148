import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import WhiteBox from "../../core/components/WhiteBox";
import TablePaginator from "../../core/components/TablePaginator";
import SessionRow from "./SessionRow";

const SessionsTable = props => {
  /**
   * A list of sessions.
   */

  const {
    category, tablePage, setTablePage, rowsPerPage, setRowsPerPage, ascending,
    showCategory, selectedSession, setSelectedSession
  } = props;

  const sessions = category.sessions.edges.map(edge => edge.node);
  sessions.sort((s1, s2) => ascending ? (s1.start - s2.start) : (s2.start - s1.start));

  const sessionsToDisplay = sessions.slice(
    (tablePage - 1) * rowsPerPage, tablePage * rowsPerPage
  )

  const [showingDropdown, setShowingDropdown] = useState(false);
  const className = classNames({
    "table-container": true, "showing-dropdown": showingDropdown
  })
  
  return (
    <WhiteBox className="sessions-table">
      {sessions.length > rowsPerPage && <TablePaginator
        rowCount={sessions.length}
        tablePage={tablePage} setTablePage={setTablePage}
        rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage}
      />}
      <div className={className}>
        <div className="table">
          {sessionsToDisplay.map(session => {
            return (
              <SessionRow
                session={session}
                category={category}
                key={session.id}
                setShowingDropdown={setShowingDropdown}
                showCategory={showCategory}
                selected={selectedSession === session.id}
                setSelectedSession={setSelectedSession}
              />
            )
          })}
        </div>
      </div>
      {sessions.length > rowsPerPage && <TablePaginator
        rowCount={sessions.length}
        tablePage={tablePage} setTablePage={setTablePage}
        rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage}
      />}
    </WhiteBox>
  )
}

SessionsTable.propTypes = {
  category: PropTypes.object.isRequired,
  tablePage: PropTypes.number.isRequired,
  setTablePage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
}

export default SessionsTable;