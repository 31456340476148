import gql from "graphql-tag";

export const USER = gql`{ user {
  email firstName lastName dateJoined timezone dayEnds weekStartsMonday
  showQuantitySummaries
  caloriesOnHome carbohydratesOnHome sugarOnHome proteinOnHome fatOnHome
  fibreOnHome saltOnHome
  showEmptyTimeCategories
  showAccountSummaries
} }`;

export const POSSIBLE_COMPARITORS = gql`{ user {
  quantities(empty: false) { edges { node { id name } } }
  foods(empty: false) { edges { node { id name } } }
  meals { count }
  timeCategories(empty: false) { edges { node { id name } } }
  accounts(empty: false) { edges { node { id name } } }
}}`;

export const COMPARISON = gql`query comparison(
  $entity1: String!, $entity2: String!
) {
  user { comparison(entity1: $entity1, entity2: $entity2) {
    series1 { seriesType name units resolution data { datetime timezone value } }
    series2 { seriesType name units resolution data { datetime timezone value } }
    correlation
  }}
}`;