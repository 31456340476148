import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/react-hooks";
import { QUANTITY, QUANTITIES, PINNED_QUANTITIES } from "../queries";
import { DELETE_MEASUREMENT } from "../mutations";
import Modal from "../../core/components/Modal";
import alertIcon from "../../images/alert.svg";

const DeleteMeasurementModal = props => {
  /**
   * A full page interface asking if a measurement should be deleted.
   */

  const { showModal, setShowModal, measurement, quantity } = props;
  const [error, setError] = useState("");

  const [deleteMeasurement, deleteMeasurementMutaion] = useMutation(DELETE_MEASUREMENT, {
    onError: () => {
      setError("Sorry there was an error, quantity couldn't be deleted.");
    },
    refetchQueries: [
      {query: QUANTITY, variables: {id: quantity.id}},
      {query: QUANTITIES, variables: {last: 150}}, {query: PINNED_QUANTITIES}
    ],
    onCompleted: () => {
      setShowModal(false);
    }
  });

  const formSubmit = e => {
    e.preventDefault();
    deleteMeasurement({
      variables: {id: measurement.id}
    });
  }

  return (
    <Modal setShowModal={setShowModal} showModal={showModal} className="delete-modal delete-measurement-modal">
      <form onSubmit={formSubmit}>
        <img src={alertIcon} className="icon" alt="alert" />
        {error && <div className="error">{error}</div>}
        <div className="proposal">Delete Measurement '{measurement.value}{quantity.units}'</div>
        <div className="warning">This will permanently delete the measurement.</div>
        <input type="submit" value="Delete" disabled={deleteMeasurementMutaion.loading} />
      </form>
    </Modal>
  )
}

DeleteMeasurementModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  measurement: PropTypes.object.isRequired,
  quantity: PropTypes.object.isRequired
}

export default DeleteMeasurementModal;







