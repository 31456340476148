import gql from "graphql-tag";

export const CREATE_QUANTITY = gql`mutation(
  $name: String!, $units: String, $description: String, 
  $pinned: Boolean!, $seriesType: Int! $resolution: String
) {
  createQuantity(
    name: $name, units: $units, description: $description, pinned: $pinned
    seriesType: $seriesType, resolution: $resolution
  ) {
    quantity { id name units description pinned resolution seriesType }
  }
}`;

export const UPDATE_QUANTITY = gql`mutation(
  $id: ID!, $name: String!, $units: String, $description: String,
  $pinned: Boolean!, $seriesType: Int!
) {
  updateQuantity(
    id: $id, name: $name, units: $units, description: $description,
    pinned: $pinned, seriesType: $seriesType
  ) {
    quantity { id name units description pinned resolution seriesType }
  }
}`;

export const DELETE_QUANTITY = gql`mutation($id: ID!) {
  deleteQuantity(id: $id) {success}
}`;


export const CREATE_MEASUREMENT = gql`mutation(
  $quantity: ID!, $value: Float!, $timezone: String!, $datetime: Int!, $notes: String
) {
  createMeasurement(
    quantity: $quantity, value: $value, datetime: $datetime,
    timezone: $timezone, notes: $notes
  ) { measurement { id } }
}`;

export const UPDATE_MEASUREMENT = gql`mutation(
  $id: ID!, $value: Float!, $timezone: String!, $datetime: Int!, $notes: String
) {
  updateMeasurement(
    id: $id, value: $value, datetime: $datetime,
    timezone: $timezone, notes: $notes
  ) {
    measurement { id quantity { id } }
  }
}`;

export const DELETE_MEASUREMENT = gql`mutation($id: ID!) {
  deleteMeasurement(id: $id) {success}
}`;


export const UPDATE_QUANTITIES_SETTINGS = gql`mutation($showQuantitySummaries: Boolean!) {
  updateQuantitiesSettings(showQuantitySummaries: $showQuantitySummaries) {
    user { showQuantitySummaries }
  }
}`;