import React, { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { TIME_CATEGORY } from "../queries";
import Base from "../../core/components/Base";
import BaseNotFoundPage from "../../core/pages/BaseNotFoundPage";
import WhiteBox from "../../core/components/WhiteBox";
import TimeCategoryForm from "../components/TimeCategoryForm";

const EditTimeCategoryPage = () => {
  /**
   * Page for editing a time category
   */

  const categoryId = useRouteMatch("/time/:id/edit").params.id;

  const {loading, data, error} = useQuery(TIME_CATEGORY, {variables: {id: categoryId}});
  const category = loading || error ? {sessions: {edges: []}} : data.user.timeCategory;

  
  useEffect(() => {
    if (!loading) {
      document.title = `${category.name} - lytiko`;
    }
  });

  if (error) {
    try {
      const message = JSON.parse(error.graphQLErrors[0].message);
      if (message.quantity === "Does not exist") {
        return <BaseNotFoundPage />
      }
    } catch {  }
  }

  if (loading) {
    return (
      <Base className="edit-time-category-page">
        <WhiteBox className="time-category-form" loading={true} />
      </Base>
    )
  }

  return (
    <Base className="edit-time-category-page">
      <TimeCategoryForm category={category} loading={loading} />
    </Base>
  )
}

export default EditTimeCategoryPage;