import React, { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import ClipLoader from "react-spinners/ClipLoader";
import { PINNED_QUANTITIES } from "../../quantities/queries";
import { FOOD_DAY } from "../../food/queries";
import { PINNED_TIME_CATEGORIES } from "../../time/queries";
import Quantity from "../../quantities/components/Quantity";
import NutrientGrid from "../../food/components/NutrientGrid";
import TimeCategory from "../../time/components/TimeCategory";
import Account from "../../money/components/Account";
import Base from "../components/Base";
import { getCurrentDay } from "../time";
import { UserContext } from "../context";
import { PINNED_ACCOUNTS } from "../../money/queries";

const HomePage = () => {
  /**
   * The starting page.
   */

  const user = useContext(UserContext);

  const {loading, data} = useQuery(PINNED_QUANTITIES);
  const quantities = loading ? [] : data.user.quantities.edges.map(edge => edge.node);
  
  const currentDay = getCurrentDay(user);
  const foodQuery = useQuery(FOOD_DAY, {
    variables: {date: currentDay}, fetchPolicy: "network-only"
  });

  const timeQuery = useQuery(PINNED_TIME_CATEGORIES, {
    variables: {date: currentDay}, fetchPolicy: "network-only"
  });
  const timeCategories = timeQuery.loading ? [] : timeQuery.data.user.timeCategories.edges.map(edge => edge.node);

  const moneyQuery = useQuery(PINNED_ACCOUNTS, {fetchPolicy: "network-only"});
  const accounts = moneyQuery.loading ? [] : moneyQuery.data.user.accounts.edges.map(edge => edge.node);
  
  
  useEffect(() => {
    document.title = "lytiko";
  });

  return (
    <Base className="home-page">
      <section className="insights">
        <h2>Insights</h2>
        <div><Link to="/compare/">Compare Two Quantities</Link></div>

      </section>

      <section>
        <h2>Pinned Quantities</h2>
        {
          loading ? <ClipLoader /> : quantities.length ? (
            <div className="quantity-grid">
              {quantities.map(quantity => {
                return (
                  <Quantity
                    key={quantity.id}
                    quantity={quantity}
                    link={true}
                    showSummary={user.showQuantitySummaries}
                  />
                )
              })}
            </div>
          ) : (
            <div className="empty">
              Currently no quantities being tracked. <Link to="/quantities/new/">Add one.</Link>
            </div>
          )
        }
      </section>

      <section>
        <h2>Food Today</h2>
        {
          !foodQuery.data ? <ClipLoader /> :  (
            <NutrientGrid
              calories={user.caloriesOnHome ? foodQuery.data.user.meals.calories : undefined}
              carbohydrates={user.carbohydratesOnHome ? foodQuery.data.user.meals.carbohydrates : undefined}
              sugar={user.sugarOnHome ? foodQuery.data.user.meals.sugar : undefined}
              protein={user.proteinOnHome ? foodQuery.data.user.meals.protein : undefined}
              fat={user.fatOnHome ? foodQuery.data.user.meals.fat : undefined}
              fibre={user.fibreOnHome ? foodQuery.data.user.meals.fibre : undefined}
              salt={user.saltOnHome ? foodQuery.data.user.meals.salt : undefined}
            />
          )
        }
      </section>

      <section>
        <h2>Pinned Time Tracking</h2>
        {
          timeQuery.loading ? <ClipLoader /> : timeCategories.length ? (
            <div className="time-category-grid-home">
              {timeCategories.map(category => {
                return (
                  <TimeCategory
                    key={category.id}
                    category={category}
                    link={true}
                  />
                )
              })}
            </div>
          ) : (
            <div className="empty">
              Currently no time categories being tracked. <Link to="/time/new/">Add one.</Link>
            </div>
          )
        }
      </section>

      <section>
        <h2>Pinned Accounts</h2>
        {
          moneyQuery.loading ? <ClipLoader /> : accounts.length ? (
            <div className="account-grid">
              {accounts.map(account => {
                return (
                  <Account
                    key={account.id}
                    account={account}
                    link={true}
                    showSummary={user.showAccountSummaries}
                  />
                )
              })}
            </div>
          ) : (
            <div className="empty">
              Currently no accounts being tracked. <Link to="/money/new/">Add one.</Link>
            </div>
          )
        }
      </section>

    </Base>
  )
}

export default HomePage;