import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useQuery } from "@apollo/react-hooks";
import queryString from "query-string";
import moment from "moment-timezone";
import { TIME_CATEGORIES_FOR_DAY } from "../queries";
import Base from "../../core/components/Base";
import DatetimeInput from "../../core/components/DatetimeInput";
import TimeCategoryGrid from "../components/TimeCategoryGrid";
import QuickAddSessionModal from "../components/QuickAddSessionModal";
import { UserContext } from "../../core/context";
import Loader from "../../core/components/Loader";
import { getCurrentDay } from "../../core/time";
import Timelines from "../components/Timelines";
import SessionsTable from "../components/SessionsTable";

const TimePage = () => {
  /**
   * The general time page.
   */

  const history = useHistory();
  const params = queryString.parse(history.location.search);
  let date = params.date;

  const user = useContext(UserContext);
  date = date || getCurrentDay(user);

  const [selectedSession, setSelectedSession] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const { data, loading } = useQuery(TIME_CATEGORIES_FOR_DAY, {
    variables: {date: date}, skip: date === undefined, fetchPolicy: "network-only"
  });
  const categories = !data ? [] : data.user.timeCategories.edges.map(edge => edge.node);
  const dayTimestamp = moment.utc(date).valueOf();

  // Get a list of all sessions sorted by start time
  const allSessions = categories.reduce(
    (prev, curr) => [...prev, ...curr.sessions.edges.map(edge => ({node: {...edge.node, color: curr.color}}))], []
  ).sort((a, b) => b.start - a.start);

  const changeDate = datetime => {
    const newDate = moment(datetime).tz("UTC").format("YYYY-MM-DD");
    history.push(`/time?date=${newDate}`);
  }

  useEffect(() => {
    document.title = "Time - lytiko";
  });

  const keyDown = e => {
    if (e.keyCode === 37 && !e.ctrlKey && !e.shiftKey) {
      if (!document.querySelector(".quick-add-session-modal.displayed")) {
        const newDate = moment.utc(date).subtract(1, "day");
        document.removeEventListener("keydown", keyDown, false);
        changeDate(newDate);
      }
    }
    if (e.keyCode === 39 && !e.ctrlKey && !e.shiftKey) {
      if (!document.querySelector(".quick-add-session-modal.displayed")) {
        const newDate = moment.utc(date).add(1, "day");
        document.removeEventListener("keydown", keyDown, false);
        changeDate(newDate);
      }
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", keyDown, false);
    return () => document.removeEventListener("keydown", keyDown, false);
  }, [date]);

  useEffect(() => {
    const removeSelectedSession = e => {
      if (!e.target.classList.contains("session") && !e.target.classList.contains("cell")) {
        setSelectedSession(null);
      }
    };
    document.addEventListener("click", removeSelectedSession, false);
    return () => document.removeEventListener("click", removeSelectedSession, false);
  })

  if (!data || loading) {
    return (
      <Base className="time-page">
        <Loader />
      </Base>
    )
  }

  return (
    <Base className="time-page" onKeyDown={keyDown}>
      <div className="heading">
        <h1>{moment(date).format("dddd D MMMM, YYYY")}</h1>
        <DatetimeInput
          id="date"
          resolution="D"
          datetime={dayTimestamp}
          timezone="UTC"
          setDatetime={changeDate}
          setTimezone={() => {}}
          disabled={false}
          closeOnChange={true}
        />
        {(categories.length || null ) && (
          <button className="quick-add" onClick={() => setShowModal(true)}>Quick Add</button>
        )}
      </div>

      <QuickAddSessionModal
        showModal={showModal}
        setShowModal={setShowModal}
        categories={categories}
        date={date}
      />

      <TimeCategoryGrid categories={categories} />

      <Timelines
        categories={categories}
        selectedSession={selectedSession}
        setSelectedSession={setSelectedSession}
      />

      <SessionsTable 
        category={{sessions: {edges: allSessions}}}
        tablePage={1}
        setTablePage={() => {}}
        rowsPerPage={100000}
        setRowsPerPage={() => {}}
        ascending={true}
        showCategory={true}
        selectedSession={selectedSession}
        setSelectedSession={setSelectedSession}
      />
    </Base>
  )
}

export default TimePage;