import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { useMutation } from "@apollo/react-hooks";
import { ACCOUNTS, PINNED_ACCOUNTS } from "../queries";
import { DELETE_ACCOUNT } from "../mutations";
import alertIcon from "../../images/alert.svg";
import Modal from "../../core/components/Modal";

const DeleteAccountModal = props => {
  /**
   * A full page interface asking if a account should be deleted.
   */

  const { showModal, setShowModal, account } = props;
  const history = useHistory();
  const [error, setError] = useState("");

  const [deleteAccount, deleteAccountMutaion] = useMutation(DELETE_ACCOUNT, {
    onError: () => {
      setError("Sorry there was an error, account couldn't be deleted.");
    },
    refetchQueries: [{query: ACCOUNTS}, {query: PINNED_ACCOUNTS}],
    onCompleted: () => {
      history.push("/money/");
    }
  });

  const formSubmit = e => {
    e.preventDefault();
    deleteAccount({variables: {id: account.id}});
  }

  //const measurementCount = account.measurements && account.measurements.edges.length;

  return (
    <Modal setShowModal={setShowModal} showModal={showModal} className="delete-modal delete-account-modal">
      <form onSubmit={formSubmit}>
        <img src={alertIcon} className="icon" alt="alert" />
        {error && <div className="error">{error}</div>}
        <div className="proposal">Delete Account '{account.name}'</div>
        <div className="warning">This will permanently delete account.</div>
        {/* <div className="warning">This will permanently delete account and its {measurementCount} measurement{measurementCount !== 1 && "s"}</div> */}
        <input type="submit" value="Delete" disabled={deleteAccountMutaion.loading} />
      </form>
    </Modal>
  )
}


DeleteAccountModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired
}

export default DeleteAccountModal;







