import React, { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { MEAL } from "../queries";
import Base from "../../core/components/Base";
import MealForm from "../components/MealForm";
import WhiteBox from "../../core/components/WhiteBox";

const EditMealPage = () => {
  /**
   * Page for editing meals.
   */

  const mealId = useRouteMatch("/food/meals/:id/edit/").params.id;

  const { loading, data } = useQuery(MEAL, {variables: {id: mealId}});
  const meal = !data ? {} : data.user.meal;
  const foods = !data? [] : data.user.foods.edges.map(edge => edge.node);
  
  useEffect(() => {
    document.title = "Edit Meal - lytiko";
  });

  if (loading) {
    return (
      <Base className="edit-meal-page">
        <WhiteBox loading={true} />
      </Base>
    )
  }

  return (
    <Base className="edit-meal-page">
      <MealForm foods={foods} meal={meal} />
    </Base>
    
  )
}

export default EditMealPage;