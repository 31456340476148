import React, { useState, useEffect, useRef } from "react";
import { useQuery } from "@apollo/react-hooks";
import { USER } from "../queries";
import userIcon from "../../images/user-icon.svg";
import NavDropdown from "./NavDropdown";

const Nav = () => {
  /**
   * The navbar at the top of every logged in page.
   */

  const { loading, data } = useQuery(USER);
  const user = loading ? {} : data.user;

  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownElement = useRef(null);

  const iconClicked = e => {
    e.stopPropagation();
    setShowDropdown(!showDropdown);
  }

  const clickOutside = e => {
    if (dropdownElement.current && !dropdownElement.current.contains(e.target)) {
      setShowDropdown(false);
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickOutside)
  });

  return (
    <nav className={showDropdown ? "show-dropdown" : ""}>
      <div ref={dropdownElement} className="nav-dropdown-container">
        <img onClick={iconClicked} src={userIcon} alt="user-icon"  />
        <NavDropdown user={user} />
      </div>
    </nav>
  )
}

export default Nav;