import React, { useState, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { QUANTITY, QUANTITY_SERIES } from "../queries";
import Quantity from "../components/Quantity";
import WhiteBox from "../../core/components/WhiteBox";
import MeasurementForm from "../components/MeasurementForm";
import QuantityChart from "../components/QuantityChart";
import MeasurementsTable from "../components/MeasurementsTable";
import Base from "../../core/components/Base";
import BaseNotFoundPage from "../../core/pages/BaseNotFoundPage";

const QuantityPage = () => {
  /**
   * Information about a specific quantity
   */

  const quantityId = useRouteMatch("/quantities/:id").params.id;

  const [requestedResolution, setRequestedResolution] = useState(0); 
  const [tablePage, setTablePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const {loading, data, error} = useQuery(QUANTITY, {variables: {id: quantityId}});
  const quantity = loading || error ? {measurements: {edges: []}} : data.user.quantity;

  const additionalSeriesQuery = useQuery(QUANTITY_SERIES, {
    variables: {id: quantityId, resolution: requestedResolution},
    skip: requestedResolution === 0
  });
  const additionalSeries = additionalSeriesQuery.data ? additionalSeriesQuery.data.user.quantity.series : null;

  
  
  useEffect(() => {
    if (!loading && !error) {
      document.title = `${quantity.name} - lytiko`;
    }
  });

  if (error) {
    try {
      const message = JSON.parse(error.graphQLErrors[0].message);
      if (message.quantity === "Does not exist") {
        return <BaseNotFoundPage />
      }
    } catch {  }
  }

  if (loading) {
    return (
      <Base className="quantity-page">
        <div className="quantity-page-top">
          <WhiteBox loading={true} />
          <WhiteBox loading={true} />
        </div>
        <WhiteBox className="lytiko-chart" loading={true} />
        <WhiteBox className="lytiko-chart" loading={true} />
      </Base>
    )
  }

  return (
    <Base className="quantity-page">
      <div className="quantity-page-top">
        <Quantity link={false} quantity={quantity} />
        <MeasurementForm quantity={quantity} />
      </div>
      <QuantityChart
        quantity={quantity}
        requestedResolution={requestedResolution}
        setRequestedResolution={setRequestedResolution}
        additionalSeries={additionalSeries}
        seriesLoading={additionalSeriesQuery.loading}
      />
      <MeasurementsTable
        quantity={quantity}
        tablePage={tablePage} setTablePage={setTablePage}
        rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage}
      />
    </Base>
  )
}

export default QuantityPage;