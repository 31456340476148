import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/react-hooks";
import { USER } from "../queries";
import { UPDATE_NAME } from "../mutations";
import Modal from "../../core/components/Modal";

const NameFormModal = props => {
  /**
   * A modal for editing the user's name.
   */

  const { showModal, setShowModal, user } = props;

  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [error, setError] = useState("");

  const [updateName, updateNameMutation] = useMutation(UPDATE_NAME, {
    onError: () => {
      setError("Sorry there was an error, name couldn't be updated.");
    },
    refetchQueries: [{query: USER}],
    onCompleted: () => {
      setShowModal(false);
      setError("")
    }
  });

  const formSubmit = e => {
    e.preventDefault();
    updateName({variables: {firstName, lastName}});
  }

  return (
    <Modal setShowModal={setShowModal} showModal={showModal} className="name-form-modal">
      {error && <div className="error">{error}</div>}
      <form onSubmit={formSubmit}>
        <input
          type="text"
          value={firstName}
          onChange={e => setFirstName(e.target.value)}
          required={true}
          disabled={updateNameMutation.loading}
          placeholder="First name"
        />
        <input
          type="text"
          value={lastName}
          onChange={e => setLastName(e.target.value)}
          required={true}
          disabled={updateNameMutation.loading}
          placeholder="Last name"
        />
        
        <input type="submit" value="Save" disabled={updateNameMutation.loading} />
      </form>
    </Modal>
  )
}


NameFormModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
}

export default NameFormModal;