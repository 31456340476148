import React from "react";
import PropTypes from "prop-types";
import Div100vh from "react-div-100vh";
import classNames from "classnames";
import WhiteBox from "../../core/components/WhiteBox";
import closeIcon from "../../images/close.svg";

const Modal = props => {
  /**
   * A white box hovering over the page that can be dismissed.
   */

  const { setShowModal, showModal } = props;
  const classes = {modal: true, displayed: showModal};
  if (props.className) classes[props.className] = true;
  const className = classNames(classes);

  return (
    <Div100vh className={className} onClick={() => setShowModal(false)}>
      <WhiteBox className="modal-box" onClick={e => e.stopPropagation()}>
        <img src={closeIcon} className="close" alt="close" onClick={() => setShowModal(false)}/>
        {props.children}
      </WhiteBox>
    </Div100vh>
  )
}

Modal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  className: PropTypes.string
}

export default Modal;