import React, { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { SESSION } from "../queries";
import Base from "../../core/components/Base";
import WhiteBox from "../../core/components/WhiteBox";
import SessionForm from "../components/SessionForm";
import BaseNotFoundPage from "../../core/pages/BaseNotFoundPage";

const EditSessionPage = () => {
  /**
   * Page for editing a session
   */

  const sessionId = useRouteMatch("/time/sessions/:id/edit/").params.id;
  const {loading, data, error} = useQuery(SESSION, {variables: {id: sessionId}});
  const session = loading || error ? {} : data.user.session;

  useEffect(() => {
    if (!loading && !error) {
      document.title = `${session.notes} - lytiko`;
    }
  });

  if (error) {
    try {
      const message = JSON.parse(error.graphQLErrors[0].message);
      if (message.session === "Does not exist") {
        return <BaseNotFoundPage />
      }
    } catch {  }
  }

  if (loading) {
    return (
      <Base className="edit-session-page">
        <WhiteBox className="session-form" loading={true} />
      </Base>
    )
    
  }

  return (
    <Base className="edit-session-page">
      <SessionForm session={session} category={session.category} />
    </Base>
  )
}

export default EditSessionPage;