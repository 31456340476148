import React, { useState } from "react";
import { Switch, Route } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider, useQuery } from "@apollo/react-hooks";
import { TokenContext, UserContext } from "../context";
import { makeClient } from "../api";
import { USER } from "../queries";
import Base from "./Base";
import LoginPage from "../pages/LoginPage";
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage";
import LandingPage from "../pages/LandingPage";
import HomePage from "../pages/HomePage";
import SettingsPage from "../pages/SettingsPage";
import ComparePage from "../pages/ComparePage";
import NewQuantityPage from "../../quantities/pages/NewQuantityPage";
import QuantityPage from "../../quantities/pages/QuantityPage";
import QuantitiesPage from "../../quantities/pages/QuantitiesPage";
import EditQuantityPage from "../../quantities/pages/EditQuantityPage";
import EditMeasurementPage from "../../quantities/pages/EditMeasurementPage";
import NutritionPage from "../../food/pages/NutritionPage";
import NewFoodPage from "../../food/pages/NewFoodPage";
import NewMealPage from "../../food/pages/NewMealPage";
import EditMealPage from "../../food/pages/EditMealPage";
import EditFoodPage from "../../food/pages/EditFoodPage";
import FoodPage from "../../food/pages/FoodPage";
import NotFoundPage from "../pages/NotFoundPage";
import BaseNotFoundPage from "../pages/BaseNotFoundPage";
import NutrientPage from "../../food/pages/NutrientPage";
import TimePage from "../../time/pages/TimePage";
import NewTimeCategoryPage from "../../time/pages/NewTimeCategoryPage";
import TimeCategoryPage from "../../time/pages/TimeCategoryPage";
import EditSessionPage from "../../time/pages/EditSessionPage";
import EditTimeCategoryPage from "../../time/pages/EditTimeCategoryPage";
import MoneyPage from "../../money/pages/MoneyPage";
import NewAccountPage from "../../money/pages/NewAccountPage";
import EditAccountPage from "../../money/pages/EditAccountPage";
import AccountPage from "../../money/pages/AccountPage";
import EditTransactionPage from "../../money/pages/EditTransactionPage";

export default () => {

  const [token, setToken] = useState(localStorage.getItem("token"));
  const client = makeClient();

  if (token) {
    return (
      <TokenContext.Provider value={setToken}>
          <ApolloProvider client={client}>
            <ApolloApp />
          </ApolloProvider>
      </TokenContext.Provider>
    );
  }
  
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact>
            <LandingPage setToken={setToken} />
          </Route>
          <Route path="/login/" exact>
            <LoginPage setToken={setToken} />
          </Route>
          <Route path="/policy/" exact>
            <PrivacyPolicyPage />
          </Route>
          <Route component={NotFoundPage} />
        </Switch>
      </BrowserRouter>
    </ApolloProvider>
  );
}


const ApolloApp = () => {

  const { loading, data } = useQuery(USER);

  if (loading) {
    return <BrowserRouter><Base loading={true}/></BrowserRouter>
  }

  return (
    <UserContext.Provider value={data.user}>
      <BrowserRouter>
        <Switch>
          <Route path="/" exact>
            <HomePage />
          </Route>
          <Route path="/policy/" exact>
            <PrivacyPolicyPage />
          </Route>
          <Route path="/settings/" exact>
            <SettingsPage />
          </Route>
          <Route path="/compare/" exact>
            <ComparePage />
          </Route>

          <Route path="/quantities/new/" exact>
            <NewQuantityPage />
          </Route>
          <Route path="/quantities/:id/edit/" exact>
            <EditQuantityPage />
          </Route>
          <Route path="/quantities/measurements/:id/edit/" exact>
            <EditMeasurementPage />
          </Route>
          <Route path="/quantities/:id/" exact>
            <QuantityPage />
          </Route>
          <Route path="/quantities/" exact>
            <QuantitiesPage />
          </Route>

          <Route path="/food/new/" exact>
            <NewFoodPage />
          </Route>
          <Route path="/food/:id/edit/" exact>
            <EditFoodPage />
          </Route>
          <Route path="/food/meals/:id/" exact>
            <NewMealPage />
          </Route>
          <Route path="/food/meals/:id/edit/" exact>
            <EditMealPage />
          </Route>
          <Route path="/food/:id/" exact>
            <FoodPage />
          </Route>
          <Route path="/food/nutrient/(calories|carbohydrates|sugar|protein|fat|fibre|salt)/">
            <NutrientPage />
          </Route>
          <Route path="/food/" exact>
            <NutritionPage />
          </Route>

          <Route path="/time/new/" exact>
            <NewTimeCategoryPage />
          </Route>
          <Route path="/time/sessions/:id/edit/" exact>
            <EditSessionPage />
          </Route>
          <Route path="/time/:id/" exact>
            <TimeCategoryPage />
          </Route>
          <Route path="/time/:id/edit/" exact>
            <EditTimeCategoryPage />
          </Route>
          <Route path="/time/" exact>
            <TimePage />
          </Route>

          <Route path="/money/new/" exact>
            <NewAccountPage />
          </Route>
          <Route path="/money/:id/" exact>
            <AccountPage />
          </Route>
          <Route path="/money/:id/edit/" exact>
            <EditAccountPage />
          </Route>
          <Route path="/money/transactions/:id/edit/" exact>
            <EditTransactionPage />
          </Route>
          <Route path="/money/" exact>
            <MoneyPage />
          </Route>
          

          <Route component={BaseNotFoundPage} />
        </Switch>
      </BrowserRouter>
    </UserContext.Provider>
  )
}