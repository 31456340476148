import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { useMutation } from "@apollo/react-hooks";
import { DELETE_TIME_CATEGORY } from "../mutations";
import Modal from "../../core/components/Modal";
import alertIcon from "../../images/alert.svg";

const DeleteTimeCategoryModal = props => {
  /**
   * A full page interface asking if a time category should be deleted.
   */

  const { showModal, setShowModal, category } = props;
  const history = useHistory();
  const [error, setError] = useState("");

  const [deleteTimeCategory, deleteTimeCategoryMutaion] = useMutation(DELETE_TIME_CATEGORY, {
    onError: () => {
      setError("Sorry there was an error, category couldn't be deleted.");
    },
    onCompleted: () => {
      history.push("/time/");
    }
  });

  const formSubmit = e => {
    e.preventDefault();
    deleteTimeCategory({
      variables: {id: category.id}
    });
  }

  return (
    <Modal setShowModal={setShowModal} showModal={showModal} className="delete-modal delete-time-category-modal">
      <form onSubmit={formSubmit}>
        <img src={alertIcon} className="icon" alt="alert" />
        {error && <div className="error">{error}</div>}
        <div className="proposal">Delete Time Category '{category.name}'</div>
        <div className="warning">This will permanently delete the category and all its sessions.</div>
        <input type="submit" value="Delete" disabled={deleteTimeCategoryMutaion.loading} />
      </form>
    </Modal>
  )
}

DeleteTimeCategoryModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  category: PropTypes.object.isRequired
}

export default DeleteTimeCategoryModal;







