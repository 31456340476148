import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/react-hooks";
import Toggle from "react-toggle";
import "react-toggle/style.css"
import { USER } from "../../core/queries";
import { UPDATE_FOOD_SETTINGS } from "../mutations";
import WhiteBox from "../../core/components/WhiteBox";
import { createErrorObject } from "../../core/forms";

const FoodSettingsForm = props => {
  /**
   * An interface for letting a user modify their food settings.
   */

  const { user } = props;
  const loading = Object.keys(user).length === 0;

  const [errors, setErrors] = useState({
    caloriesOnHome: "", carbohydratesOnHome: "", sugarOnHome: "",
    proteinOnHome: "", fatOnHome: "", fibreOnHome: "", saltOnHome: "",
    general: ""
  });

  const [updateFoodSettings] = useMutation(UPDATE_FOOD_SETTINGS, {    
    onError: ({graphQLErrors}) => {
      setErrors(createErrorObject(errors, graphQLErrors));
    },
    onCompleted: () => setErrors("")
  });

  if (loading) {
    return <WhiteBox loading={true} />
  }

  const nutrientOnHomeToggled = e => {
    e.persist();
    updateFoodSettings({
      variables: {...{
        caloriesOnHome: user.caloriesOnHome,
        carbohydratesOnHome: user.carbohydratesOnHome,
        sugarOnHome: user.sugarOnHome,
        proteinOnHome: user.proteinOnHome,
        fatOnHome: user.fatOnHome,
        fibreOnHome: user.fibreOnHome,
        saltOnHome: user.saltOnHome
      }, [e.target.id]: e.target.checked},
      optimisticResponse: {
        updateFoodSettings: {
          __typename: "updateFoodSettingsMutation",
          user: {...{
            __typename: "UserType", caloriesOnHome: user.caloriesOnHome,
            carbohydratesOnHome: user.carbohydratesOnHome,
            sugarOnHome: user.sugarOnHome, proteinOnHome: user.proteinOnHome,
            fatOnHome: user.fatOnHome, fibreOnHome: user.fibreOnHome,
            saltOnHome: user.saltOnHome
          }, [e.target.id]: e.target.checked},
        }
      },
      update: (proxy) => {
        let newData = proxy.readQuery({ query: USER});
        newData.user.caloriesOnHome = user.caloriesOnHome;
        newData.user.carbohydratesOnHome = user.carbohydratesOnHome;
        newData.user.sugarOnHome = user.sugarOnHome;
        newData.user.proteinOnHome = user.proteinOnHome;
        newData.user.fatOnHome = user.fatOnHome;
        newData.user.fibreOnHome = user.fibreOnHome;
        newData.user.saltOnHome = user.saltOnHome;
        newData.user[e.target.id] = e.target.checked;
        proxy.writeQuery({ query: USER, data: newData});
      }
    })
  }

  return (
    <WhiteBox className="basic-settings-form">
      <h2>Food Settings</h2>

      {errors.general && <div className="general-error error">{errors.general}</div>}
      {errors.caloriesOnHome && <div className="error">{errors.caloriesOnHome}</div>}
      {errors.carbohydratesOnHome && <div className="error">{errors.carbohydratesOnHome}</div>}
      {errors.sugarOnHome && <div className="error">{errors.sugarOnHome}</div>}
      {errors.proteinOnHome && <div className="error">{errors.proteinOnHome}</div>}
      {errors.fatOnHome && <div className="error">{errors.fatOnHome}</div>}
      {errors.fibreOnHome && <div className="error">{errors.fibreOnHome}</div>}
      {errors.saltOnHome && <div className="error">{errors.saltOnHome}</div>}

      <div className="inputs">
        <div className="input toggle-input">
          <Toggle
              id="caloriesOnHome"
              checked={user.caloriesOnHome}
              onChange={nutrientOnHomeToggled}
          />
          <label htmlFor="caloriesOnHome">Show Calories on Home Page</label>
        </div>
        <div className="input toggle-input">
          <Toggle
              id="carbohydratesOnHome"
              checked={user.carbohydratesOnHome}
              onChange={nutrientOnHomeToggled}
          />
          <label htmlFor="carbohydratesOnHome">Show Carbohydrates on Home Page</label>
        </div>
        <div className="input toggle-input">
          <Toggle
              id="sugarOnHome"
              checked={user.sugarOnHome}
              onChange={nutrientOnHomeToggled}
          />
          <label htmlFor="sugarOnHome">Show Sugar on Home Page</label>
        </div>
        <div className="input toggle-input">
          <Toggle
              id="proteinOnHome"
              checked={user.proteinOnHome}
              onChange={nutrientOnHomeToggled}
          />
          <label htmlFor="proteinOnHome">Show Protein on Home Page</label>
        </div>
        <div className="input toggle-input">
          <Toggle
              id="fatOnHome"
              checked={user.fatOnHome}
              onChange={nutrientOnHomeToggled}
          />
          <label htmlFor="fatOnHome">Show Fat on Home Page</label>
        </div>
        <div className="input toggle-input">
          <Toggle
              id="fibreOnHome"
              checked={user.fibreOnHome}
              onChange={nutrientOnHomeToggled}
          />
          <label htmlFor="fibreOnHome">Show Fibre on Home Page</label>
        </div>
        <div className="input toggle-input">
          <Toggle
              id="saltOnHome"
              checked={user.saltOnHome}
              onChange={nutrientOnHomeToggled}
          />
          <label htmlFor="saltOnHome">Show Salt on Home Page</label>
        </div>
      </div>
    </WhiteBox>
  )
}

FoodSettingsForm.propTypes = {
  user: PropTypes.object.isRequired
}

export default FoodSettingsForm;