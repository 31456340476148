import React, { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { MEASUREMENT } from "../queries";
import Base from "../../core/components/Base";
import WhiteBox from "../../core/components/WhiteBox";
import MeasurementForm from "../components/MeasurementForm";
import BaseNotFoundPage from "../../core/pages/BaseNotFoundPage";

const EditMeasurementPage = () => {
  /**
   * Page for editing a measurement
   */

  const measurementId = useRouteMatch("/quantities/measurements/:id/edit/").params.id;
  const {loading, data, error} = useQuery(MEASUREMENT, {variables: {id: measurementId}});
  const measurement = loading || error ? {} : data.user.measurement;

  useEffect(() => {
    if (!loading && !error) {
      document.title = `${measurement.value}${measurement.quantity.units} - lytiko`;
    }
  });

  if (error) {
    try {
      const message = JSON.parse(error.graphQLErrors[0].message);
      if (message.measurement === "Does not exist") {
        return <BaseNotFoundPage />
      }
    } catch {  }
  }

  if (loading) {
    return (
      <Base className="edit-measurement-page">
        <WhiteBox className="measurement-form" loading={true} />
      </Base>
    )
    
  }

  return (
    <Base className="edit-measurement-page">
      <MeasurementForm measurement={measurement} quantity={measurement.quantity} />
    </Base>
  )
}

export default EditMeasurementPage;