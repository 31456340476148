import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import Toggle from "react-toggle";
import classNames from "classnames";
import { UserContext } from "../../core/context";
import { seriesToMovingAverage, createSeries, createChartConf } from "../../core/charts";
import LytikoChart from "../../core/components/LytikoChart";
import WhiteBox from "../../core/components/WhiteBox";

const TimeCategoryChart = props => {
  /**
   * A data visualisation for a time category.
   */

  const { category } = props;
  const user = useContext(UserContext);
  const [showTrendline, setShowTrendline] = useState(true);

  if (category.series.data.length < 1) {
    return (
      <WhiteBox className="lytiko-chart">
        <div className="notice">Need more data to display chart.</div>
      </WhiteBox>
    )
  }

  // Create series
  const series = createSeries(category.series, {
    fillGaps: true, totals: true, dayEnds: user.dayEnds
  });
  series.color = category.color + "C0";
  series.borderColor = category.color + "C0";
  const options = createChartConf(user.timezone || moment.tz.guess());
  options.series = [series];
  options.timeChart = true;

  // Enough dates for a trendline?
  const canShowTrendline = series.data.filter(d => d[1] > 0).length > (0.15 * series.data.length);
  if (canShowTrendline && showTrendline) {
    options.series.push(seriesToMovingAverage(series))
  }

  const className = classNames({
    "time-category-chart": true,
    "has-trendline": canShowTrendline && showTrendline,
  })
  
  return (
    <WhiteBox className={className}>
      <LytikoChart options={options} />
      {canShowTrendline && <div className="chart-controls">
        <div className="toggle-input">
          <Toggle
              id="trendline"
              checked={showTrendline}
              onChange={() => setShowTrendline(!showTrendline)}
          />
          <label htmlFor="trendline">Show Trendline</label>
        </div>
      </div>}
    </WhiteBox>
  )
}

TimeCategoryChart.propTypes = {
  category: PropTypes.object.isRequired,
}

export default TimeCategoryChart;