import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { useMutation } from "@apollo/react-hooks";
import moment from "moment";
import { TIME_CATEGORY, TIME_CATEGORY_SESSIONS } from "../queries";
import { CREATE_SESSION, UPDATE_SESSION } from "../mutations";
import WhiteBox from "../../core/components/WhiteBox";
import DatetimeInput from "../../core/components/DatetimeInput";
import { createErrorObject } from "../../core/forms";
import { UserContext } from "../../core/context";

const SessionForm = props => {
  /**
   * An interface for creating or editing a session.
   */

  const history = useHistory();
  const {category, session} = props;

  // Values to display without existing session until the user selects something
  const user = useContext(UserContext);
  const currentTimezone = user.timezone || moment.tz.guess();

  const [description, setDescription] = useState(session ? session.description : "");
  const [currentDatetime,] = useState(moment().set("second", 0).set("millisecond", 0).valueOf());
  const [start, setStart] = useState(session ? session.start * 1000 : null);
  const [end, setEnd] = useState(session ? (session.start + session.duration * 60) * 1000 + session.duration: null);
  const [timezone, setTimezone] = useState(session ? session.timezone : null);
  const [errors, setErrors] = useState({
    description: "", start: "", duration: "", timezone: ""
  });

  const [createSession, createSessionMutation] = useMutation(CREATE_SESSION, {
    onError: ({graphQLErrors}) => {
      setErrors(createErrorObject(errors, graphQLErrors));
    },
    refetchQueries: [
      {query: TIME_CATEGORY, variables: {id: category.id}},
      {query: TIME_CATEGORY_SESSIONS, variables: {id: category.id}},
    ],
    onCompleted: () => {
      setDescription("");
      setErrors(Object.keys(errors).reduce(
        (o, key) => Object.assign(o, {[key]: ""}), {}
      ));
    }
  });

  const [updateSession, updateSessionMutation] = useMutation(UPDATE_SESSION, {
    onError: ({graphQLErrors}) => {
      setErrors(createErrorObject(errors, graphQLErrors));
    },
    refetchQueries: [
      {query: TIME_CATEGORY, variables: {id: category.id}},
      {query: TIME_CATEGORY_SESSIONS, variables: {id: category.id}},
    ],
    onCompleted: ({updateSession: {session: {category: {id}}}}) => {
      history.push(`/time/${id}/`);
    }
  });

  const formSubmit = e => {
    e.preventDefault();
    const actualStart = (start || currentDatetime) / 1000;
    const actualEnd = (end || currentDatetime) / 1000;
    const duration = parseInt((actualEnd - actualStart) / 60);
    
    if (session) {
      updateSession({
        variables: {
          description,
          start: actualStart,
          duration: duration,
          timezone: timezone || currentTimezone,
          id: session.id
        }
      });
    } else {
      createSession({
        variables: {
          duration: duration,
          description,
          start: actualStart,
          timezone: timezone || currentTimezone,
          category: category.id
        }
      });
    }

  }

  return (
    <WhiteBox className="session-form model-form">
      {session && <h1>Edit Session</h1>}
      <form onSubmit={formSubmit}>
        {errors.general && <div className="general-error error">{errors.general}</div>}
        <div className="top-row">
          <div className="input">
            <label htmlFor="start">Start</label>
            {errors.datetime && <div className="error">{errors.datetime}</div>}
            <DatetimeInput
              id="start"
              resolution={category.resolution}
              datetime={start === null ? currentDatetime : start}
              timezone={timezone === null ? currentTimezone : timezone}
              setDatetime={setStart}
              setTimezone={setTimezone}
              hideWeekday={true}
              disabled={createSessionMutation.loading || updateSessionMutation.loading}
            />
          </div>
          <div className="input">
            <label htmlFor="end">End</label>
            {errors.duration && <div className="error">{errors.duration}</div>}
            <DatetimeInput
              id="end"
              resolution={category.resolution}
              datetime={end === null ? currentDatetime : end}
              timezone={timezone === null ? currentTimezone : timezone}
              setDatetime={setEnd}
              setTimezone={setTimezone}
              hideWeekday={true}
              disabled={createSessionMutation.loading || updateSessionMutation.loading}
            />
          </div>

        </div>
        
        <div className="bottom-row">
          <div className="input">
            <label htmlFor="description">Description</label>
            {errors.description && <div className="error">{errors.description}</div>}
            <input
              id="description"
              value={description}
              onChange={e => setDescription(e.target.value)}
              disabled={createSessionMutation.loading || updateSessionMutation.loading}
            />
          </div>
          
          <input
            type="submit"
            value={session ? "Save": "Add"} 
            disabled={createSessionMutation.loading || updateSessionMutation.loading}
          />
        </div>
        
      </form>
    </WhiteBox>
  )
}

SessionForm.propTypes = {
  session: PropTypes.object,
  category: PropTypes.object.isRequired
}

export default SessionForm;