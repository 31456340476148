import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { useMutation } from "@apollo/react-hooks";
import moment from "moment";
import { ACCOUNT, ACCOUNT_TRANSACTIONS, ACCOUNTS, PINNED_ACCOUNTS } from "../queries";
import { CREATE_TRANSACTION, UPDATE_TRANSACTION } from "../mutations";
import WhiteBox from "../../core/components/WhiteBox";
import DatetimeInput from "../../core/components/DatetimeInput";
import { createErrorObject } from "../../core/forms";
import { getCurrentDay } from "../../core/time";
import { UserContext } from "../../core/context";
import { ClipLoader } from "react-spinners";

const TransactionForm = props => {
  /**
   * An interface for creating or editing a transaction.
   */

  const history = useHistory();
  const {account, transaction, refetch} = props;

  // Values to display without existing transaction until the user selects something
  const user = useContext(UserContext);
  const currentDatetime = moment().set("second", 0).set("millisecond", 0).valueOf();
  const currentTimezone = user.timezone || moment.tz.guess();
  const currentDay = moment.utc(getCurrentDay(user, account.hasTimes ? null : "D")).valueOf();

  const [amount, setAmount] = useState(transaction ? transaction.amount : "");
  const [description, setDescription] = useState(transaction ? transaction.description : "");
  const [datetime, setDatetime] = useState(transaction ? transaction.datetime * 1000 : null);
  const [timezone, setTimezone] = useState(transaction ? transaction.timezone : null);
  const [errors, setErrors] = useState({
    value: "", notes: "", datetime: "", timezone: ""
  });

  const [createTransaction, createTransactionMutation] = useMutation(CREATE_TRANSACTION, {
    onError: ({graphQLErrors}) => {
      setErrors(createErrorObject(errors, graphQLErrors));
    },
    refetchQueries: [
      {query: ACCOUNT, variables: {id: account.id}},
      {query: ACCOUNTS, variables: {last: 150}}, {query: PINNED_ACCOUNTS}
    ],
    onCompleted: () => {
      setAmount("");
      setDescription("");
      setDatetime(null);
      setTimezone(null);
      setErrors(Object.keys(errors).reduce(
        (o, key) => Object.assign(o, {[key]: ""}), {}
      ));
      refetch();
    }
  });

  const [updateTransaction, updateTransactionMutation] = useMutation(UPDATE_TRANSACTION, {
    onError: ({graphQLErrors}) => {
      setErrors(createErrorObject(errors, graphQLErrors));
    },
    refetchQueries: [
      {query: ACCOUNT, variables: {id: account.id}},
      {query: ACCOUNT_TRANSACTIONS, variables: {id: account.id}},
      {query: ACCOUNTS, variables: {last: 150}}, {query: PINNED_ACCOUNTS}
    ],
    onCompleted: ({updateTransaction: {transaction: {account: {id}}}}) => {
      history.push(`/money/${id}/`);
    }
  });

  const formSubmit = e => {
    e.preventDefault();
    if (transaction) {
      updateTransaction({
        variables: {
          amount,
          description,
          datetime: (datetime || (!account.hasTimes ? currentDay : currentDatetime)) / 1000,
          timezone: timezone || (!account.hasTimes ? "UTC" : currentTimezone),
          id: transaction.id
        }
      });
    } else {
      createTransaction({
        variables: {
          amount,
          description,
          datetime: (datetime || (!account.hasTimes ? currentDay : currentDatetime)) / 1000,
          timezone: timezone || (!account.hasTimes ? "UTC" : currentTimezone),
          account: account.id
        }
      });
    }

  }

  return (
    <WhiteBox className="transaction-form model-form">
      {transaction && <h1>Edit Transaction</h1>}
      <form onSubmit={formSubmit}>
        {errors.general && <div className="general-error error">{errors.general}</div>}
        <div className="input">
          <label htmlFor="date">Date</label>
          {errors.datetime && <div className="error">{errors.datetime}</div>}
          <DatetimeInput
            id="date"
            resolution={account.hasTimes ? null : "D"}
            datetime={datetime === null ? !account.hasTimes ? currentDay : currentDatetime : datetime}
            timezone={timezone === null ? !account.hasTimes ? "UTC" : currentTimezone : timezone}
            setDatetime={setDatetime}
            setTimezone={setTimezone}
            disabled={createTransactionMutation.loading || updateTransactionMutation.loading}
          />
        </div>
        <div className="top-row">
          <div className="input">
            <label htmlFor="amount">Amount</label>
            {errors.value && <div className="error">{errors.amount}</div>}
            <input
              id="amount"
              value={amount}
              type="number"
              step="0.01"
              onChange={e => setAmount(e.target.value)}
              required={true}
              disabled={createTransactionMutation.loading || updateTransactionMutation.loading}
            />
          </div>

          <div className="input">
            <label htmlFor="notes">Description</label>
            {errors.description && <div className="error">{errors.description}</div>}
            <input
              id="description"
              value={description}
              onChange={e => setDescription(e.target.value)}
              disabled={createTransactionMutation.loading || updateTransactionMutation.loading}
            />
          </div>
        </div>

        <button>
          {(
            createTransactionMutation.loading || updateTransactionMutation.loading
          ) ? <ClipLoader size="15px" color="white" /> : transaction ? "Save": "Add"} 
        </button>
      </form>
    </WhiteBox>
  )
}

TransactionForm.propTypes = {
  transaction: PropTypes.object,
  account: PropTypes.object.isRequired
}

export default TransactionForm;