import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/react-hooks";
import { ACCOUNT, ACCOUNTS, PINNED_ACCOUNTS } from "../queries";
import { DELETE_TRANSACTION } from "../mutations";
import Modal from "../../core/components/Modal";
import alertIcon from "../../images/alert.svg";

const DeleteTransactionModal = props => {
  /**
   * A full page interface asking if a transaction should be deleted.
   */

  const { showModal, setShowModal, transaction, account, refetch } = props;
  const [error, setError] = useState("");

  const [deleteTransaction, deleteTransactionMutaion] = useMutation(DELETE_TRANSACTION, {
    onError: () => {
      setError("Sorry there was an error, account couldn't be deleted.");
    },
    refetchQueries: [
      {query: ACCOUNT, variables: {id: account.id}},
      {query: ACCOUNTS, variables: {last: 150}}, {query: PINNED_ACCOUNTS}
    ],
    onCompleted: () => {
      setShowModal(false);
      refetch();
    }
  });

  const formSubmit = e => {
    e.preventDefault();
    deleteTransaction({
      variables: {id: transaction.id}
    });
  }

  return (
    <Modal setShowModal={setShowModal} showModal={showModal} className="delete-modal delete-transaction-modal">
      <form onSubmit={formSubmit}>
        <img src={alertIcon} className="icon" alt="alert" />
        {error && <div className="error">{error}</div>}
        <div className="proposal">Delete Transaction '{transaction.amount} {account.currency}'</div>
        <div className="warning">This will permanently delete the transaction.</div>
        <input type="submit" value="Delete" disabled={deleteTransactionMutaion.loading} />
      </form>
    </Modal>
  )
}

DeleteTransactionModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  transaction: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired
}

export default DeleteTransactionModal;







