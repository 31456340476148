import gql from "graphql-tag";

export const SIGNUP = gql`mutation(
  $firstName: String! $lastName: String! $email: String! $password: String!
) { 
  signup(
    firstName: $firstName, lastName: $lastName
    email: $email, password: $password
  ) {
    token user { email }
  }
}`;

export const LOGIN = gql`mutation($email: String! $password: String!) { 
  login(email: $email, password: $password) {
    token user { email }
  }
}`;

export const UPDATE_NAME = gql`mutation($firstName: String!, $lastName: String!) {
  updateName(firstName: $firstName, lastName: $lastName) { firstName lastName }
}`

export const UPDATE_EMAIL = gql`mutation($email: String!, $password: String!) {
  updateEmail(email: $email, password: $password) { email }
}`

export const UPDATE_PASSWORD = gql`mutation($current: String!, $new: String!) {
  updatePassword(current: $current, new: $new) { success }
}`

export const UPDATE_BASIC_SETTINGS = gql`mutation(
  $dayEnds: Int!, $weekStartsMonday: Boolean, $timezone: String
) {
  updateBasicSettings(
    dayEnds: $dayEnds, timezone: $timezone, weekStartsMonday: $weekStartsMonday
  ) { user { timezone dayEnds weekStartsMonday } }
}`;

export const DELETE_USER = gql`mutation($password: String!) {
  deleteUser(password: $password) { success }
}`