import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const LytikoChart = props => {

  const { options, className } = props;
  const chartClass = classNames({
    "lytiko-chart": true, className: className
  })

  return (
    <div className={chartClass}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

LytikoChart.propTypes = {
  options: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default LytikoChart;