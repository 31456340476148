import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/react-hooks";
import ClipLoader from "react-spinners/ClipLoader";
import Select from "react-select";
import Toggle from "react-toggle";
import "react-toggle/style.css"
import moment from "moment-timezone";
import { USER } from "../queries";
import { UPDATE_BASIC_SETTINGS } from "../mutations";
import WhiteBox from "../components/WhiteBox";
import { createErrorObject } from "../forms";

const BasicSettingsForm = props => {
  /**
   * An interface for letting a user modify their base settings.
   */

  const { user } = props;
  const loading = Object.keys(user).length === 0;

  const [errors, setErrors] = useState({
    day_ends: "", timezone: "", weekStartsMonday: "", general: ""
  });

  const [updateBasicSettings] = useMutation(UPDATE_BASIC_SETTINGS, {    
    onError: ({graphQLErrors}) => {
      setErrors(createErrorObject(errors, graphQLErrors));
    },
    onCompleted: () => setErrors("")
  });

  if (loading) {
    return (
      <WhiteBox className="basic-settings-form">
        <ClipLoader css="display: block; margin: 0 auto;"/>
      </WhiteBox>
    )
  }
  
  const dayEndOptions = [  
    {value: 0, label: "Midnight"}, {value: 1, label: "1am"},
    {value: 2, label: "2am"}, {value: 3, label: "3am"},
    {value: 4, label: "4am"}, {value: 5, label: "5am"},
  ]

  const dayEndSelected = selection => {
    updateBasicSettings({
      variables: {
        dayEnds: selection.value,
        timezone: user.timezone,
        weekStartsMonday: user.weekStartsMonday
      },
      optimisticResponse: {
        updateBasicSettings: {
          __typename: "UpdateBasicSettingsMutation",
          user: {
            __typename: "UserType", 
            timezone: user.timezone, dayEnds: selection.value,
            weekStartsMonday: user.weekStartsMonday
          }
        }
      },
      update: (proxy) => {
        const newData = proxy.readQuery({ query: USER});
        newData.user.dayEnds = selection.value;
        proxy.writeQuery({ query: USER, data: newData});
      }
    })
  }

  const weekStartsOptions = [  
    {value: false, label: "Sunday"}, {value: true, label: "Monday"},
  ]
  const selectedWeekStartOption = weekStartsOptions[user.weekStartsMonday ? 1 : 0]

  const weekStartsSelected = selection => {
    updateBasicSettings({
      variables: {
        dayEnds: user.dayEnds,
        timezone: user.timezone,
        weekStartsMonday: selection.value
      },
      optimisticResponse: {
        updateBasicSettings: {
          __typename: "UpdateBasicSettingsMutation",
          user: {
            __typename: "UserType", 
            dayEnds: user.dayEnds, timezone: user.timezone, 
            weekStartsMonday: selection.value,
          }
        }
      },
      update: (proxy) => {
        const newData = proxy.readQuery({ query: USER});
        newData.user.weekStartsMonday = selection.value;
        proxy.writeQuery({ query: USER, data: newData});
      }
    })
  }

  const timezoneOptions = moment.tz.names().map(
    name => {return {value: name, label: name}}
  );

  const timezoneToggled = e => {
    const timezone = !e.target.checked ? moment.tz.guess() : null;
    updateBasicSettings({
      variables: {
        dayEnds: user.dayEnds,
        timezone: timezone,
        weekStartsMonday: user.weekStartsMonday
      },
      optimisticResponse: {
        updateBasicSettings: {
          __typename: "UpdateBasicSettingsMutation",
          user: {
            __typename: "UserType", timezone, dayEnds: user.dayEnds,
            weekStartsMonday: user.weekStartsMonday
          }
        }
      },
      update: (proxy) => {
        const newData = proxy.readQuery({ query: USER});
        newData.user.timezone = timezone;
        proxy.writeQuery({ query: USER, data: newData});
      }
    })
  }

  const timezoneSelected = selection => {
    updateBasicSettings({
      variables: {
        dayEnds: user.dayEnds,
        timezone: selection.value
      },
      optimisticResponse: {
        updateBasicSettings: {
          __typename: "UpdateBasicSettingsMutation",
          user: {
            __typename: "UserType", 
            timezone: selection.value, dayEnds: user.dayEnds,
          }
        }
      },
      update: (proxy) => {
        const newData = proxy.readQuery({ query: USER});
        newData.user.timezone = selection.value;
        proxy.writeQuery({ query: USER, data: newData});
      }
    })
  }

  return (
    <WhiteBox className="basic-settings-form">
      <h2>Basic Settings</h2>

      {errors.general && <div className="general-error error">{errors.general}</div>}
      {errors.day_ends && <div className="error">{errors.day_ends}</div>}
      {errors.weekStartsMonday && <div className="error">{errors.weekStartsMonday}</div>}
      {errors.timezone && <div className="error">{errors.timezone}</div>}

      <div className="inputs">

        <div className="grid-2">
          <div className="input">
            <label>Day Ends:</label>
            <Select
              options={dayEndOptions}
              value={dayEndOptions[user.dayEnds]}
              onChange={dayEndSelected}
              className="react-select"
              classNamePrefix="react-select"
            />
          </div>
          <div className="input">
            <label>Week Begins:</label>
            <Select
              options={weekStartsOptions}
              value={selectedWeekStartOption}
              onChange={weekStartsSelected}
              className="react-select"
              classNamePrefix="react-select"
            />
          </div>
        </div>
        
        
        <div className="input toggle-input">
          <Toggle
            id="timezone"
            checked={!user.timezone}
            onChange={timezoneToggled}
            />
            <label htmlFor="timezone">Use Device Timezone</label>
        </div>

        {
          user.timezone && (
            <div className="input">
              <label>Timezone:</label>
              <Select
                options={timezoneOptions}
                value={{value: user.timezone, label: user.timezone}}
                onChange={timezoneSelected}
                className="react-select"
                classNamePrefix="react-select"
              />
            </div>
          )
        }
      </div>
    </WhiteBox>
  )
}

BasicSettingsForm.propTypes = {
  user: PropTypes.object.isRequired
}

export default BasicSettingsForm;