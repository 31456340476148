import gql from "graphql-tag";

export const ACCOUNT = gql`query account($id: ID!) {
  user { account(id: $id) {
    id name currency description pinned hasTimes startDate startBalance latestBalance
    series { seriesType units resolution data {
      datetime timezone value components { datetime timezone value }
    } }
  } }
}`;

export const ACCOUNT_TRANSACTIONS = gql`query account($id: ID!, $offset: Int, $first: Int) {
  user { account(id: $id) {
    id currency hasTimes transactionCount
    transactions(first: $first, offset: $offset, reverse: true) { edges { node {
      id datetime timezone amount postBalance description
    } } }
  } }
}`;

export const ACCOUNTS = gql`query accounts($last: Int) {
  user { accounts { edges { node { 
    id name currency description pinned hasTimes startDate startBalance latestBalance
    series { seriesType units data(last: $last) { datetime timezone value } }
  } } } }
}`;

export const PINNED_ACCOUNTS = gql`{
  user { accounts(pinned: true) { edges { node { 
    id name currency description pinned hasTimes startDate startBalance latestBalance
    series { seriesType units data(last: 150) { datetime timezone value } }
  } } } }
}`;

export const TRANSACTION = gql`query transaction($id: ID!) {
  user { transaction(id: $id) {
    id amount datetime timezone description account { id currency hasTimes }
  } }
}`;