import gql from "graphql-tag";

export const CREATE_FOOD = gql`mutation(
  $name: String!, $notes: String, $units: String!, $isPortion: Boolean!,
  $portionDefinition: Float, $calories: Float, $carbohydrates: Float,
  $sugar: Float, $protein: Float, $fat: Float, $fibre: Float, $salt: Float
) { createFood(
  name: $name, notes: $notes, units: $units, isPortion: $isPortion,
  portionDefinition: $portionDefinition, calories: $calories,
  carbohydrates: $carbohydrates, sugar: $sugar, protein: $protein, fat: $fat,
  fibre: $fibre, salt: $salt
) { food { id name } } }`;

export const CREATE_MEAL = gql`mutation(
  $datetime: Int!, $timezone: String!, $notes: String, $components: [ComponentInput],
  $rawCalories: Float, $rawCarbohydrates: Float, $rawSugar: Float,
  $rawProtein: Float, $rawFat: Float, $rawFibre: Float, $rawSalt: Float
) { createMeal(
  datetime: $datetime, timezone: $timezone, notes: $notes, components: $components,
  rawCalories: $rawCalories, rawCarbohydrates: $rawCarbohydrates, rawSugar: $rawSugar,
  rawProtein: $rawProtein, rawFat: $rawFat, rawFibre: $rawFibre, rawSalt: $rawSalt
) { meal { id datetime timezone } } }`;

export const UPDATE_FOOD = gql`mutation(
  $id: ID!, $name: String!, $notes: String, $units: String!,
  $portionDefinition: Float, $calories: Float, $carbohydrates: Float,
  $sugar: Float, $protein: Float, $fat: Float, $fibre: Float, $salt: Float
) { updateFood(
  id: $id, name: $name, notes: $notes, units: $units,
  portionDefinition: $portionDefinition, calories: $calories,
  carbohydrates: $carbohydrates, sugar: $sugar, protein: $protein, fat: $fat,
  fibre: $fibre, salt: $salt
) { food { id } } }`;

export const UPDATE_MEAL = gql`mutation(
  $id: ID!, $datetime: Int!, $timezone: String!, $notes: String, $components: [ComponentInput],
  $rawCalories: Float, $rawCarbohydrates: Float, $rawSugar: Float,
  $rawProtein: Float, $rawFat: Float, $rawFibre: Float, $rawSalt: Float
) { updateMeal(
  id: $id, datetime: $datetime, timezone: $timezone, notes: $notes,
  components: $components, 
  rawCalories: $rawCalories, rawCarbohydrates: $rawCarbohydrates, rawSugar: $rawSugar,
  rawProtein: $rawProtein, rawFat: $rawFat, rawFibre: $rawFibre, rawSalt: $rawSalt
) { meal { id datetime timezone } } }`;

export const DELETE_FOOD = gql`mutation($id: ID!) {
  deleteFood(id: $id) {success}
}`;

export const DELETE_MEAL = gql`mutation($id: ID!) {
  deleteMeal(id: $id) {success}
}`;

export const UPDATE_FOOD_SETTINGS = gql`mutation(
  $caloriesOnHome: Boolean! $carbohydratesOnHome: Boolean! $sugarOnHome: Boolean!
  $proteinOnHome: Boolean! $fatOnHome: Boolean! $fibreOnHome: Boolean!
  $saltOnHome: Boolean!
) {
  updateFoodSettings(
    caloriesOnHome: $caloriesOnHome carbohydratesOnHome: $carbohydratesOnHome
    sugarOnHome: $sugarOnHome proteinOnHome: $proteinOnHome 
    fatOnHome: $fatOnHome fibreOnHome: $fibreOnHome saltOnHome: $saltOnHome
  ) {
    user {
      caloriesOnHome carbohydratesOnHome sugarOnHome proteinOnHome
      fatOnHome fibreOnHome saltOnHome
    }
  }
}`;