import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Div100vh from "react-div-100vh";

const NotFoundPage = () => {
  /**
   * The page which shows when not logged in and the url doesn't match.
   */

  useEffect(() => {
    document.title = "Page Not Found - lytiko";
  });

  return (
    <Div100vh className="not-found-page">
      <h1>Page Not Found</h1>
      <Link to="/">Home</Link>
    </Div100vh>
  )
}

export default NotFoundPage;