import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { useMutation } from "@apollo/react-hooks";
import { FOODS } from "../queries";
import { DELETE_FOOD } from "../mutations";
import alertIcon from "../../images/alert.svg";
import Modal from "../../core/components/Modal";

const DeleteFoodModal = props => {
  /**
   * A full page interface asking if a food should be deleted.
   */

  const { showModal, setShowModal, food } = props;
  const history = useHistory();
  const [error, setError] = useState("");

  const [deleteFood, deleteFoodMutaion] = useMutation(DELETE_FOOD, {
    onError: () => {
      setError("Sorry there was an error, food couldn't be deleted.");
    },
    refetchQueries: [{query: FOODS}],
    onCompleted: () => {
      history.push("/food/");
    }
  });

  const formSubmit = e => {
    e.preventDefault();
    deleteFood({variables: {id: food.id}});
  }

  return (
    <Modal setShowModal={setShowModal} showModal={showModal} className="delete-modal delete-food-modal">
      <form onSubmit={formSubmit}>
        <img src={alertIcon} className="icon" alt="alert" />
        {error && <div className="error">{error}</div>}
        <div className="proposal">Delete Food</div>
        <div className="warning">
          This will permanently delete the food. It will also disappear from any
          meals it was in.
        </div>
        <input type="submit" value="Delete" disabled={deleteFoodMutaion.loading} />
      </form>
    </Modal>
  )
}


DeleteFoodModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  food: PropTypes.object.isRequired
}

export default DeleteFoodModal;