import React, { useEffect } from "react";
import Base from "../../core/components/Base";
import TimeCategoryForm from "../components/TimeCategoryForm";

const NewTimeCategoryPage = () => {
  /**
   * Page for creating new time categories.
   */
  
  useEffect(() => {
    document.title = "New Time Category - lytiko";
  });

  return (
    <Base className="new-time-category-page">
      <TimeCategoryForm />
    </Base>
  )
}

export default NewTimeCategoryPage;