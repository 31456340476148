import React, { useState } from "react";
import PropTypes from "prop-types";
import WhiteBox from "../../core/components/WhiteBox";
import DeleteFoodModal from "./DeleteFoodModal";
import editIcon from "../../images/edit.svg";
import compareIcon from "../../images/compare.svg";
import deleteIcon from "../../images/delete.svg";
import Dropdown from "../../core/components/Dropdown";

const Food = props => {
  /**
   * A box with food information in it.
   */

  const { food } = props;

  const [showModal, setShowModal] = useState(false);

  const deleteClicked = () => {
    setShowModal(true);
  }

  const links = [
    {href: `/food/${food.id}/edit/`, icon: editIcon, text: "Edit"},
    {href: `/compare?comparitor1=food-${food.id}`, icon: compareIcon, text: "Compare"},
    {action: deleteClicked, icon: deleteIcon, text: "Delete"}
  ]


  return (
    <WhiteBox className="food">
      <Dropdown links={links} />

      <div className="food-description">
        <div className="food-name">{food.name}</div>
        <div className="food-notes">{food.notes}</div>
      </div>

      <div className="nutrients">
        <div className="per">{food.isPortion ? "Per portion" : `Per 100${food.units}`}</div>
        <div className="nutrients-grid">        
          {food.calories !== null && (
            <div className="nutrient calories">
              Calories: <span className="value">{food.calories} kcal</span>
            </div>
          )}
          {food.carbohydrates !== null && (
            <div className="nutrient carbohydrates">
              Carbohydrates: <span className="value">{food.carbohydrates}g</span>
            </div>
          )}
          {food.sugar !== null && (
            <div className="nutrient sugar">
              Sugar: <span className="value">{food.sugar}g</span>
            </div>
          )}
          {food.protein !== null && (
            <div className="nutrient protein">
              Protein: <span className="value">{food.protein}g</span>
            </div>
          )}
          {food.fat !== null && (
            <div className="nutrient fat">
              Fat: <span className="value">{food.fat}g</span>
            </div>
          )}
          {food.fibre !== null && (
            <div className="nutrient fibre">
              Fibre: <span className="value">{food.fibre}g</span>
            </div>
          )}
          {food.salt !== null && (
            <div className="nutrient salt">
              Salt: <span className="value">{food.salt}g</span>
            </div>
          )}
        </div>
      </div>
      <DeleteFoodModal  
        food={food} 
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </WhiteBox>
  )
}

Food.propTypes = {
  food: PropTypes.object.isRequired
}

export default Food;