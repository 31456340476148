import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";
import { TwitterPicker } from "react-color";

const ColorPicker = props => {
  /**
   * An widget for picking a color.
   */

  const {color, setColor, colors} = props;

  // Keep track of whether the modal should be shown
  const [showModal, setShowModal] = useState(false);

  const colorPicked = color => {
    setColor(color.hex);
    setShowModal(false);
  }

  return (
    <div
      className="color-picker"
    >
      <div
        className="current-color"
        style={{backgroundColor: color}}
        onClick={() => setShowModal(true)} 
      />

      <Modal setShowModal={setShowModal} showModal={showModal} className="color-modal">
        <TwitterPicker
          colors={colors} onChangeComplete={colorPicked}
          triangle="hide"
        />
      </Modal>
    </div>
  );
}

ColorPicker.propTypes = {
  color: PropTypes.string.isRequired,
  setColor: PropTypes.func.isRequired,
  colors: PropTypes.array.isRequired
}

export default ColorPicker;