import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Dropdown from "../../core/components/Dropdown";
import roundTo from "round-to";
import { UserContext } from "../../core/context";
import { timeString } from "../../core/time";
import WhiteBox from "../../core/components/WhiteBox";
import DeleteMealModal from "./DeleteMealModal";
import editIcon from "../../images/edit.svg";
import deleteIcon from "../../images/delete.svg";

const Meal = props => {
  const { meal } = props;
  const user = useContext(UserContext);

  const [showModal, setShowModal] = useState(false);

  const deleteClicked = () => {
    setShowModal(true);
  }

  const links = [
    {href: `/food/meals/${meal.id}/edit/`, icon: editIcon, text: "Edit"},
    {action: deleteClicked, icon: deleteIcon, text: "Delete"}
  ]

  return (
    <WhiteBox className="meal">
      <Dropdown links={links} />
      
      <div className="meal-left">
        <div className="time">{timeString(meal.datetime * 1000, meal.timezone, user.timezone).split(",")[2]}</div>
        <div className="components">
          {meal.components.edges.map(edge => edge.node).map(component => {
            return (
              <div className="component" key={component.id}>
                <Link className="food-link" to={`/food/${component.food.id}/`}>{component.food.name}</Link>
                <div className="amount">{component.amount}{!component.isPortion && <span className="units">{component.food.units}</span>}</div>
              </div>
            )
          })}
          {!meal.components.edges.length && <div className="note">No foods recorded</div>}
        </div>
      </div>

      <div className="meal-nutrition">
        {meal.calories ? <div className="nutrient">
          <span className="number">{roundTo(meal.calories, 0)}</span> <span className="units">kcal</span>
        </div> : ""}
        {meal.carbohydrates ? <div className="nutrient">
          <span className="number">{roundTo(meal.carbohydrates, 0)}</span><span className="units">g</span> <span className="name">carbs</span>
        </div> : ""}
        {meal.sugar ? <div className="nutrient">
          <span className="number">{roundTo(meal.sugar, 0)}</span><span className="units">g</span> <span className="name">sugar</span>
        </div> : ""}
        {meal.protein ? <div className="nutrient">
          <span className="number">{roundTo(meal.protein, 0)}</span><span className="units">g</span> <span className="name">protein</span>
        </div> : ""}
        {meal.fat ? <div className="nutrient">
          <span className="number">{roundTo(meal.fat, 0)}</span><span className="units">g</span> <span className="name">fat</span>
        </div> : ""}
        {meal.fibre ? <div className="nutrient">
          <span className="number">{roundTo(meal.fibre, 0)}</span><span className="units">g</span> <span className="name">fibre</span>
        </div> : ""}
        {meal.salt ? <div className="nutrient">
          <span className="number">{roundTo(meal.salt, 0)}</span><span className="units">g</span> <span className="name">salt</span>
        </div> : ""}
      </div>
      <DeleteMealModal  
        meal={meal} 
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </WhiteBox>
  );
};

Meal.propTypes = {
  meal: PropTypes.object.isRequired
};

export default Meal;