import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../core/context"
import DeleteMeasurementModal from "./DeleteMeasurementModal";
import Dropdown from "../../core/components/Dropdown";
import editIcon from "../../images/edit.svg";
import deleteIcon from "../../images/delete.svg";
import { timeString, dayString } from "../../core/time";

const MeasurementRow = props => {
  /**
   * A row representing a measurement.
   */

  const {measurement, quantity, setShowingDropdown} = props;

  const [showModal, setShowModal] = useState(false);

  const user = useContext(UserContext);

  const deleteClicked = () => {
    setShowModal(true);
  }

  const links = [
    {href: `/quantities/measurements/${measurement.id}/edit/`, icon: editIcon, text: "Edit"},
    {action: deleteClicked, icon: deleteIcon, text: "Delete"}
  ]

  return (
    <div className="measurement-row" >
      <div className="cell value">{Number(measurement.value).toLocaleString()}</div>
      <div className="cell">{quantity.resolution ? dayString(measurement.datetime * 1000, quantity.resolution) : timeString(measurement.datetime * 1000, measurement.timezone, user.timezone)}</div>
      <div className="cell notes">{measurement.notes}</div>
      <div className="cell">
        <Dropdown links={links} setShowingDropdown={setShowingDropdown} />
      </div>
      <DeleteMeasurementModal
        quantity={quantity}
        measurement={measurement}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </div>
  );
}

MeasurementRow.propTypes = {
  measurement: PropTypes.object.isRequired,
  quantity: PropTypes.object.isRequired,
  setShowingDropdown: PropTypes.func.isRequired
}

export default MeasurementRow;