import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const PrivacyPolicyPage = () => {
  /**
   * The GDPR privacy policy page.
   */

  useEffect(() => {
    document.title = "Privacy Policy - lytiko";
  });
  
  return (
    <div className="privacy-policy-page">
      <h1>lytiko Privacy Policy</h1>
      <p>
        This is the privacy policy for lytiko and associated apps.
      </p>
      <p><Link to="/">Back Home</Link></p>
      <h2>Waiting List</h2>
      <p>
        When you sign up to the lytiko waiting list with your email address, we
        store that email address and the date and time you signed up. We do not
        store any additional information about you.
      </p>
      <p>
        If you would like to leave the waiting list, follow the link in the
        email you received on signing up. This will remove your details from the
        waiting list database immediately.
      </p>
      <p>
        When you are invited to sign up for lytiko, you will be sent an email to
        this address informing you. If you sign up, your email address will be
        removed from the waiting list. If you don't sign up within 30 days of
        the email being sent, we will remove your email address from our
        database anyway.
      </p>

      <h2>Your Data</h2>
      <p>
        In addition to the quantities you manage with Lytiko, Lytiko stores your
        email address, your name, the date you last logged in and the date you
        signed up.
      </p>
      <p>
        This data is never transferred to a third party for any reason. We do
        not sell or provide access to your data. Your data is stored on our
        servers in a secure fashion.
      </p>
      <p>
        We do not store copies of your data if you delete your account - if you
        want your data removed from our servers entirely, you may do this at any
        time. Your details will be removed from all our backups within 24 hours.
      </p>

      <h2>Contacting You</h2>
      <p>
        We may use your email address to send you servive-critical emails, such
        as password resets, email verification, and so on.
      </p>

      <h2>Cookies</h2>
      <p>
        We use google analytics and its cookies on our home page, but not in the
        lytiko app itself.
      </p>
    </div>
  );
}

export default PrivacyPolicyPage;