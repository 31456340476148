import React, { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { FOOD } from "../queries";
import Base from "../../core/components/Base";
import FoodForm from "../components/FoodForm";
import WhiteBox from "../../core/components/WhiteBox";

const EditFoodPage = () => {
  /**
   * Page for editing foods.
   */

  const foodId = useRouteMatch("/food/:id/edit/").params.id;

  const { loading, data } = useQuery(FOOD, {variables: {id: foodId}});
  const food = !data ? {} : data.user.food;
  
  useEffect(() => {
    document.title = "Edit Food - lytiko";
  });

  if (loading) {
    return (
      <Base className="new-food-page">
        <WhiteBox className="food-form" loading={true} />
      </Base>
    )
  }

  return (
    <Base className="new-food-page">
      <FoodForm food={food} />
    </Base>
    
  )
}

export default EditFoodPage;