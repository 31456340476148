import React, { useEffect } from "react";
import Base from "../../core/components/Base";
import AccountForm from "../components/AccountForm";

const NewAccountPage = () => {
  /**
   * Page for creating new quantities.
   */
  
  useEffect(() => {
    document.title = "New Account - lytiko";
  });

  return (
    <Base className="new-account-page">
      <AccountForm />
    </Base>
  )
}

export default NewAccountPage;