import React from "react";
import PropTypes from "prop-types";
import ClipLoader from "react-spinners/ClipLoader";

const WhiteBox = props => {
  /**
   * A standard lytiko white box container. You can make them a container for a
   * loading icon if the content is still loading.
   */

  const Element = props.element || "div";
  const propsCopy = {...props}
  delete propsCopy.element;
  delete propsCopy.loading;

  if (props.loading) {
    return (
      <Element {...propsCopy} className={`white-box ${props.className}`}>
        <ClipLoader css="display: block; margin: 0 auto;"/>
      </Element>
    )
  }

  return (
    <Element {...propsCopy} className={`white-box ${props.className}`}>
      {props.children}
    </Element>
  )
}

WhiteBox.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  className: PropTypes.string,
  element: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
}

export default WhiteBox;