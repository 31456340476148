import React, { useContext } from "react";
import { Link } from "react-router-dom";
import WhiteBox from "../../core/components/WhiteBox";
import { durationString } from "../../core/time";
import { UserContext } from "../../core/context";

const TimeCategoryGrid = props => {
  /**
   * A grid of time categories.
   */

  let { categories } = props;
  const user = useContext(UserContext);
  if (!user.showEmptyTimeCategories) {
    categories = categories.filter(category => category.total);
  }

  if (!categories.length) {
    return (
      <div className="time-category-grid empty">
        There is no tracked time for this day.
      </div>
    )
  }

  return (
    <div className="time-category-grid">
      {categories.map(category => (
        <WhiteBox 
          key={category.id}
          className="time-category-summary"
          style={{borderTopColor: category.color}}
          element={Link}
          to={`/time/${category.id}`}
        >
          <div className="category-name">{category.name}</div>
          <div className="category-total">
            {category.total ? durationString(category.total) : "--"}
          </div>
        </WhiteBox>
      ))}
    </div>
  );
};

TimeCategoryGrid.propTypes = {
    
};

export default TimeCategoryGrid;