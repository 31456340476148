import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import DeleteTimeCategoryModal from "./DeleteTimeCategoryModal";
import SvgSeries from "../../core/components/SvgSeries";
import { createSeries, seriesToMovingAverage } from "../../core/charts";
import { durationString } from "../../core/time";
import Dropdown from "../../core/components/Dropdown";
import editIcon from "../../images/edit.svg";
import compareIcon from "../../images/compare.svg";
import deleteIcon from "../../images/delete.svg";
import WhiteBox from "../../core/components/WhiteBox";

const TimeCategory = props => {
  /**
   * A box with time category information in it. It can optionally act as a
   * link to the category's page, and if so, will have slightly different
   * behaviour.
   */

  const {category, link } = props;

  const [showModal, setShowModal] = useState(false);

  const boxProps = {className: "time-category"};
  if (link) {
    boxProps.to = `/time/${category.id}/`;
    boxProps.element = Link;
    boxProps.style = {borderTopColor: category.color};
  }

  const deleteClicked = () => {
    setShowModal(true);
  }

  let series = createSeries(category.series, {});
  if (series.data.length > 5) {
    series = seriesToMovingAverage(series);
  }
  series = series.data;

  const links = [
    {href: `/time/${category.id}/edit/`, icon: editIcon, text: "Edit"},
    {href: `/compare?comparitor1=timecategory-${category.id}`, icon: compareIcon, text: "Compare"},
    {action: deleteClicked, icon: deleteIcon, text: "Delete"}
  ]

  return (
    <WhiteBox {...boxProps}>
      <div className="top-row">
        <div className="info">
          <div className="category-name">{category.name}</div>
          <div className="category-description">{category.description}</div>
        </div>
        {!link && <Dropdown links={links} />}
      </div>
      {link && <div className="bottom-row">
        {category.total === 0 ? (
          <div className="total">--</div>
        ) : (
          <div className="total">{durationString(category.total)}</div>
        )}
        {link && <div className="series-container">
          <SvgSeries series={series} lineWidth={1.5} />
        </div>}
      </div>}
      <DeleteTimeCategoryModal
        category={category} 
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </WhiteBox>
  )
}

TimeCategory.propTypes = {
  category: PropTypes.object.isRequired,
  link: PropTypes.bool.isRequired,
  showSummary: PropTypes.bool
}

export default TimeCategory;