import React, { useEffect, useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import Loader from "../../core/components/Loader";
import { QUANTITIES } from "../queries";
import leftArrow from "../../images/left-arrow.svg";
import Base from "../../core/components/Base";
import QuantitySummary from "../components/QuantitySummary";
import { UserContext } from "../../core/context";

const QuantitiesPage = () => {
  /**
   * All a user's quantities on one page. If there are no quantities, it will
   * indicate how to create one.
   */

  const user = useContext(UserContext);
  const {loading, data} = useQuery(QUANTITIES, {
    variables: {last: user.showQuantitySummaries ? 150 : 1}
  });
  const quantities = loading ? [] : data.user.quantities.edges.map(edge => edge.node);
  
  useEffect(() => {
    document.title = "Quantities - lytiko";
  });

  if (loading) {
    return (
      <Base className="quantities-page">
        <Loader />
      </Base>
    )
  }

  if (quantities.length === 0) {
    return (
      <Base className="quantities-page">
        <div className="no-quantities">
          <div>You are currently not tracking any quantities</div>
          <div className="pointer"><img src={leftArrow} alt="" />Start tracking now</div>
        </div>
      </Base>
    )
  }

  return (
    <Base className="quantities-page">
      <div className="quantities-grid">
        {quantities.map(quantity => {
          return (
            <QuantitySummary
              quantity={quantity}
              key={quantity.id}
              showSummary={user.showQuantitySummaries}
            />
          )
        })}
      </div>
    </Base>
  )
}

export default QuantitiesPage;