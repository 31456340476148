import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { useMutation } from "@apollo/react-hooks";
import Select from "react-select";
import WhiteBox from "../../core/components/WhiteBox";
import { CREATE_FOOD, UPDATE_FOOD } from "../mutations";
import { FOODS, FOOD, FOOD_HISTORY, NUTRIENT } from "../queries";
import { createErrorObject } from "../../core/forms";

const FoodForm = props => {
  /**
   * An interface for creating or editing a food.
   */

  const food = props.food;

  const history = useHistory();
  const [name, setName] = useState(food ? food.name : "");
  const [notes, setNotes] = useState(food ? food.notes : "");
  const [units, setUnits] = useState(food ? food.units : "g");
  const [isPortion, setIsPortion] = useState(food ? food.isPortion : false);
  const [portionDefinition, setPortionDefinition] = useState(food ? food.portionDefinition : null);
  const [showPortionDefinition, setShowPortionDefinition] = useState(false);

  const [calories, setCalories] = useState(food ? food.calories : "");
  const [carbohydrates, setCarbohydrates] = useState(food ? food.carbohydrates : "");
  const [sugar, setSugar] = useState(food ? food.sugar : "");
  const [protein, setProtein] = useState(food ? food.protein : "");
  const [fat, setFat] = useState(food ? food.fat : "");
  const [fibre, setFibre] = useState(food ? food.fibre : "");
  const [salt, setSalt] = useState(food ? food.salt : "");

  const [errors, setErrors] = useState({
    name: "", notes: "", units: "", isPortion: "", portionDefinition: "",
    calories: "", carbohydrates: "", sugar: "", protein: "", fat: "", fibre: "",
    salt: "", general: ""
  });
  

  const [createFood, createFoodMutation] = useMutation(CREATE_FOOD, {
    onError: ({graphQLErrors}) => {
      setErrors(createErrorObject(errors, graphQLErrors));
    },
    refetchQueries: [{query: FOODS}],
    onCompleted: ({createFood: {food: {id}}}) => {
      history.push(`/food/${id}/`);
    }
  });

  const [updateFood, updateFoodMutation] = useMutation(UPDATE_FOOD, {
    onError: ({graphQLErrors}) => {
      setErrors(createErrorObject(errors, graphQLErrors));
    },
    refetchQueries: [
      {query: FOODS},  {query: NUTRIENT},
      {query: FOOD_HISTORY, variables: {id: food && food.id}},
      {query: FOOD, variables: {id: food && food.id}}
    ],
    onCompleted: ({updateFood: {food: {id}}}) => {
      history.push(`/food/${id}/`);
    }
  });

  const formSubmit = e => {
    e.preventDefault();
    if (food) {
      updateFood({
        variables: {
          id: food.id, name, notes, units, isPortion, portionDefinition,
          calories: calories !== "" ? parseFloat(calories) : null,
          carbohydrates: carbohydrates !== "" ? parseFloat(carbohydrates) : null,
          sugar: sugar !== "" ? parseFloat(sugar) : null,
          protein: protein !== "" ? parseFloat(protein) : null,
          fat: fat !== "" ? parseFloat(fat) : null,
          fibre: fibre !== "" ? parseFloat(fibre) : null,
          salt: salt !== "" ? parseFloat(salt) : null,
        }
      });
    } else {
      createFood({
        variables: {
          name, notes, units, isPortion, portionDefinition,
          calories: calories !== "" ? parseFloat(calories) : null,
          carbohydrates: carbohydrates !== "" ? parseFloat(carbohydrates) : null,
          sugar: sugar !== "" ? parseFloat(sugar) : null,
          protein: protein !== "" ? parseFloat(protein) : null,
          fat: fat !== "" ? parseFloat(fat) : null,
          fibre: fibre !== "" ? parseFloat(fibre) : null,
          salt: salt !== "" ? parseFloat(salt) : null,
        }
      });
    }
  }

  const unitsOptions = [  
    {value: "g", label: "100g"},
    {value: "ml", label: "100ml"},
    {value: "Portion", label: "Portion"}
  ]

  const portionUnitsOptions = [  
    {value: "g", label: "g"},
    {value: "ml", label: "ml"}
  ]

  const unitsChanged = selection => {
    if (selection.value === "Portion") {
      setIsPortion(true);
    } else {
      setIsPortion(false);
      setUnits(selection.value);
    }
  }

  return (
    <WhiteBox className="food-form model-form">
      {food ? <h1>Edit Food</h1> : <h1>Create New Food</h1>}

      <form onSubmit={formSubmit}>
        {errors.general && <div className="error">{errors.general}</div>}
        {errors.units && <div className="error">{errors.units}</div>}
        {errors.isPortion && <div className="error">{errors.isPortion}</div>}
        {errors.portionDefinition && <div className="error">{errors.portionDefinition}</div>}

        <div className="top-row">
          <div className="input">
            <label htmlFor="name">Food Name</label>
            {errors.name && <div className="error">{errors.name}</div>}
            <input
              id="name"
              value={name}
              onChange={e => setName(e.target.value)}
              disabled={createFoodMutation.loading || updateFoodMutation.loading}
              required={true}
            />
          </div>
          <div className="input">
            <label htmlFor="notes">Notes</label>
            {errors.notes && <div className="error">{errors.notes}</div>}
            <input
              id="notes"
              value={notes}
              onChange={e => setNotes(e.target.value)}
              disabled={createFoodMutation.loading || updateFoodMutation.loading}
            />
          </div>

        </div>
 
        <h2>
          Nutrients Per {isPortion ? "Portion" : `100${units}`}
        </h2>
        <div className="nutrient-inputs">
          <div className="input">
            <label>Unit:</label>
            {food ? (
              <input
                readOnly={true}
                className="read-only"
                value={unitsOptions[isPortion ? 2 : units === "g" ? 0 : 1].label}
              />
            ) : (
              <Select
                options={unitsOptions}
                value={unitsOptions[isPortion ? 2 : units === "g" ? 0 : 1]}
                onChange={unitsChanged}
                disabled={true}
                className="react-select"
                classNamePrefix="react-select"
              />
            )}
          </div>
          <div className="input">
            <label htmlFor="calories">Calories</label>
            <input
              className={errors.calories ? "error-input" : ""}
              id="calories"
              type="number"
              step="any"
              value={calories}
              onChange={e => setCalories(e.target.value)}
              disabled={createFoodMutation.loading || updateFoodMutation.loading}
            />
          </div>
          <div className="input">
            <label htmlFor="carbohydrates">Carbohydrates</label>
            <input
              className={errors.carbohydrates ? "error-input" : ""}
              id="carbohydrates"
              type="number"
              step="any"
              value={carbohydrates}
              onChange={e => setCarbohydrates(e.target.value)}
              disabled={createFoodMutation.loading || updateFoodMutation.loading}
            />
          </div>
          <div className="input">
            <label htmlFor="sugar">Sugar</label>
            <input
              className={errors.sugar ? "error-input" : ""}
              id="sugar"
              type="number"
              step="any"
              value={sugar}
              onChange={e => setSugar(e.target.value)}
              disabled={createFoodMutation.loading || updateFoodMutation.loading}
            />
          </div>
          <div className="input">
            <label htmlFor="protein">Protein</label>
            <input
              className={errors.protein ? "error-input" : ""}
              id="protein"
              type="number"
              step="any"
              value={protein}
              onChange={e => setProtein(e.target.value)}
              disabled={createFoodMutation.loading || updateFoodMutation.loading}
            />
          </div>
          <div className="input">
            <label htmlFor="fat">Fat</label>
            <input
              className={errors.fat ? "error-input" : ""}
              id="fat"
              type="number"
              step="any"
              value={fat}
              onChange={e => setFat(e.target.value)}
              disabled={createFoodMutation.loading || updateFoodMutation.loading}
            />
          </div>
          <div className="input">
            <label htmlFor="fibre">Fibre</label>
            <input
              className={errors.fibre ? "error-input" : ""}
              id="fibre"
              type="number"
              step="any"
              value={fibre}
              onChange={e => setFibre(e.target.value)}
              disabled={createFoodMutation.loading || updateFoodMutation.loading}
            />
          </div>
          <div className="input">
            <label htmlFor="salt">Salt</label>
            <input
              className={errors.salt ? "error-input" : ""}
              id="salt"
              type="number"
              step="any"
              value={salt}
              onChange={e => setSalt(e.target.value)}
              disabled={createFoodMutation.loading || updateFoodMutation.loading}
            />
          </div>

        </div>

        <div className="bottom-row">
          <div className="input">
            {showPortionDefinition ? (
              <>
                <label htmlFor="portion">Portion is:
                  <div className="toggler" onClick={() => setShowPortionDefinition(false)}>Hide</div>
                </label>
                <div className="portion-calculator">
                  
                  <input
                    id="portion"
                    type="number"
                    step="any"
                    required={food && food.portionDefinition}
                    value={portionDefinition || ""}
                    onChange={e => setPortionDefinition(e.target.value)}
                    disabled={createFoodMutation.loading || updateFoodMutation.loading}
                  />
                  {isPortion ? (
                    <Select
                      options={portionUnitsOptions}
                      value={portionUnitsOptions[units === "g" ? 0 : 1]}
                      onChange={selection => setUnits(selection.value)}
                      className="react-select"
                      classNamePrefix="react-select"
                    />
                  ) : <div className="units">{units}</div>}
                </div>
              </>
            ) : (
              <div className="toggler" onClick={() => setShowPortionDefinition(true)}>Set Portion</div>
            )}
          </div>
  
          <input
            type="submit"
            value="Save" 
            disabled={createFoodMutation.loading || updateFoodMutation.loading}
          />
        </div>
      </form>

    </WhiteBox>
  )
}

FoodForm.propTypes = {
  food: PropTypes.object,
}

export default FoodForm;