import React, { useEffect, useContext } from "react";
import Base from "../components/Base";
import CredentialsForm from "../components/CredentialsForm";
import BasicSettingsForm from "../components/BasicSettingsForm";
import QuantitiesSettingsForm from "../../quantities/components/QuantitiesSettingsForm";
import FoodSettingsForm from "../../food/components/FoodSettingsForm";
import TimeSettingsForm from "../../time/components/TimeSettingsForm";
import MoneySettingsForm from "../../money/components/MoneySettingsForm";
import UserDeletionForm from "../components/UserDeletionForm";
import { UserContext } from "../context";

const SettingsPage = () => {
  /**
   * The settings/profile page.
   */
  
  useEffect(() => {
    document.title = "Settings - lytiko";
  });

  const user = useContext(UserContext)

  return (
    <Base className="settings-page">
      <CredentialsForm user={user} />
      <BasicSettingsForm user={user} />
      <QuantitiesSettingsForm user={user} />
      <FoodSettingsForm user={user} />
      <TimeSettingsForm user={user} />
      <MoneySettingsForm user={user} />
      <UserDeletionForm />
    </Base>
  )
}

export default SettingsPage;