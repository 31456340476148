import gql from "graphql-tag";

export const TIME_CATEGORY = gql`query timeCategory($id: ID!) {
  user { timeCategory(id: $id) {
    id name description color pinned
    series { name units resolution seriesType data(last: 250) {
      datetime timezone value
    } }
  } }
}`;

export const TIME_CATEGORY_SESSIONS = gql`query timeCategory($id: ID!) {
  user { timeCategory(id: $id) {
    id sessions { edges { node { id start duration description timezone } } }
  } }
}`;

export const TIME_CATEGORIES_FOR_DAY = gql`query timeCategoriesForDay($date: String!) {
  user { timeCategories { edges { node {
    id name description color pinned total(date: $date) sessions(date: $date) {
      edges { node { id start timezone duration description } }
    }
  } } } }
}`;

export const PINNED_TIME_CATEGORIES = gql`query pinnedTimeCategories($date: String!) {
  user { timeCategories(pinned: true) { edges { node {
    id name color description total(date: $date)
    series { seriesType units data(last: 150) { datetime timezone value } }
  } } } }
}`;

export const SESSION = gql`query session($id: ID!) {
  user { session(id: $id) {
    id duration start timezone description category { id }
  } }
}`;