import moment from "moment-timezone";

export const getCurrentDay = (user, resolution) => {
  /**
   * Gets the current day as a YYYY-MM-DD string, based on the user's timezone
   * and when they have specified that a day should be considered over. If a
   * resolution is supplied, it will be used to determine how far back to go.
   */

  let day = moment();
  if (user.dayEnds !== undefined) {
    const timezone = user.timezone || moment.tz.guess();
    day = moment().tz(timezone).subtract(user.dayEnds, "hour");
  }
  if (resolution === "W") {
    day = day.subtract(day.day() === 0 ? 6 : day.day() - 1, "days");
  }
  if (resolution === "M") day = moment([day.year(), day.month()]);
  if (resolution === "Y") day = moment([day.year()]);
  return day.format("YYYY-MM-DD");
}

export const timeString = (datetime, timezone, appTimezone, hideWeekday) => {
  /**
   * Converts a datetime integer to a string. The time shown will be based on
   * the datetime's timezone. If the system-wide timezone differs, the offset
   * from this will be shown.
   */
  
  if (appTimezone === null) appTimezone = moment.tz.guess();
  let string =  moment(datetime).tz(timezone).format(
    hideWeekday ? "D MMMM, YYYY, HH:mm" : "dddd D MMMM, YYYY, HH:mm"
  );
  if (timezone !== appTimezone) {
    const offset1 = moment.tz.zone(timezone).utcOffset(datetime);
    const offset2 = moment.tz.zone(appTimezone).utcOffset(datetime);
    const offset = (offset1 - offset2) / 60;
    const offsetString = (offset < 0 ? "+" : "-") + Math.abs(offset).toString().padStart(2, "0").padEnd(4, "0")
    string = `${string}${offsetString}`;
  }
  return string;
}

export const dtToUtcDt = (datetime, timezone, dayEnds) => {
  const dt = moment(datetime * 1000).tz(timezone).subtract(dayEnds, "hour");
  const day = moment.utc([dt.year(), dt.month(), dt.date()]);
  return day.valueOf();
}

export const dayString = (datetime, resolution, showWeekday) => {
  /**
   * Converts a UTC datetime integer to a string representing that date. The
   * function assumes an exact UTC date, but doesn't check this.
   */

  let formatString = resolution === "Y" ? "YYYY" : (
    resolution === "M" ? "MMMM YYYY" : "dddd D MMMM, YYYY"
  );
  if (resolution === "W") formatString = `[w]/c ${formatString}`;
  return moment.utc(datetime).format(formatString);
}

export const durationString = minutes => {
  minutes = parseInt(minutes);
  if (minutes === 1) {
    return "1 minute";
  } else if (minutes >= 60) {
    const hours = parseInt(minutes / 60);
    const remainingMinutes = minutes % 60;
    const hoursString = `${hours} hour${hours === 1 ? "" : "s"}`;
    const minutesString = `, ${remainingMinutes} minute${remainingMinutes === 1 ? "" : "s"}`;
    return `${hoursString}${remainingMinutes === 0 ? "" : minutesString}`;
  } else {
    return `${minutes} minutes`;
  }
}