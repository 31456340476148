import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import DeleteQuantityModal from "./DeleteQuantityModal";
import SvgSeries from "../../core/components/SvgSeries";
import { createSeries, seriesToMovingAverage } from "../../core/charts";
import Dropdown from "../../core/components/Dropdown";
import editIcon from "../../images/edit.svg";
import compareIcon from "../../images/compare.svg";
import deleteIcon from "../../images/delete.svg";
import WhiteBox from "../../core/components/WhiteBox";

const Quantity = props => {
  /**
   * A box with quantity information in it. It can optionally act as a link to
   * the quantity's page, and if so, will have slightly different behaviour.
   */

  const {quantity, link, showSummary} = props;

  const [showModal, setShowModal] = useState(false);

  const boxProps = {className: "quantity"};
  if (link) {
    boxProps.to = `/quantities/${quantity.id}/`;
    boxProps.element = Link;
  }

  const deleteClicked = () => {
    setShowModal(true);
  }

  let series = createSeries(quantity.series, {});
  if (series.data.length > 5 && series.type !== "line") {
    series = seriesToMovingAverage(series);
  }
  series = series.data;

  const links = [
    {href: `/quantities/${quantity.id}/edit/`, icon: editIcon, text: "Edit"},
    {href: `/compare?comparitor1=quantity-${quantity.id}`, icon: compareIcon, text: "Compare"},
    {action: deleteClicked, icon: deleteIcon, text: "Delete"}
  ]

  return (
    <WhiteBox {...boxProps}>
      <div className="top-row">
        <div className="info">
          <div className="quantity-name">{quantity.name}</div>
          <div className="quantity-description">{quantity.description}</div>
        </div>
        {!link && <Dropdown links={links} />}
      </div>

      <div className="bottom-row">
        {quantity.latestValue === null || quantity.seriesType === 3 ? (
          <div className="value">--</div>
        ) : (
          <div className="value">{Number(quantity.latestValue).toLocaleString()}<span className="units">{quantity.units}</span></div>
        )}
        {link && showSummary && <div className="series-container">
          <SvgSeries series={series} lineWidth={1.5} />
        </div>}
      </div>
      <DeleteQuantityModal
        quantity={quantity} 
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </WhiteBox>
  )
}

Quantity.propTypes = {
  quantity: PropTypes.object.isRequired,
  link: PropTypes.bool.isRequired,
  showSummary: PropTypes.bool
}

export default Quantity;