import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { useMutation } from "@apollo/react-hooks";
import moment from "moment";
import { QUANTITY, QUANTITIES, PINNED_QUANTITIES } from "../queries";
import { CREATE_MEASUREMENT, UPDATE_MEASUREMENT } from "../mutations";
import WhiteBox from "../../core/components/WhiteBox";
import DatetimeInput from "../../core/components/DatetimeInput";
import { createErrorObject } from "../../core/forms";
import { getCurrentDay } from "../../core/time";
import { UserContext } from "../../core/context";

const MeasurementForm = props => {
  /**
   * An interface for creating or editing a measurement.
   */

  const history = useHistory();
  const {quantity, measurement} = props;

  // Values to display without existing measurement until the user selects something
  const user = useContext(UserContext);
  const currentDatetime = moment().set("second", 0).set("millisecond", 0).valueOf();
  const currentTimezone = user.timezone || moment.tz.guess();
  const currentDay = moment.utc(getCurrentDay(user, quantity.resolution)).valueOf();

  const [value, setValue] = useState(measurement ? measurement.value : "");
  const [notes, setNotes] = useState(measurement ? measurement.notes : "");
  const [datetime, setDatetime] = useState(measurement ? measurement.datetime * 1000 : null);
  const [timezone, setTimezone] = useState(measurement ? measurement.timezone : null);
  const [errors, setErrors] = useState({
    value: "", notes: "", datetime: "", timezone: ""
  });

  const [createMeasurement, createMeasurementMutation] = useMutation(CREATE_MEASUREMENT, {
    onError: ({graphQLErrors}) => {
      setErrors(createErrorObject(errors, graphQLErrors));
    },
    refetchQueries: [
      {query: QUANTITY, variables: {id: quantity.id}},
      {query: QUANTITIES, variables: {last: 150}}, {query: PINNED_QUANTITIES}
    ],
    onCompleted: () => {
      setValue("");
      setNotes("");
      setDatetime(null);
      setTimezone(null);
      setErrors(Object.keys(errors).reduce(
        (o, key) => Object.assign(o, {[key]: ""}), {}
      ));
    }
  });

  const [updateMeasurement, updateMeasurementMutation] = useMutation(UPDATE_MEASUREMENT, {
    onError: ({graphQLErrors}) => {
      setErrors(createErrorObject(errors, graphQLErrors));
    },
    refetchQueries: [
      {query: QUANTITY, variables: {id: quantity.id}},
      {query: QUANTITIES, variables: {last: 150}}, {query: PINNED_QUANTITIES}
    ],
    onCompleted: ({updateMeasurement: {measurement: {quantity: {id}}}}) => {
      history.push(`/quantities/${id}/`);
    }
  });

  const formSubmit = e => {
    e.preventDefault();
    if (measurement) {
      updateMeasurement({
        variables: {
          value: quantity.seriesType === 3 ? 0 : value,
          notes,
          datetime: (datetime || (quantity.resolution ? currentDay : currentDatetime)) / 1000,
          timezone: timezone || (quantity.resolution ? "UTC" : currentTimezone),
          id: measurement.id
        }
      });
    } else {
      createMeasurement({
        variables: {
          value: quantity.seriesType === 3 ? 0 : value,
          notes,
          datetime: (datetime || (quantity.resolution ? currentDay : currentDatetime)) / 1000,
          timezone: timezone || (quantity.resolution ? "UTC" : currentTimezone),
          quantity: quantity.id
        }
      });
    }

  }

  return (
    <WhiteBox className="measurement-form model-form">
      {measurement && <h1>Edit Measurement</h1>}
      <form onSubmit={formSubmit}>
        {errors.general && <div className="general-error error">{errors.general}</div>}
        <div className="input">
          <label htmlFor="date">Date</label>
          {errors.datetime && <div className="error">{errors.datetime}</div>}
          <DatetimeInput
            id="date"
            resolution={quantity.resolution}
            datetime={datetime === null ? quantity.resolution ? currentDay : currentDatetime : datetime}
            timezone={timezone === null ? quantity.resolution ? "UTC" : currentTimezone : timezone}
            setDatetime={setDatetime}
            setTimezone={setTimezone}
            disabled={createMeasurementMutation.loading || updateMeasurementMutation.loading}
          />
        </div>
        <div className="top-row">
          {quantity.seriesType === 3 ? <div /> : <div className="input">
            <label htmlFor="value">Value</label>
            {errors.value && <div className="error">{errors.value}</div>}
            <input
              id="value"
              value={value}
              type="number"
              step="any"
              onChange={e => setValue(e.target.value)}
              required={true}
              disabled={createMeasurementMutation.loading || updateMeasurementMutation.loading}
            />
          </div>}

          <div className="input">
            <label htmlFor="notes">Notes</label>
            {errors.notes && <div className="error">{errors.notes}</div>}
            <input
              id="notes"
              value={notes}
              onChange={e => setNotes(e.target.value)}
              disabled={createMeasurementMutation.loading || updateMeasurementMutation.loading}
            />
          </div>

        </div>
        
        <input
          type="submit"
          value={measurement ? "Save": "Add"} 
          disabled={createMeasurementMutation.loading || updateMeasurementMutation.loading}
        />
      </form>
    </WhiteBox>
  )
}

MeasurementForm.propTypes = {
  measurement: PropTypes.object,
  quantity: PropTypes.object.isRequired
}

export default MeasurementForm;