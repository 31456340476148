import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { TokenContext } from "../context";

const NavDropdown = props => {
  /**
   * The dropdown on the nav bar.
   */

  const setToken = useContext(TokenContext);

  const history = useHistory();

  const logout = () => {
    setToken(null);
    localStorage.removeItem("token");
    history.push("/");
  }

  return (
    <div className="nav-dropdown">
      <div className="nav-dropdown-top">
        <div className="user-name">{props.user.firstName} {props.user.lastName}</div>
        <div className="user-email">{props.user.email}</div>
      </div>
      <div className="nav-dropdown-bottom">
        <Link to="/settings/">Settings</Link>
        <div className="logout-button" onClick={logout}>Log Out</div>
      </div>
    </div>
  )
}

NavDropdown.propTypes = {
  user: PropTypes.object.isRequired
}

export default NavDropdown;