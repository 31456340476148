import gql from "graphql-tag";

export const QUANTITY = gql`query quantity($id: ID!) {
  user { quantity(id: $id) {
    id name units description pinned resolution seriesType resolution latestValue
    measurements { edges { node {
      id value datetime timezone notes
    } } }
    series { seriesType units resolution data {
      datetime timezone value components { datetime timezone value }
    } }
  } }
}`;

export const QUANTITY_SERIES = gql`query quantity($id: ID!, $resolution: String) {
  user { quantity(id: $id) {
    id series(resolution: $resolution) { seriesType units resolution data {
      datetime timezone value components { datetime timezone value }
    } }
  } }
}`;

export const PINNED_QUANTITIES = gql`{
  user { quantities(pinned: true) { edges { node { 
    id name units description resolution latestValue seriesType
    series { seriesType units data(last: 150) { datetime timezone value } }
  } } } }
}`

export const QUANTITIES = gql`query quantities($last: Int) {
  user { quantities { edges { node { 
    id name units description resolution latestValue seriesType
    series { seriesType units data(last: $last) { datetime timezone value } }
  } } } }
}`

export const MEASUREMENT = gql`query measurement($id: ID!) {
  user { measurement(id: $id) {
    id value datetime timezone notes quantity { id units resolution seriesType }
  } }
}`;