import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { useMutation } from "@apollo/react-hooks";
import moment from "moment-timezone";
import Select from "react-select";
import { CREATE_MEAL, UPDATE_MEAL } from "../mutations";
import WhiteBox from "../../core/components/WhiteBox";
import DatetimeInput from "../../core/components/DatetimeInput";
import { UserContext } from "../../core/context";
import addIcon from "../../images/plus.svg";
import closeIcon from "../../images/close.svg";
import { createErrorObject } from "../../core/forms";
import { dtToUtcDt } from "../../core/time";

const MealForm = props => {
  /**
   * An interface for creating or editing a meal.
   */

  const {meal, foods} = props;
  const foodsObject = foods.reduce((f, key) => ({ ...f, [key.id]: key}), {});
  const history = useHistory();

  // Values to display without existing measurement until the user selects something
  const user = useContext(UserContext);
  const currentDatetime = moment().set("second", 0).set("millisecond", 0).valueOf();
  const currentTimezone = user.timezone || moment.tz.guess();

  const [showNutrients, setShowNutrients] = useState(meal ? Boolean(
    meal.rawCalories || meal.rawCarbohydrates || meal.rawSugar ||
    meal.rawProtein || meal.rawFat || meal.rawFibre || meal.rawSalt
  ) : false);
  const [datetime, setDatetime] = useState(meal ? meal.datetime * 1000 : null);
  const [timezone, setTimezone] = useState(meal ? meal.timezone : null);
  const [notes, setNotes] = useState("");
  const [rawCalories, setRawCalories] = useState(meal ? meal.rawCalories : "");
  const [rawCarbohydrates, setRawCarbohydrates] = useState(meal ? meal.rawCarbohydrates : "");
  const [rawSugar, setRawSugar] = useState(meal ? meal.rawSugar : "");
  const [rawProtein, setRawProtein] = useState(meal ? meal.rawProtein : "");
  const [rawFat, setRawFat] = useState(meal ? meal.rawFat : "");
  const [rawFibre, setRawFibre] = useState(meal ? meal.rawFibre : "");
  const [rawSalt, setRawSalt] = useState(meal ? meal.rawSalt : "");
  const [components, setComponents] = useState(meal ? (
    meal.components.edges.map(edge => edge.node).map(component => ({
      amount: component.amount, isPortion: component.isPortion,
      food: component.food.id, showMenu: false
    }))
   ) : [{food: null, amount: "", isPortion: true, showMenu: false}]
  );

  const [errors, setErrors] = useState({
    datetime: "", timezone: "", notes: "", raw_calories: "", raw_carbohydrates: "",
    raw_sugar: "", raw_protein: "", raw_fat: "", raw_fibre: "", raw_salt: "", general: ""
  });

  const [createMeal, createMealMutation] = useMutation(CREATE_MEAL, {
    onError: ({graphQLErrors}) => {
      setErrors(createErrorObject(errors, graphQLErrors));
    },
    refetchQueries: [],
    onCompleted: data => {
      const date = dtToUtcDt(
        data.createMeal.meal.datetime,
        data.createMeal.meal.timezone,
        user.dayEnds
      )
      history.push(`/food?date=${moment.utc(date).format("YYYY-MM-DD")}`);
    }
  });

  const [updateMeal, updateMealMutation] = useMutation(UPDATE_MEAL, {
    onError: ({graphQLErrors}) => {
      setErrors(createErrorObject(errors, graphQLErrors));
    },
    refetchQueries: [],
    onCompleted: data => {
      const date = dtToUtcDt(
        data.updateMeal.meal.datetime,
        data.updateMeal.meal.timezone,
        user.dayEnds
      )
      history.push(`/food?date=${moment.utc(date).format("YYYY-MM-DD")}`);
    }
  });

  const foodOptions = foods.map(food => {return {value: food.id, label: food.name}});

  const foodSelected = (index, foodId) => {
    components[index].food = foodId;
    const food = foodsObject[foodId];
    if (food.portionDefinition) {
      components[index].isPortion = food.isPortion;
    } else {
      components[index].isPortion = food.isPortion;
    }
    setComponents([...components]);
  }

  const amountChanged = (index, amount) => {
    components[index].amount = parseFloat(amount);
    setComponents([...components]);
  }

  const unitsSelected = (index, units) => {
    components[index].isPortion = units === "portions";
    setComponents([...components]);
  }

  const addComponent = () => {
    components.push({food: null, amount: "", isPortion: true, showMenu: false});
    setComponents([...components]);
  }

  const removeComponent = (index) => {
    components.splice(index, 1);
    setComponents([...components]);
  }

  const inputChange = (index, text) => {
    components[index].showMenu = text.length >= 3;
    setComponents([...components]);
  }

  const formSubmit = e => {
    e.preventDefault();
    if (meal) {
      updateMeal({
        variables: {
          id: meal.id,
          datetime: (datetime || currentDatetime) / 1000,
          timezone: timezone || currentTimezone,
          notes,
          rawCalories: rawCalories !== "" ? parseFloat(rawCalories) : null,
          rawCarbohydrates: rawCarbohydrates !== "" ? parseFloat(rawCarbohydrates) : null,
          rawSugar: rawSugar !== "" ? parseFloat(rawSugar) : null,
          rawProtein: rawProtein !== "" ? parseFloat(rawProtein) : null,
          rawFat: rawFat !== "" ? parseFloat(rawFat) : null,
          rawFibre: rawFibre !== "" ? parseFloat(rawFibre) : null,
          rawSalt: rawSalt !== "" ? parseFloat(rawSalt) : null,
          components: components.filter(c => c.food).map(c => {
            let {showMenu, ...comp} = c;
            return comp;
          })
        }
      });
    } else {
      createMeal({
        variables: {
          datetime: (datetime || currentDatetime) / 1000,
          timezone: timezone || currentTimezone,
          notes,
          rawCalories: rawCalories !== "" ? parseFloat(rawCalories) : null,
          rawCarbohydrates: rawCarbohydrates !== "" ? parseFloat(rawCarbohydrates) : null,
          rawSugar: rawSugar !== "" ? parseFloat(rawSugar) : null,
          rawProtein: rawProtein !== "" ? parseFloat(rawProtein) : null,
          rawFat: rawFat !== "" ? parseFloat(rawFat) : null,
          rawFibre: rawFibre !== "" ? parseFloat(rawFibre) : null,
          rawSalt: rawSalt !== "" ? parseFloat(rawSalt) : null,
          components: components.filter(c => c.food).map(c => {
            let {showMenu, ...comp} = c;
            return comp;
          })
        }
      });
    }
  }

  return (
    <WhiteBox className="meal-form model-form">
      {meal ? <h1>Edit Meal</h1> : <h1>Create New Meal</h1>}

      <form onSubmit={formSubmit}>
        {errors.general && <div className="error">{errors.general}</div>}

        <div className="top-row">
          <div className="input">
            <label htmlFor="date">Date</label>
            {errors.datetime && <div className="error">{errors.datetime}</div>}
            <DatetimeInput
              id="date"
              datetime={datetime === null ? currentDatetime : datetime}
              timezone={timezone === null ? currentTimezone : timezone}
              setDatetime={setDatetime}
              setTimezone={setTimezone}
              disabled={createMealMutation.loading || updateMealMutation.loading}
            />
          </div>
          <div className="input">
            <label htmlFor="notes">Notes</label>
            {errors.notes && <div className="error">{errors.notes}</div>}
            <input
              id="notes"
              value={notes}
              onChange={e => setNotes(e.target.value)}
              disabled={createMealMutation.loading || updateMealMutation.loading}
            />
          </div>
        </div>

        <div onClick={() => setShowNutrients(!showNutrients)} className="toggle">
          {showNutrients ? "Hide" : "Show"} Nutrients
        </div>

        {showNutrients && <div className="nutrient-inputs">
          <div className="input">
            <label htmlFor="calories">Calories</label>
            <input
              className={errors.raw_calories ? "error-input" : ""}
              id="calories"
              type="number"
              step="any"
              value={rawCalories}
              onChange={e => setRawCalories(e.target.value)}
              disabled={createMealMutation.loading || updateMealMutation.loading}
            />
          </div>
          <div className="input">
            <label htmlFor="carbohydrates">Carbohydrates</label>
            <input
              className={errors.raw_carbohydrates ? "error-input" : ""}
              id="carbohydrates"
              type="number"
              step="any"
              value={rawCarbohydrates}
              onChange={e => setRawCarbohydrates(e.target.value)}
              disabled={createMealMutation.loading || updateMealMutation.loading}
            />
          </div>
          <div className="input">
            <label htmlFor="sugar">Sugar</label>
            <input
              className={errors.raw_sugar ? "error-input" : ""}
              id="sugar"
              type="number"
              step="any"
              value={rawSugar}
              onChange={e => setRawSugar(e.target.value)}
              disabled={createMealMutation.loading || updateMealMutation.loading}
            />
          </div>
          <div className="input">
            <label htmlFor="protein">Protein</label>
            <input
              className={errors.raw_protein ? "error-input" : ""}
              id="protein"
              type="number"
              step="any"
              value={rawProtein}
              onChange={e => setRawProtein(e.target.value)}
              disabled={createMealMutation.loading || updateMealMutation.loading}
            />
          </div>
          <div className="input">
            <label htmlFor="fat">Fat</label>
            <input
              className={errors.raw_fat ? "error-input" : ""}
              id="fat"
              type="number"
              step="any"
              value={rawFat}
              onChange={e => setRawFat(e.target.value)}
              disabled={createMealMutation.loading || updateMealMutation.loading}
            />
          </div>
          <div className="input">
            <label htmlFor="fibre">Fibre</label>
            <input
              className={errors.raw_fibre ? "error-input" : ""}
              id="fibre"
              type="number"
              step="any"
              value={rawFibre}
              onChange={e => setRawFibre(e.target.value)}
              disabled={createMealMutation.loading || updateMealMutation.loading}
            />
          </div>
          <div className="input">
            <label htmlFor="salt">Salt</label>
            
            <input
              className={errors.raw_salt ? "error-input" : ""}
              id="salt"
              type="number"
              step="any"
              value={rawSalt}
              onChange={e => setRawSalt(e.target.value)}
              disabled={createMealMutation.loading || updateMealMutation.loading}
            />
          </div>

        </div>}
        <div className="components">
          {components.map((component, index) => {
            const food = foodsObject[component.food];
            return (
              <div key={index} className="component">
                <img
                  src={closeIcon}
                  alt="remove"
                  className="remove"
                  onClick={() => removeComponent(index)}
                />
                <div className="input">
                  <label>Food:</label>
                  <Select 
                    options={foodOptions}
                    value={food ? {value: food.id, label: food.name} : null}
                    onChange={selection => foodSelected(index, selection.value)}
                    isDisabled={createMealMutation.loading || updateMealMutation.loading}
                    className="react-select-food"
                    placeholder=""
                    classNamePrefix="react-select"
                    filterOption={(option, string) => option.label.toLowerCase().includes(string.toLowerCase())}
                    menuIsOpen={component.showMenu}
                    onInputChange={string => inputChange(index, string)}
                  />
                </div>

                
                {food && (
                  <div className="amount">
                    
                    <div className="input">
                    <label htmlFor="amount">Amount:</label>
                      <input
                        id="amount"
                        type="number"
                        step="any"
                        required={true}
                        value={component.amount}
                        onChange={e => amountChanged(index, e.target.value)}
                        disabled={createMealMutation.loading || updateMealMutation.loading}
                      />
                    </div>
                  
                    <div className="units">{
                      food.portionDefinition ? (
                        <Select
                          value={{
                            value: component.isPortion ? "portions" : food.units,
                            label: component.isPortion ? "portions" : food.units
                          }}
                          onChange={selection => unitsSelected(index, selection.value)}
                          options={["portions", food.units].map(x => {return {value: x, label: x}})}
                          isDisabled={createMealMutation.loading || updateMealMutation.loading}
                          className="react-select"
                          classNamePrefix="react-select"
                        />
                      ) : (
                        <span className="units">{food.isPortion ? "portions" : food.units}</span>
                      )
                    }</div>
                  </div>
                )}
              </div>
            )
          })}

          <div className="bottom-row">
            <img src={addIcon} className="add" alt="add" onClick={addComponent} />
            <input
              type="submit"
              value="Save" 
              disabled={(
                (components.some(
                  component => component.food === null || !component.amount
                ) || !components.length) && !rawCalories && !rawCarbohydrates && !rawSugar && !rawProtein && !rawFat && !rawFibre && !rawSalt
              ) || createMealMutation.loading || updateMealMutation.loading}
            />
          </div>
          
        </div>
      </form>
    </WhiteBox>
  );
};

MealForm.propTypes = {
  meal: PropTypes.object,
  foods: PropTypes.array.isRequired
};

export default MealForm;