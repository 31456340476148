export const getCurrencySymbol = currency => {
  return {
    GBP: "£", USD: "$", EUR: "€"
  }[currency]
}

export const addCurrency = (numberString, currency) => {
  const symbol = getCurrencySymbol(currency)
  if (!symbol) return numberString;
  if (numberString[0] === "-") {
    return `-${symbol}${numberString.slice(1)}`;
  } else {
    return `${symbol}${numberString}`;
  }
}