import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { useMutation } from "@apollo/react-hooks";
import Toggle from "react-toggle";
import classNames from "classnames";
import { QUANTITIES, QUANTITY, PINNED_QUANTITIES } from "../queries";
import { CREATE_QUANTITY, UPDATE_QUANTITY } from "../mutations";
import WhiteBox from "../../core/components/WhiteBox";
import { createErrorObject } from "../../core/forms";

const QuantityForm = props => {
  /**
   * An interface for creating or editing a quantity.
   */

  const quantity = props.quantity;

  const history = useHistory();
  const [name, setName] = useState(quantity ? quantity.name : "");
  const [units, setUnits] = useState(quantity ? quantity.units : "");
  const [description, setDescription] = useState(quantity ? quantity.description : "");
  const [pinned, setPinned] = useState(quantity ? quantity.pinned : true);
  const [seriesType, setSeriesType] = useState(quantity ? quantity.seriesType : 1);
  const [resolution, setResolution] = useState(quantity ? quantity.resolution : null);
  const [errors, setErrors] = useState({
    name: "", units: "", description: "", pinned: "", seriesType: "", resolution: "", general: ""
  });
  const [showAdvanced, setShowAdvanced] = useState(true);

  const [createQuantity, createQuantityMutation] = useMutation(CREATE_QUANTITY, {
    onError: ({graphQLErrors}) => {
      setErrors(createErrorObject(errors, graphQLErrors));
    },
    refetchQueries: [{query: QUANTITIES, variables: {last: 150}}, {query: PINNED_QUANTITIES}],
    onCompleted: ({createQuantity: {quantity: {id}}}) => {
      history.push(`/quantities/${id}/`);
    }
  });

  const [updateQuantity, updateQuantityMutation] = useMutation(UPDATE_QUANTITY, {
    onError: ({graphQLErrors}) => {
      setErrors(createErrorObject(errors, graphQLErrors));
    },
    refetchQueries: [
      {query: QUANTITIES}, {query: PINNED_QUANTITIES},
      {query: QUANTITY, variables: {id: quantity && quantity.id}}
    ],
    onCompleted: ({updateQuantity: {quantity: {id}}}) => {
      history.push(`/quantities/${id}/`);
    }
  });

  const formSubmit = e => {
    e.preventDefault();
    if (quantity) {
      updateQuantity({
        variables: {
          id: quantity.id, name, units, description, pinned, seriesType: parseInt(seriesType)
        }
      });
    } else {
      createQuantity({
        variables: {name, units, description, pinned, seriesType: parseInt(seriesType), resolution}
      });
    }
  }

  const className = classNames({
    "quantity-form": true, "show-advanced": showAdvanced, "model-form": true
  })

  return (
    <WhiteBox className={className}>
      {quantity ? <h1>Edit Quantity</h1> : <h1>Create New Quantity</h1>}

      <form onSubmit={formSubmit}>
        {errors.general && <div className="general-error error">{errors.general}</div>}
        <div className="top-row">
          <div className="input">
            <label htmlFor="name">Quantity Name</label>
            {errors.name && <div className="error">{errors.name}</div>}
            <input
              id="name"
              value={name}
              onChange={e => setName(e.target.value)}
              disabled={createQuantityMutation.loading || updateQuantityMutation.loading}
              required
              placeholder="e.g Weight, Steps, etc."
            />
          </div>

          <div className="input">
            <label htmlFor="units">Units</label>
            {errors.units && <div className="error">{errors.units}</div>}
            <input
              id="units"
              value={units}
              onChange={e => setUnits(e.target.value)}
              disabled={createQuantityMutation.loading || updateQuantityMutation.loading}
            />
          </div>
        </div>

        <div className="input">
          <label htmlFor="description">Description</label>
          {errors.description && <div className="error">{errors.description}</div>}
          <input
            id="description"
            value={description}
            onChange={e => setDescription(e.target.value)}
            disabled={createQuantityMutation.loading || updateQuantityMutation.loading}
          />
        </div>

        <div className="input toggle-input has-advanced-toggle">
          <Toggle
              id="pinned"
              checked={pinned}
              onChange={() => setPinned(!pinned)}
          />
          <label htmlFor="pinned">Pinned Quantity</label>
          <div
            className="toggler"
            onClick={() => setShowAdvanced(!showAdvanced)}  
          >
            {showAdvanced ? "Hide" : "Show"} Advanced Options
          </div>
        </div>

        

        <div className="advanced">
          <label>What type of quantity is this?</label>
          <div className="series">
  

            <div className="options">
              <div
                className={`series-type${seriesType === 1 ? " selected" : ""}`}
                onClick={() => setSeriesType(1)}
              >
                <div className="type-name">Measurement</div>
              </div>
              <div
                className={`series-type${seriesType === 2 ? " selected" : ""}`}
                onClick={() => setSeriesType(2)}
              >
                <div className="type-name">Cumulative Count</div>
              </div>
              <div
                className={`series-type${seriesType === 3 ? " selected" : ""}`}
                onClick={() => setSeriesType(3)}
              >
                <div className="type-name">Event</div>
              </div>
              <div
                className={`series-type${seriesType === 4 ? " selected" : ""}`}
                onClick={() => setSeriesType(4)}
              >
                <div className="type-name">Event with Count</div>
              </div>
              <div
                className={`series-type${seriesType === 5 ? " selected" : ""}`}
                onClick={() => setSeriesType(5)}
              >
                <div className="type-name">Event with Measurement</div>
              </div>
            </div>

            <div className="explanation">
              {seriesType === 1 ? (<>
                <div className="series-name">Measurement</div>
                <div className="description">
                  Things which you occasionally take a measurement of.
                </div>
                <div className="examples">
                  Examples: weight, mood, body fat
                </div>
              </>) : seriesType === 2 ? (<>
                <div className="series-name">Cumulative Count</div>
                <div className="description">
                  An exact count of something that you track over time.
                </div>
                <div className="examples">
                  Examples: Number of twitter followers
                </div>
              </>) : seriesType === 3 ? (<>
                <div className="series-name">Event</div>
                <div className="description">
                  Recording when something happens.
                </div>
                <div className="examples">
                  Examples: Book completions, nose bleeds
                </div>
              </>) : seriesType === 4 ? (<>
                <div className="series-name">Event with Count</div>
                <div className="description">
                  Recording when something happens, along with an associated count.
                </div>
                <div className="examples">
                  Examples: Pushups done, daily step count
                </div>
              </>) : (<>
                <div className="series-name">Event with Measurement</div>
                <div className="description">
                  Recording when something happens, along with an associated measurement.
                </div>
                <div className="examples">
                  Examples: Migraines, nightmares
                </div>
              </>)}
            </div>
          </div>
          {!quantity && <label className="resolution-label">What time period are values associated with?</label>}
          {!quantity && <div className="resolutions">
            <div
              className={`resolution${resolution === null ? " selected" : ""}`}
              onClick={() => setResolution(null)}
            >
              Any Time
            </div>
            <div
              className={`resolution${resolution === "D" ? " selected" : ""}`}
              onClick={() => setResolution("D")}
            >
              Day
            </div>
            <div
              className={`resolution${resolution === "W" ? " selected" : ""}`}
              onClick={() => setResolution("W")}
            >
              Week
            </div>
            <div
              className={`resolution${resolution === "M" ? " selected" : ""}`}
              onClick={() => setResolution("M")}
            >
              Month
            </div>
            <div
              className={`resolution${resolution === "Y" ? " selected" : ""}`}
              onClick={() => setResolution("Y")}
            >
              Year
            </div>
          </div>}
        
        </div>


        <input
          type="submit"
          value="Save" 
          disabled={createQuantityMutation.loading || updateQuantityMutation.loading}
        />
      </form>

    </WhiteBox>
  )
}

QuantityForm.propTypes = {
  quantity: PropTypes.object,
}

export default QuantityForm;