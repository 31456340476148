import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { useQuery } from "@apollo/react-hooks";
import queryString from "query-string";
import moment from "moment-timezone";
import { FOOD_DAY_WITH_MEALS } from "../queries";
import Base from "../../core/components/Base";
import DatetimeInput from "../../core/components/DatetimeInput";
import NutrientGrid from "../../food/components/NutrientGrid";
import { getCurrentDay } from "../../core/time";
import Loader from "../../core/components/Loader";
import Meal from "../components/Meal";
import { UserContext } from "../../core/context";

const NutritionPage = () => {
  /**
   * The general nutrition page.
   */

  const history = useHistory();
  const params = queryString.parse(history.location.search);
  let date = params.date;

  const user = useContext(UserContext);
  
  date = date || getCurrentDay(user);

  const { data, loading } = useQuery(FOOD_DAY_WITH_MEALS, {
    variables: {date: date}, skip: date === undefined, fetchPolicy: "network-only"
  });
  const meals = !data ? [] : data.user.meals.edges.map(edge => edge.node);
  const dayTimestamp = moment.utc(date).valueOf();

  const changeDate = datetime => {
    const newDate = moment(datetime).tz("UTC").format("YYYY-MM-DD");
    history.push(`/food?date=${newDate}`);
  }

  const keyDown = e => {
    if (e.keyCode === 37 && !e.ctrlKey && !e.shiftKey) {
      if (!document.querySelector(".date-modal.displayed")) {
        const newDate = moment.utc(date).subtract(1, "day");
        document.removeEventListener("keydown", keyDown, false);
        changeDate(newDate);
      }
    }
    if (e.keyCode === 39 && !e.ctrlKey && !e.shiftKey) {
      if (!document.querySelector(".date-modal.displayed")) {
        const newDate = moment.utc(date).add(1, "day");
        document.removeEventListener("keydown", keyDown, false);
        changeDate(newDate);
      }
    }
  }

  useEffect(() => {
    document.title = "Food - lytiko";
  });

  useEffect(() => {
    document.addEventListener("keydown", keyDown, false);
    return () => document.removeEventListener("keydown", keyDown, false);
  }, [date])

  if (!data || loading) {
    return (
      <Base className="nutrition-page">
        <Loader />
      </Base>
    )
  }

  return (
    <Base className="nutrition-page" onKeyDown={keyDown}>
      <div className="heading">
        <h1>{moment(date).format("dddd D MMMM, YYYY")}</h1>
        <DatetimeInput
          id="date"
          resolution="D"
          datetime={dayTimestamp}
          timezone="UTC"
          setDatetime={changeDate}
          setTimezone={() => {}}
          disabled={false}
          closeOnChange={true}
        />
      </div>
      
      
      <NutrientGrid
        calories={data.user.meals.calories}
        carbohydrates={data.user.meals.carbohydrates}
        sugar={data.user.meals.sugar}
        protein={data.user.meals.protein}
        fat={data.user.meals.fat}
        fibre={data.user.meals.fibre}
        salt={data.user.meals.salt}
      />
      <h2>Meals</h2>
      {meals.length ? <div className="meal-grid">
        {meals.map(meal => <Meal key={meal.id} meal={meal} />)}
      </div> : <div className="no-data">There are no meals for this day.</div>}
    </Base>
    
  )
}

export default NutritionPage;