import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import paginateFirstIcon from "../../images/paginate-first.svg";
import paginatePreviousIcon from "../../images/paginate-previous.svg";
import paginateNextIcon from "../../images/paginate-next.svg";
import paginateLastIcon from "../../images/paginate-last.svg";

const TablePaginator = props => {
  /**
   * A widget for moving through pages in table.
   */

  const {rowCount, tablePage, setTablePage, rowsPerPage} = props;

  const pageCount = Math.ceil(rowCount / rowsPerPage);

  let nearPages = [tablePage - 2, tablePage - 1, tablePage, tablePage + 1, tablePage + 2];
  while (nearPages[0] < 1) {
    nearPages = nearPages.map(x => x + 1);
  }
  while (nearPages[4] > pageCount) {
    nearPages = nearPages.map(x => x - 1);
  }
  if (pageCount < 5) {
    nearPages = [1, 2, 3, 4, 5].slice(0, pageCount);
  }


  const goToFirst = () => {
    setTablePage(1);
  }

  const goToLast = () => {
    setTablePage(pageCount);
  }

  const goToPrevious = () => {
    if (tablePage > 1) {
      setTablePage(tablePage - 1);
    }
  }
  
  const goToNext = () => {
    if (tablePage < pageCount) {
      setTablePage(tablePage + 1);
    }
  }

  const goToPage = e => {
    setTablePage(parseInt(e.target.innerText));
  }

  return (
    <div className="table-paginator">
      <img
        className={`navigator ${tablePage === 1 && "disabled"}`}
        onClick={goToFirst}
        src={paginateFirstIcon} alt="first" 
      />
      <img
        className={`navigator ${tablePage === 1 && "disabled"}`}
        onClick={goToPrevious}
        src={paginatePreviousIcon} alt="previous"
      />
      
      {nearPages.map(pageNum => {
        let className = classNames({
          navigator: true, number: true, selected: tablePage === pageNum,
          proximate: Math.abs(pageNum - tablePage) <= 1
        })
        return (
          <div
            className={className}
            onClick={goToPage}
            key={pageNum}
          >
            {pageNum}
          </div>
        )
      })}

      <img
        className={`navigator ${tablePage === pageCount && "disabled"}`}
        onClick={goToNext}
        src={paginateNextIcon} alt="next" 
      />
      <img
        className={`navigator ${tablePage === pageCount && "disabled"}`}
        onClick={goToLast}
        src={paginateLastIcon} alt="last"
      />
    </div>
  )
}

TablePaginator.propTypes = {
  rowCount: PropTypes.number.isRequired,
  tablePage: PropTypes.number.isRequired,
  setTablePage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
}

export default TablePaginator;