import React, { useState, useContext, useRef } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import calendarIcon from "../../images/calendar.svg";
import leftArrow from "../../images/left.svg";
import rightArrow from "../../images/right.svg";
import Modal from "./Modal";
import { UserContext } from "../context";
import { timeString, dayString, getCurrentDay } from "../time";

const DatetimeInput = props => {
  /**
   * An input element for controlling a numerical datetime and a timezone.
   */

  // Need date, datetime and timezone passed in, as well as HTML info, and flags
  // indicating whether to display time info, and whether to close the modal
  // when a day is selected.
  let {
    datetime, setDatetime,
    timezone, setTimezone,
    id, disabled,
    closeOnChange, resolution, hideWeekday } = props;
  
  // Use an internal datetime, only use if closeOnChange is true
  const [internalDatetime, setInternalDatetime] = useState(datetime);

  // Decide which to use
  const actualDatetime = closeOnChange ? internalDatetime : datetime
  const setActualDatetime = closeOnChange ? setInternalDatetime : setDatetime

  // What is the app timezone?
  const user = useContext(UserContext);
  const appTimezone = user.timezone || moment.tz.guess();

  // Get the relevant month as array of week days
  const monthStart = moment(actualDatetime).tz(appTimezone).startOf("month");
  const dayOfWeek = monthStart.day();
  let day = 0;
  if (user.weekStartsMonday && dayOfWeek === 0) {
    day -= 5;
  } else {
    day = -monthStart.day() + 1 + user.weekStartsMonday;
  }
  const weeks = [[]];
  while(day <= monthStart.daysInMonth()) {
    weeks[weeks.length - 1].push(day > 0 ? day : null);
    if (weeks[weeks.length - 1].length === 7) weeks.push([]);
    day += 1;
  }
  while (weeks[weeks.length - 1].length !== 7) weeks[weeks.length - 1].push(null);

  // Keep track of whether the modal should be shown
  const [showModal, setShowModal] = useState(false);

  // Keep track of whether text is being entered
  const [editingText, setEditingText] = useState(false);

  // What should be displayed in the input element?
  const inputValue = resolution ? dayString(actualDatetime, resolution, hideWeekday) : timeString(actualDatetime, timezone, appTimezone, hideWeekday);

  // Get moment version datetime
  datetime = moment(actualDatetime).tz(timezone);

  const minuteElement = useRef(null);
  const [tempHour, setTempHour] = useState(null);
  const [tempMinute, setTempMinute] = useState(null);

  const keyDown = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      if (closeOnChange) setDatetime(datetime.valueOf());
      setShowModal(false);
    }
    if (e.keyCode === 37 && !editingText) {
      e.preventDefault();
      e.stopPropagation();
      if (e.ctrlKey && e.shiftKey) {
        datetime.subtract(1, "year");
        setActualDatetime(datetime.valueOf());
      } else if (e.shiftKey) {
        prevMonth();
      } else {
        datetime.subtract(1, "day");
        setActualDatetime(datetime.valueOf());
      }
    }
    if (e.keyCode === 39 && !editingText) {
      e.preventDefault();
      e.stopPropagation();
      if (e.ctrlKey && e.shiftKey) {
        datetime.add(1, "year");
        setActualDatetime(datetime.valueOf());
      } else if (e.shiftKey) {
        nextMonth();
      } else {
        datetime.add(1, "day");
        setActualDatetime(datetime.valueOf());
      }
    }
    if (e.keyCode === 38 && !editingText) {
      e.preventDefault();
      e.stopPropagation();
      datetime.subtract(7, "day");
      setActualDatetime(datetime.valueOf());
    }
    if (e.keyCode === 40 && !editingText) {
      e.preventDefault();
      e.stopPropagation();
      datetime.add(7, "day");
      setActualDatetime(datetime.valueOf());
    }
  }

  // Function for setting to now
  const setNow = () => {
    if (resolution) {
      const day = moment.utc(getCurrentDay(user, resolution));
      setActualDatetime(day.valueOf());
    } else {
      setActualDatetime(moment().set("second", 0).set("millisecond", 0).valueOf());
    }
  }

  // Function for moving to previous month
  const prevMonth = () => {
    datetime.subtract(1, resolution === "Y" ? "year" : "month");
    setActualDatetime(datetime.valueOf());
  }

  // Function for moving to next month
  const nextMonth = () => {
    datetime.add(1, resolution === "Y" ? "year" : "month");
    setActualDatetime(datetime.valueOf());
  }

  // Function for moving to specific day
  const daySelected = e => {
    const day = e.target.innerText;
    if (day.length) {
      datetime.set("date", parseInt(day));
      setDatetime(datetime.valueOf());
      if (closeOnChange) setShowModal(false);
    }
  }

  // Function for selecting hour
  const hourSelected = e => {
    setTempHour(e.target.value);
    if (closeOnChange) setShowModal(false);
    if (e.target.value.length === 2) {
      setTimeout(() => minuteElement.current.select(), 0);
    }
  }

  const setHour = () => {
    datetime.set("hour", tempHour);
    setEditingText(false);
    setDatetime(datetime.valueOf());
    setTempHour(null);
    if (closeOnChange) setShowModal(false);
  }

  const hourWheel = e => {
    if (e.deltaY > 2 || e.deltaY < -2) {
      datetime.set("hour", datetime.hour() + (e.deltaY > 0 ? 1 : -1));
      setDatetime(datetime.valueOf());
    }
  }

  // Function for selecting minute
  const minuteSelected = e => {
    setTempMinute(e.target.value);
    if (closeOnChange) setShowModal(false);
  }

  const setMinute = () => {
    datetime.set("minute", tempMinute);
    setEditingText(false);
    setDatetime(datetime.valueOf());
    setTempMinute(null);
    if (closeOnChange) setShowModal(false);
  }

  const minuteWheel = e => {
    if (e.deltaY > 2 || e.deltaY < -2) {
      datetime.set("minute", datetime.minute() + (e.deltaY > 0 ? 1 : -1));
      setDatetime(datetime.valueOf());
    }
  }

  // Function for selecting timezone
  const timezoneSelected = e => {
    setTimezone(e.target.value);
    setDatetime(datetime.tz(e.target.value).valueOf());
    if (closeOnChange) setShowModal(false);
  } 

  
  return (
    <div
      className="datetime-input"
      onKeyDown={keyDown}
      tabIndex="-1"
    >
      <input
        id={id}
        value={inputValue}
        disabled={disabled}
        onClick={() => setShowModal(true)} 
        readOnly
      />
      <img className="icon" alt="" src={calendarIcon} onClick={() => setShowModal(true)} />

      <Modal setShowModal={setShowModal} showModal={showModal} className="date-modal">
        <button className="now" type="button" onClick={setNow} tabIndex="-1">Now</button>
        <div className="date-heading">Date</div>
        <div className="month-row">
          <img src={leftArrow} className="left" alt="previous" onClick={prevMonth} />
          {resolution !== "Y" && <div className="month">{moment.months(datetime.month())}</div>}
          <div className="year">{datetime.year()}</div>
          <img src={rightArrow} className="right" alt="next" onClick={nextMonth} />
        </div>
        {resolution !== "M" && resolution !== "Y" && <div className="table-container">
          <table>
            <thead>
              <tr>
                {user.weekStartsMonday ? (
                  <><th>M</th><th>T</th><th>W</th><th>T</th><th>F</th><th>S</th><th>S</th></>
                ) : (
                  <><th>S</th><th>M</th><th>T</th><th>W</th><th>T</th><th>F</th><th>S</th></>
                )}
              </tr>
            </thead>
            <tbody>
              {weeks.map((week, weekNum) => {
                
                return (
                  <tr key={weekNum}>{week.map((day, dayNum) => {
                    const notAllowed = day === null || (resolution === "W" && dayNum !== (user.weekStartsMonday ? 0 : 1));
                    return (
                      <td
                        key={dayNum}
                        className={day === datetime.date() ? "selected" : notAllowed ? "invalid" : ""}
                        onClick={notAllowed ? () => {} : daySelected}
                      >{day}</td>
                    )
                  })}</tr>
                )
              })}
            </tbody>
          </table>
        </div>}
        {!resolution && <>
          <div className="time-row">
            <div className="date-heading">Time</div>
            <input
              type="number"
              autoFocus={true}
              max="23" min="0"
              value={tempHour === null ? datetime.hour().toString().padStart(2, "0") : tempHour}
              onChange={hourSelected}
              onFocus={() => setEditingText(true)}
              onBlur={setHour}
              onWheel={hourWheel}
            />
            <input
              type="number"
              max="59" min="0"
              ref={minuteElement}
              value={tempMinute === null ? datetime.minute().toString().padStart(2, "0") : tempMinute}
              onChange={minuteSelected}
              onFocus={() => setEditingText(true)}
              onBlur={setMinute}
              onWheel={minuteWheel}
            />
          </div>
          <select className="timezone" value={timezone} onChange={timezoneSelected}>
            {moment.tz.names().map(tz => {
              return <option value={tz} key={tz}>{tz}</option>
            })}
          </select>
        </>}
      </Modal>
    </div>
  );
}

DatetimeInput.propTypes = {
  datetime: PropTypes.number.isRequired,
  timezone: PropTypes.string.isRequired,
  setDatetime: PropTypes.func.isRequired,
  setTimezone: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  closeOnChange: PropTypes.bool
}

export default DatetimeInput;