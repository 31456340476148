import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import roundTo from "round-to";
import WhiteBox from "../../core/components/WhiteBox";

const NutrientGrid = props => {

  const { calories, carbohydrates, sugar, protein, fat, fibre, salt } = props;

  return (
    <div className="nutrient-grid">
      {calories !== undefined && <WhiteBox className="nutrient calories" element={Link} to="/food/nutrient/calories/">
        <div className="nutrient-name">Calories</div>
        <div className="nutrient-value">{roundTo(calories, 0)}<span className="units">kcal</span></div>
      </WhiteBox>}
      {carbohydrates !== undefined && <WhiteBox className="nutrient carbohydrates" element={Link} to="/food/nutrient/carbohydrates/">
        <div className="nutrient-name">Carbs</div>
        <div className="nutrient-value">{roundTo(carbohydrates, 1)}<span className="units">g</span></div>
      </WhiteBox>}
      {sugar !== undefined && <WhiteBox className="nutrient sugar" element={Link} to="/food/nutrient/sugar/">
        <div className="nutrient-name">Sugar</div>
        <div className="nutrient-value">{roundTo(sugar, 1)}<span className="units">g</span></div>
      </WhiteBox>}
      {protein !== undefined && <WhiteBox className="nutrient protein" element={Link} to="/food/nutrient/protein/">
        <div className="nutrient-name">Protein</div>
        <div className="nutrient-value">{roundTo(protein, 1)}<span className="units">g</span></div>
      </WhiteBox>}
      {fat !== undefined && <WhiteBox className="nutrient fat" element={Link} to="/food/nutrient/fat/">
        <div className="nutrient-name">Fat</div>
        <div className="nutrient-value">{roundTo(fat, 1)}<span className="units">g</span></div>
      </WhiteBox>}
      {fibre !== undefined && <WhiteBox className="nutrient fibre" element={Link} to="/food/nutrient/fibre/">
        <div className="nutrient-name">Fibre</div>
        <div className="nutrient-value">{roundTo(fibre, 1)}<span className="units">g</span></div>
      </WhiteBox>}
      {salt !== undefined && <WhiteBox className="nutrient salt" element={Link} to="/food/nutrient/salt/">
        <div className="nutrient-name">Salt</div>
        <div className="nutrient-value">{roundTo(salt, 2)}<span className="units">g</span></div>
      </WhiteBox>}
    </div>
  );
};

NutrientGrid.propTypes = {
  calories: PropTypes.number,
  carbohydrates: PropTypes.number,
  sugar: PropTypes.number,
  protein: PropTypes.number,
  fat: PropTypes.number,
  fibre: PropTypes.number,
  salt: PropTypes.number,
};

export default NutrientGrid;