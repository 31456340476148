import React, { useEffect } from "react";
import Base from "../../core/components/Base";
import QuantityForm from "../components/QuantityForm";

const NewQuantityPage = () => {
  /**
   * Page for creating new quantities.
   */
  
  useEffect(() => {
    document.title = "New Quantity - lytiko";
  });

  return (
    <Base className="new-quantity-page">
      <QuantityForm />
    </Base>
  )
}

export default NewQuantityPage;