import React, { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { FOOD, FOOD_HISTORY } from "../queries";
import Food from "../components/Food";
import FoodChart from "../components/FoodChart";
import WhiteBox from "../../core/components/WhiteBox";
import Base from "../../core/components/Base";
import BaseNotFoundPage from "../../core/pages/BaseNotFoundPage";

const FoodPage = () => {
  /**
   * Information about a specific food
   */

  const foodId = useRouteMatch("/food/:id").params.id;

  const {loading, data, error} = useQuery(FOOD, {variables: {id: foodId}});
  const food = loading || error ? {} : data.user.food;

  const {data: historyData} = useQuery(FOOD_HISTORY, {
    variables: {id: foodId},
    skip: loading || error
  });
  const foodWithHistory = historyData ? historyData.user.food : {};

  useEffect(() => {
    if (!loading && !error) {
      document.title = `${food.name} - lytiko`;
    }
  });

  if (error) {
    try {
      const message = JSON.parse(error.graphQLErrors[0].message);
      if (message.food === "Does not exist") {
        return <BaseNotFoundPage />
      }
    } catch {  }
  }

  if (loading) {
    return (
      <Base className="food-page">
        <div className="food-page-top">
          <WhiteBox loading={true} />
        </div>
      </Base>
    )
  }

  return (
    <Base className="food-page">
      <div className="food-page-top">
        <Food food={food} />
        {historyData ? <FoodChart food={foodWithHistory} /> : <WhiteBox loading={true} />}
      </div>
    </Base>
  )
}

export default FoodPage;