import React from "react";
import PropTypes from "prop-types";

const SvgSeries = props => {
  /**
   * A simple SVG series summary.
   */

  const { series, lineWidth, step, dots } = props;
  const points = [];

  const uniqueSeries = [];
  for (let s = series.length - 1; s >= 0; s--) {
    if (uniqueSeries.length && series[s][0] === uniqueSeries[uniqueSeries.length - 1][0]) continue
    uniqueSeries.push(series[s]);
  }
  uniqueSeries.reverse();

  if (uniqueSeries.length > 1) {
    const minX = uniqueSeries[0][0];
    const xSpan = uniqueSeries[uniqueSeries.length - 1][0] - minX;
    const values = uniqueSeries.map(x => x[1]);
    let minY = Math.min.apply(Math, values);
    let ySpan = Math.max.apply(Math, values) - minY;
    if (ySpan === 0) {
      minY -= 1;
      ySpan = 2;
    }
    if (xSpan !== 0) {
      if (dots) {
        return (
          <svg className="svg-series" viewBox={`0 0 200 100`} preserveAspectRatio="xMidYMid slice">
            {uniqueSeries.map((d, index) => {
              const x = (((d[0] - minX) / xSpan) * (200 - 4)) + 2;
              return <circle key={index} cx={x} cy={50} r="2"/>
            })}
          </svg>
        )
      }
      let x = 0; let y = 0;
      for (let d = 0; d < uniqueSeries.length; d++) {
        x = (uniqueSeries[d][0] - minX) / xSpan * (100 -  2 * lineWidth) + lineWidth;
        if (d !== 0 && step) {
          points.push(`L ${x} ${y}`);
        }
        y = 100 - (uniqueSeries[d][1] - minY) / ySpan * (100 - 2 * lineWidth) - lineWidth;
        points.push(`${d === 0 ? 'M' : 'L'} ${x} ${y}`)
      }
    }
  }

  return (
    <svg className="svg-series" viewBox={`0 0 100 100`} preserveAspectRatio="none">
      <path
        d={points.join(" ")}
        strokeWidth={`${lineWidth}px`}
        fill="none"
        vectorEffect="non-scaling-stroke"
      />
    </svg>
  )
}

SvgSeries.propTypes = {
  series: PropTypes.array.isRequired,
  lineWidth: PropTypes.number.isRequired
}

export default SvgSeries;