import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import DeleteAccountModal from "./DeleteAccountModal";
import SvgSeries from "../../core/components/SvgSeries";
import { createSeries } from "../../core/charts";
import Dropdown from "../../core/components/Dropdown";
import editIcon from "../../images/edit.svg";
import compareIcon from "../../images/compare.svg";
import deleteIcon from "../../images/delete.svg";
import WhiteBox from "../../core/components/WhiteBox";
import { addCurrency } from "../../core/numbers";

const Account = props => {
  /**
   * A box with account information in it. It can optionally act as a link to
   * the account's page, and if so, will have slightly different behaviour.
   */

  const {account, link, showSummary} = props;

  const [showModal, setShowModal] = useState(false);

  const boxProps = {className: "account"};
  if (link) {
    boxProps.to = `/money/${account.id}/`;
    boxProps.element = Link;
  }

  const deleteClicked = () => {
    setShowModal(true);
  }

  let series = createSeries(account.series, {});
  series = series.data;

  const links = [
    {href: `/money/${account.id}/edit/`, icon: editIcon, text: "Edit"},
    {href: `/compare?comparitor1=account-${account.id}`, icon: compareIcon, text: "Compare"},
    {action: deleteClicked, icon: deleteIcon, text: "Delete"}
  ]

  return (
    <WhiteBox {...boxProps}>
      <div className="top-row">
        <div className="info">
          <div className="account-name">{account.name}</div>
          <div className="account-description">{account.description}</div>
        </div>
        {!link && <Dropdown links={links} />}
      </div>

      <div className="bottom-row">
        <div className="value">
          {addCurrency(Number(account.latestBalance).toLocaleString(), account.currency)}
        </div>
        {link && showSummary && <div className="series-container">
          <SvgSeries series={series} lineWidth={1.5} step={true} />
        </div>}
      </div>
      <DeleteAccountModal
        account={account} 
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </WhiteBox>
  )
}

Account.propTypes = {
  account: PropTypes.object.isRequired,
  link: PropTypes.bool.isRequired,
  showSummary: PropTypes.bool
}

export default Account;