import React, { useState, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { ACCOUNT, ACCOUNT_TRANSACTIONS } from "../queries";
import Account from "../components/Account";
import WhiteBox from "../../core/components/WhiteBox";
import TransactionForm from "../components/TransactionForm";
import AccountChart from "../components/AccountChart";
import TransactionsTable from "../components/TransactionsTable";
import Base from "../../core/components/Base";
import BaseNotFoundPage from "../../core/pages/BaseNotFoundPage";

const AccountPage = () => {
  /**
   * Information about a specific account
   */

  const accountId = useRouteMatch("/money/:id").params.id;

  const [tablePage, setTablePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const {loading, data, error } = useQuery(ACCOUNT, {
    variables: {id: accountId},
  });
  const account = loading || error ? {measurements: {edges: []}} : data.user.account;

  const transactionsQuery = useQuery(ACCOUNT_TRANSACTIONS, {
    variables: {id: accountId, first: rowsPerPage, offset: (tablePage - 1) * rowsPerPage},
    skip: loading || error,
    notifyOnNetworkStatusChange: true,
  });
  const accountWithSessions = (
    loading || error || transactionsQuery.loading || transactionsQuery.error
  ) ? {} : transactionsQuery.data.user.account;
  
  useEffect(() => {
    if (!loading && !error) {
      document.title = `${account.name} - lytiko`;
    }
  });

  if (error) {
    try {
      const message = JSON.parse(error.graphQLErrors[0].message);
      if (message.account === "Does not exist") {
        return <BaseNotFoundPage />
      }
    } catch {  }
  }

  if (loading) {
    return (
      <Base className="account-page">
        <div className="account-page-top">
          <WhiteBox loading={true} />
          <WhiteBox loading={true} />
        </div>
        <WhiteBox className="lytiko-chart" loading={true} />
        <WhiteBox className="lytiko-chart" loading={true} />
      </Base>
    )
  }

  return (
    <Base className="account-page">
      <div className="account-page-top">
        <Account link={false} account={account} />
        <TransactionForm account={account} refetch={transactionsQuery.refetch} />
      </div>
      <AccountChart account={account} />
      {transactionsQuery.loading ? <WhiteBox className="lytiko-chart" loading={true} /> : (
        <TransactionsTable
          account={accountWithSessions}
          tablePage={tablePage} setTablePage={setTablePage}
          rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage}
          refetch={transactionsQuery.refetch}
        />
      )}
    </Base>
  )
}

export default AccountPage;