import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import Toggle from "react-toggle";
import classNames from "classnames";
import compareIcon from "../../images/compare.svg";
import { seriesToMovingAverage, createSeries, createChartConf } from "../../core/charts";
import LytikoChart from "../../core/components/LytikoChart";
import WhiteBox from "../../core/components/WhiteBox";
import { UserContext } from "../../core/context";
import Dropdown from "../../core/components/Dropdown";

const NutrientChart = props => {
  /**
   * A data visualisation for a nutrient.
   */

  const { nutrient } = props;
  const user = useContext(UserContext);
  const [showTrendline, setShowTrendline] = useState(true);

  if (nutrient.series.data.length < 1) {
    return (
      <WhiteBox className="nutrient-chart">
        <div className="notice">Need more data to display chart.</div>
      </WhiteBox>
    )
  }

  // Create series
  const series = createSeries(nutrient.series, {
    fillGaps: true, totals: true, dayEnds: user.dayEnds
  });
  const options = createChartConf(user.timezone || moment.tz.guess());
  options.series = [series];

  // Enough dates for a trendline?
  const canShowTrendline = series.data.filter(d => d[1] > 0).length > (0.3 * series.data.length);
  if (canShowTrendline && showTrendline) {
    options.series.push(seriesToMovingAverage(series))
  }
  const className = classNames({
    "nutrient-chart": true, [`${nutrient.series.name.toLowerCase()}-chart`]: true,
    "has-trendline": canShowTrendline && showTrendline
  })
  
  const links = [
    {href: `/compare?comparitor1=nutrient-${nutrient.series.name}`, icon: compareIcon, text: "Compare"},
  ]

  return (
    <WhiteBox className={className}>
      <Dropdown links={links} />
      <LytikoChart options={options} />
      {canShowTrendline && <div className="chart-controls">
        <div className="toggle-input">
          <Toggle
              id="trendline"
              checked={showTrendline}
              onChange={() => setShowTrendline(!showTrendline)}
          />
          <label htmlFor="trendline">Show Trendline</label>
        </div>
      </div>}
    </WhiteBox>
  )
}

NutrientChart.propTypes = {
  nutrient: PropTypes.object.isRequired,
}

export default NutrientChart;