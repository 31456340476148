import roundTo from "round-to";
import moment from "moment-timezone";
import { timeString, dayString, durationString } from "./time";

export const createSeries = (series, options) => {
  /**
   * Creates a highcharts series object from a lytiko series object.
   * 
   * As well as the required highcharts properties of data, marker, step and
   * type, it will also have properties for units, resolution and timezones,
   * which the tooltip function uses.
   */

  if (!options) options = {};
  const hasComponents = series.data.filter(
    d => d.components && d.components.length
  ).length > 0;
  let type = "scatter";
  if (series.seriesType === 2) type = "line";
  if (series.seriesType === 3 && hasComponents) type = "column";
  if (series.seriesType === 4) type = "column";
  if (series.seriesType === 5 && series.resolution) type = "column";
  
  let data = []; let componentInfo = undefined;
  if ((series.seriesType === 3 || series.seriesType === 4) && hasComponents) {
    componentInfo = {totals: {}, dts: []};
    for (let d of series.data) {
      componentInfo.totals[d.datetime * 1000] = d.value;
      for (let component of d.components) {
        data.push([d.datetime * 1000, series.seriesType === 3 ? 1 : component.value])
        componentInfo.dts.push([component.datetime * 1000, component.timezone])
      }
    }
  } else {
    data = series.data.map(d => [
      d.datetime * 1000, series.seriesType === 3 ? 0 : d.value
    ]).sort((a, b) => a[0] - b[0]);
  }
  const className = `series ${options.className || ""}`

  const conf = {
    data: data, type: type,
    marker: type === "line" ? {radius: 0} : {symbol: "circle"},
    step: type === "line" ? "left": undefined, className,
    units: series.units,
    yAxis: options.yAxis || 0,
    resolution: series.resolution,
    seriesType: series.seriesType,
    timezones: series.data.map(d => d.timezone),
    componentInfo
  };

  if (conf.type === "column") {
    conf.groupPadding = 0;
    conf.pointPadding = series.data.length < 10 ? 0.2 : 0;
    conf.pointWidth = null;
  }

  return conf;
}


export function tooltipFormatter(tooltip) {
  /**
   * Generates the content of a datapoint's tooltip when hovered over.
   */

  const series = tooltip.chart.userOptions.series[this.series.index];
  const units = series.units;
  const resolution = series.resolution;
  const userTimezone = tooltip.chart.userOptions.timezone || "UTC";
  const timeChart = tooltip.chart.userOptions.timeChart;

  if (series.componentInfo) {
    let componentDt = series.componentInfo.dts[this.point.index];
    componentDt = resolution === "D" ? timeString(
      componentDt[0], componentDt[1], userTimezone
    ) : dayString(componentDt[0], {W: "D", M: "D", Y: "M"}[resolution]);
    const totalDt = dayString(this.point.x, resolution);
    const componentValue = series.seriesType === 3 ? " " : `${roundTo(this.point.y, 2)}${units}`;
    const totalValue = `${roundTo(series.componentInfo.totals[this.point.x], 2)}${units}`;

    return `
      <div class="tooltip-top">${componentDt}</div><br>
      <div class='tooltip-bottom'>${componentValue}</div><br><br>
      <div class="tooltip-top">${totalDt}</div><br>
      <div class='tooltip-bottom'>${totalValue}</div>
    `;
  }
  const timezone = series.timezones ? series.timezones[this.point.index] : "UTC";
  const time = resolution ? dayString(this.point.x, resolution) : timeString(this.point.x, timezone, userTimezone);
  const value = series.seriesType === 3 ? "" : `${timeChart ? durationString(this.point.y) : roundTo(this.point.y, 2)}${units}`;
  
  return `
    <div class="tooltip-top">${time}</div><br>
    <div class='tooltip-bottom'>${value}</div>
  `;
}

export const createChartConf = (timezone) => ({
  chart: {zoomType: "x", padding: 0},
  credits: {enabled: false},
  title: {text: null},
  plotOptions: {column: {stacking: "normal"}},
  legend: {enabled: false},
  xAxis: {type: "datetime"},
  yAxis: {title: null},
  tooltip: {
    formatter: tooltipFormatter,
    style: {lineHeight: "20"},
    borderRadius: 6,
    shadow: false
  },
  timezone
});


export const seriesDataToMovingAverageData = data => {
  /**
   * Takes a highcharts series and generates a moving average data series from
   * it.
   */

  const movingAverageData = [];
  const window = Math.max(Math.min(Math.floor(data.length / 10) * 2, 10), 2); // even
  const halfWindow = window / 2;
  for (let i = 0; i < data.length; i++) {
    if (i >= halfWindow && i < data.length - halfWindow) {
      const windowData = data.slice(i - halfWindow + 1, i + halfWindow + 1);
      const sum = windowData.map(x => x[1]).reduce((a,b) => a + b, 0);
      movingAverageData.push([data[i][0], sum / windowData.length]);
    }
  }
  return movingAverageData;
}


export const seriesToMovingAverage = series => {
  /**
   * Creates a moving average series - unless the original series is a scatter
   * series, it will be shifted to the right.
   */
  
  let data = series.data;
  if (series.componentInfo) {
    data = Object.entries(series.componentInfo.totals).map(d => [parseInt(d[0]), d[1]]);
  }
  const movingAverageData = seriesDataToMovingAverageData(data);
  if (series.seriesType === 4 || series.seriesType === 3) {
    for (let n = movingAverageData.length - 1; n >= 0; n--) {
      movingAverageData[n][0] = data[data.length - (movingAverageData.length - n)][0];
    }
  }
  return {
    data: movingAverageData, units: series.units, resolution: series.resolution,
    timezones: series.timezones, yAxis: series.yAxis, marker: {radius: 0}
  };
}


const timestampToResolution = (timestamp, resolution) => {
  if (!resolution) return timestamp
  const day = timestamp - (timestamp % 86400);
  if (resolution === "D") return day
  if (resolution === "W") {
    return day - (moment(day * 1000).weekday() * 86400)
  }
  if (resolution === "M") {
    return moment()
  }
}


export const extendSeries = series => {
  timestampToResolution(moment().valueOf() / 1000)
  //const now = 
  if (series.data.length) {
    if (series.seriesType === 2) {
      series.data.push([new Date().getTime(), series.data[series.data.length - 1][1]])
    }
    if (series.seriesType === 4) {
      //console.log(series)
      //series.data.push([new Date().getTime(), series.data[series.data.length - 1][1]])
    }
  }
}