import React, { useEffect } from "react";
import Base from "../../core/components/Base";
import FoodForm from "../components/FoodForm";

const NewFoodPage = () => {
  /**
   * Page for creating new foods.
   */
  
  useEffect(() => {
    document.title = "New Food - lytiko";
  });

  return (
    <Base className="new-food-page">
      <FoodForm />
    </Base>
  )
}

export default NewFoodPage;