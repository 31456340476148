import React, { useContext } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import classNames from "classnames";
import { UserContext } from "../../core/context";
import { createSeries, createChartConf } from "../../core/charts";
import WhiteBox from "../../core/components/WhiteBox";
import LytikoChart from "../../core/components/LytikoChart";

const AccountChart = props => {
  /**
   * A data visualisation for a account.
   */

  const { account } = props;
  const series = account.series;
  const user = useContext(UserContext);

  if (account.series.data.length < 2) {
    return (
      <WhiteBox className="lytiko-chart">
        <div className="notice">Need at least two datapoints to display chart.</div>
      </WhiteBox>
    )
  }

  // Create chart conf
  const options = createChartConf(user.timezone || moment.tz.guess());

  // Add series
  options.series = [createSeries(series)]

  // y axis
  options.yAxis.labels = {enabled: true};
  options.yAxis.gridLineWidth = 1;
  
  const className = classNames({
    "account-chart": true, "has-trendline": false,
  })
  
  return (
    <WhiteBox className={className}>
      <LytikoChart options={options} />
    </WhiteBox>
  )
}

AccountChart.propTypes = {
  account: PropTypes.object.isRequired,
}

export default AccountChart;