import React, { useEffect, useContext } from "react";
import { useRouteMatch } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { NUTRIENT } from "../queries";
import WhiteBox from "../../core/components/WhiteBox";
import Base from "../../core/components/Base";
import NutrientChart from "../components/NutrientChart";
import { UserContext } from "../../core/context";

const NutrientPage = () => {
  /**
   * Information about a specific nutrient
   */

  const user = useContext(UserContext);
  const nutrient = useRouteMatch("/food/nutrient/:id/").params.id;
  const nutrientName = nutrient.charAt(0).toUpperCase() + nutrient.slice(1);

  const {loading, data} = useQuery(NUTRIENT, {
    variables: {name: nutrient}, fetchPolicy: "network-only"
  });
  
  useEffect(() => {
    document.title = `${nutrientName} - lytiko`;
  });

  if (loading) {
    return (
      <Base className="nutrient-page">
        <h1>{nutrientName}</h1>
        <WhiteBox className="nutrient-chart" loading={true} />
      </Base>
    )
  }

  data.user = {...data.user, user};

  return (
    <Base className="nutrient-page">
      <h1>{nutrientName}</h1>
      <NutrientChart nutrient={data.user.nutrient} />
    </Base>
  )
}

export default NutrientPage;