import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/react-hooks";
import ClipLoader from "react-spinners/ClipLoader";
import moment from "moment";
import { USER } from "../queries";
import { UPDATE_EMAIL, UPDATE_PASSWORD } from "../mutations";
import WhiteBox from "../components/WhiteBox";
import NameFormModal from "../components/NameFormModal";
import { createErrorObject } from "../forms";

const CredentialsForm = props => {
  /**
   * A box which controls the core information about a user.
   */

  const { user } = props;
  const loading = Object.keys(user).length === 0;
  const day = moment(user.dateJoined).format("D MMMM YYYY");

  const [showModal, setShowModal] = useState(false);
  const [changedEmail, setChangedEmail] = useState(null);
  const [emailPassword, setEmailPassword] = useState("");
  const [emailErrors, setEmailErrors] = useState({
    email: "", password: "", general: ""
  });

  const [newPassword, setNewPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [passwordErrors, setPasswordErrors] = useState({
    new: "", current: "", general: ""
  });


  const [updateEmail, updateEmailMutation] = useMutation(UPDATE_EMAIL, {
    onError: ({graphQLErrors}) => {
      setEmailErrors(createErrorObject(emailErrors, graphQLErrors));
    },
    refetchQueries: [{query: USER}],
    onCompleted: () => {
      setEmailErrors({});
      setEmailPassword("");
    }
  });

  const [updatePassword, updatePasswordMutation] = useMutation(UPDATE_PASSWORD, {
    onError: ({graphQLErrors}) => {
      setPasswordErrors(createErrorObject(passwordErrors, graphQLErrors));
    },
    onCompleted: () => {
      setPasswordErrors({});
      setNewPassword("");
      setCurrentPassword("");
    }
  });

  const submitEmailForm = e => {
    e.preventDefault();
    if (changedEmail) {
      updateEmail({variables: {email: changedEmail, password: emailPassword}});
    }
  }

  const submitPasswordForm = e => {
    e.preventDefault();
    updatePassword({variables: {new: newPassword, current: currentPassword}});
  }

  if (loading) {
    return (
      <WhiteBox className="credentials-form">
        <ClipLoader css="display: block; margin: 0 auto;"/>
      </WhiteBox>
    )
  }

  return (
    <WhiteBox className="credentials-form">
      <div className="credentials-top">
        <div className="basic-info">
          <div className="name" onClick={() => setShowModal(true)}>
            { user.firstName } { user.lastName }
          </div>
          <NameFormModal showModal={showModal} setShowModal={setShowModal} user={user} />
          <div className="date-joined">Joined { day }</div>
        </div>
        <div className="account-status"><span>BASIC</span></div>
      </div>

      <div className="forms">
        <form className="email" onSubmit={submitEmailForm}>
          <h2>Email</h2>
          {emailErrors.general && <div className="general-error error">{emailErrors.general}</div>}
          {emailErrors.email && <div className="error">{emailErrors.email}</div>}
          <input
            type="email"
            value={changedEmail === null ? user.email : changedEmail}
            onChange={e => setChangedEmail(e.target.value)}
            autoComplete="email"
            required={true}
            disabled={updateEmailMutation.loading}
          />
          {emailErrors.password && <div className="error">{emailErrors.password}</div>}
          <input
            type="password"
            value={emailPassword}
            onChange={e => setEmailPassword(e.target.value)}
            autoComplete="current-password"
            placeholder="Current Password"
            required={true}
            disabled={updateEmailMutation.loading}
          />
          <input
            type="submit"
            value="Update Email"
            disabled={updateEmailMutation.loading}
          />
        </form>

        <form className="password" onSubmit={submitPasswordForm}>
          <h2>Password</h2>
          {passwordErrors.general && <div className="general-error error">{passwordErrors.general}</div>}
          <input type="text" name="username" autoComplete="username" style={{display: "none"}} />
          {passwordErrors.new && <div className="error">{passwordErrors.new}</div>}
          <input
            type="password"
            value={newPassword}
            onChange={e => setNewPassword(e.target.value)}
            autoComplete="new-password"
            placeholder="New Password"
            required={true}
            disabled={updatePasswordMutation.loading}
          />
          {passwordErrors.current && <div className="error">{passwordErrors.current}</div>}
          <input
            type="password"
            value={currentPassword}
            onChange={e => setCurrentPassword(e.target.value)}
            autoComplete="current-password"
            placeholder="Current Password"
            required={true}
            disabled={updatePasswordMutation.loading}
          />
          <input
            type="submit"
            value="Update Password"
            disabled={updatePasswordMutation.loading}
          />
        </form>  
      </div>
    </WhiteBox>
  )
}

CredentialsForm.propTypes = {
  user: PropTypes.object.isRequired
}

export default CredentialsForm;