import React, { useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { FOODS } from "../queries";
import Base from "../../core/components/Base";
import MealForm from "../components/MealForm";
import WhiteBox from "../../core/components/WhiteBox";

const NewMealPage = () => {
  /**
   * Page for creating new meals.
   */

  const { loading, data } = useQuery(FOODS);
  const foods = !data? [] : data.user.foods.edges.map(edge => edge.node);
  
  useEffect(() => {
    document.title = "New Meal - lytiko";
  });

  if (loading) {
    return (
      <Base className="new-meal-page">
        <WhiteBox loading={true} />
      </Base>
    )
  }

  return (
    <Base className="new-meal-page">
      <MealForm foods={foods} />
    </Base>
  )
}

export default NewMealPage;