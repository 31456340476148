import React, { useState, useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { TIME_CATEGORY, TIME_CATEGORY_SESSIONS } from "../queries";
import WhiteBox from "../../core/components/WhiteBox";
import Base from "../../core/components/Base";
import BaseNotFoundPage from "../../core/pages/BaseNotFoundPage";
import TimeCategory from "../components/TimeCategory";
import SessionForm from "../components/SessionForm";
import TimeCategoryChart from "../components/TimeCategoryChart";
import SessionsTable from "../components/SessionsTable";

const TimeCategoryPage = () => {
  /**
   * Information about a specific time category
   */

  const categoryId = useRouteMatch("/time/:id").params.id;
  const [tablePage, setTablePage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const {loading, data, error} = useQuery(TIME_CATEGORY, {variables: {id: categoryId}});
  const category = loading || error ? {} : data.user.timeCategory;

  const sessionsQuery = useQuery(TIME_CATEGORY_SESSIONS, {
    variables: {id: categoryId}, skip: loading || error
  });
  const categoryWithSessions = (
    loading || error || sessionsQuery.loading || sessionsQuery.error
  ) ? {} : sessionsQuery.data.user.timeCategory;

  useEffect(() => {
    if (!loading && !error) {
      document.title = `${category.name} - lytiko`;
    }
  });

  if (error) {
    try {
      const message = JSON.parse(error.graphQLErrors[0].message);
      if (message.quantity === "Does not exist") {
        return <BaseNotFoundPage />
      }
    } catch {  }
  }

  if (loading) {
    return (
      <Base className="time-category-page">
        <div className="time-category-page-top">
          <WhiteBox loading={true} />
          <WhiteBox loading={true} />
        </div>
        <WhiteBox className="lytiko-chart" loading={true} />
        <WhiteBox className="lytiko-chart" loading={true} />
      </Base>
    )
  }

  return (
    <Base className="time-category-page">
      <div className="time-category-page-top">
        <TimeCategory link={false} category={category} />
        <SessionForm category={category} />
      </div>
      <TimeCategoryChart category={category} />
      {sessionsQuery.loading ? <WhiteBox className="lytiko-chart" loading={true} /> : (
        <SessionsTable
          category={categoryWithSessions}
          tablePage={tablePage} setTablePage={setTablePage}
          rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage}
        />
      )}
    </Base>
  );
};

TimeCategoryPage.propTypes = {
    
};

export default TimeCategoryPage;