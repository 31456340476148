import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classNames from "classnames";
import SvgSeries from "../../core/components/SvgSeries";
import WhiteBox from "../../core/components/WhiteBox";
import { createSeries } from "../../core/charts";
import { addCurrency } from "../../core/numbers";

const AccountSummary = props => {
  /**
   * A box summarising the key information for a account.
   */

  const {account, showSummary} = props;

  let series = createSeries(account.series, {});
  series = series.data;

  const valueClass = classNames({
    "account-value": true, "hidden": account.latestValue === null,
    "no-series": !showSummary
  })
  
  return (
    <WhiteBox className="account-summary" element={Link} to={`/money/${account.id}/`}>
      <div className="account-name">{account.name}</div>
      <div className={valueClass}>
        {addCurrency(Number(account.latestBalance).toLocaleString(), account.currency)}
      </div>
      {showSummary && <SvgSeries series={series} lineWidth={1} step={true} dots={false}/>}
    </WhiteBox>
  )
}

AccountSummary.propTypes = {
  account: PropTypes.object.isRequired,
  showSummary: PropTypes.bool
}

export default AccountSummary;