import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/react-hooks";
import Toggle from "react-toggle";
import "react-toggle/style.css"
import { USER } from "../../core/queries";
import { UPDATE_QUANTITIES_SETTINGS } from "../mutations";
import WhiteBox from "../../core/components/WhiteBox";
import { createErrorObject } from "../../core/forms";

const QuantitiesSettingsForm = props => {
  /**
   * An interface for letting a user modify their quantity settings.
   */

  const { user } = props;
  const loading = Object.keys(user).length === 0;

  const [errors, setErrors] = useState({
    showQuantitySummaries: "", general: ""
  });

  const [updateQuantitiesSettings] = useMutation(UPDATE_QUANTITIES_SETTINGS, {    
    onError: ({graphQLErrors}) => {
      setErrors(createErrorObject(errors, graphQLErrors));
    },
    onCompleted: () => setErrors("")
  });

  if (loading) {
    return <WhiteBox loading={true} />
  }

  const quantitySummariesToggled = e => {
    const showSummaries = e.target.checked;
    updateQuantitiesSettings({
      variables: {
        showQuantitySummaries: showSummaries
      },
      optimisticResponse: {
        updateQuantitiesSettings: {
          __typename: "updateQuantitiesSettingsMutation",
          user: {
            __typename: "UserType", showQuantitySummaries: showSummaries
          }
        }
      },
      update: (proxy) => {
        let newData = proxy.readQuery({ query: USER});
        newData.user.showQuantitySummaries = showSummaries;
        proxy.writeQuery({ query: USER, data: newData});
      }
    })
  }

  return (
    <WhiteBox className="basic-settings-form">
      <h2>Quantities Settings</h2>

      {errors.general && <div className="general-error error">{errors.general}</div>}
      {errors.showQuantitySummaries && <div className="error">{errors.showQuantitySummaries}</div>}

      <div className="inputs">
        <div className="input toggle-input">
          <Toggle
              id="trendline"
              checked={user.showQuantitySummaries}
              onChange={quantitySummariesToggled}
          />
          <label htmlFor="trendline">Show Summary Trendlines</label>
        </div>
      </div>
    </WhiteBox>
  )
}

QuantitiesSettingsForm.propTypes = {
  user: PropTypes.object.isRequired
}

export default QuantitiesSettingsForm;