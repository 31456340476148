import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { useMutation } from "@apollo/react-hooks";
import { QUANTITIES, PINNED_QUANTITIES } from "../queries";
import { DELETE_QUANTITY } from "../mutations";
import alertIcon from "../../images/alert.svg";
import Modal from "../../core/components/Modal";

const DeleteQuantityModal = props => {
  /**
   * A full page interface asking if a quantity should be deleted.
   */

  const { showModal, setShowModal, quantity } = props;
  const history = useHistory();
  const [error, setError] = useState("");

  const [deleteQuantity, deleteQuantityMutaion] = useMutation(DELETE_QUANTITY, {
    onError: () => {
      setError("Sorry there was an error, quantity couldn't be deleted.");
    },
    refetchQueries: [{query: QUANTITIES}, {query: PINNED_QUANTITIES}],
    onCompleted: () => {
      history.push("/quantities/");
    }
  });

  const formSubmit = e => {
    e.preventDefault();
    deleteQuantity({variables: {id: quantity.id}});
  }

  const measurementCount = quantity.measurements && quantity.measurements.edges.length;

  return (
    <Modal setShowModal={setShowModal} showModal={showModal} className="delete-modal delete-quantity-modal">
      <form onSubmit={formSubmit}>
        <img src={alertIcon} className="icon" alt="alert" />
        {error && <div className="error">{error}</div>}
        <div className="proposal">Delete Quantity '{quantity.name}'</div>
        <div className="warning">This will permanently delete quantity and its {measurementCount} measurement{measurementCount !== 1 && "s"}</div>
        <input type="submit" value="Delete" disabled={deleteQuantityMutaion.loading} />
      </form>
    </Modal>
  )
}


DeleteQuantityModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  quantity: PropTypes.object.isRequired
}

export default DeleteQuantityModal;







