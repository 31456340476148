import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import Toggle from "react-toggle";
import classNames from "classnames";
import { UserContext } from "../../core/context";
import { createSeries, createChartConf, seriesToMovingAverage } from "../../core/charts";
import WhiteBox from "../../core/components/WhiteBox";
import LytikoChart from "../../core/components/LytikoChart";

const ComparisonChart = props => {
  /**
   * A chart comparing two series.
   */

  const {series1, series2, correlation} = props;
  const user = useContext(UserContext);

  const [shareAxis, setShareAxis] = useState(false);
  const canShareAxis = series1.units === series2.units;
  const shouldShareAxis = shareAxis && canShareAxis;

  // Create series
  const series1Conf = createSeries(series1, {
    yAxis: 0, className: "first-series", dayEnds: user.dayEnds
  });
  const series2Conf = createSeries(series2, {
    yAxis: shouldShareAxis ? 0 : 1, className: "second-series", dayEnds: user.dayEnds
  });

  let start = 0; let end = 0;
  if (series1Conf.data.length && series2Conf.data.length) {
    start = Math.max(series1Conf.data[0][0], series2Conf.data[0][0]);
    end = Math.min(
      series1Conf.data[series1Conf.data.length - 1][0],
      series2Conf.data[series2Conf.data.length - 1][0]
    );
  }
  if (end < start) {
    start = Math.min(series1Conf.data[0][0], series2Conf.data[0][0]);
    end = Math.max(
      series1Conf.data[series1Conf.data.length - 1][0],
      series2Conf.data[series2Conf.data.length - 1][0]
    );
  }
  const startEndSpan = end - start;
  const options = createChartConf(user.timezone || moment.tz.guess());
  options.series = [series1Conf, series2Conf];
  options.xAxis.min = start - (startEndSpan * 0.01);
  options.xAxis.max = end + (startEndSpan * 0.01);

  options.title.text = correlation;
  if (series1.data.length > 5 && series1.seriesType !== 2 && (series1.seriesType !== 3)) {
    options.series.push(seriesToMovingAverage(series1Conf));
    options.series[options.series.length - 1].className = "trendline-1";
  }
  if (series2.data.length > 5 && series2.seriesType !== 2 && (series2.seriesType !== 3)) {
    options.series.push(seriesToMovingAverage(series2Conf));
    options.series[options.series.length - 1].className = "trendline-2";
  }
  
  let title1 = series1.name;
  if (series1.units) title1 += ` (${series1.units})`;
  let title2 = series2.name;
  if (series2.units) title2 += ` (${series2.units})`;
  options.yAxis = [
    {title: {text: title1}, className: "y-axis-1"},
    {title: {text: title2}, opposite: !shouldShareAxis, className: "y-axis-2"}
  ]

  const className = classNames({
    "comparison-chart": true, "shared-axis": shouldShareAxis
  })
  
  return (
    <WhiteBox className={className}>
      <LytikoChart options={options} />
      {canShareAxis && <div className="chart-controls">
        <div className="toggle-input">
          <Toggle
            id="share-axis"
            checked={shareAxis}
            onChange={() => setShareAxis(!shareAxis)}
          />
          <label htmlFor="trendline">Share Axis</label>
        </div>
      </div>}
    </WhiteBox>
  )
}

ComparisonChart.propTypes = {
  series1: PropTypes.object.isRequired,
  series2: PropTypes.object.isRequired,
}

export default ComparisonChart;