import React, { useEffect } from "react";
import { useLocation } from "react-router";
import PropTypes from "prop-types";
import classNames from "classnames";
import Loader from "./Loader";
import Div100vh from "react-div-100vh";
import Nav from "./Nav";
import Sidebar from "./Sidebar";

const Base = props => {
  /**
   * Provides the basic logged in layout.
   */

  const { loading } = props;

  const location = useLocation();
  const section = location.pathname.split("/")[1];
  const baseClass = classNames({
    "base": true,
    "quantities-base": section === "quantities",
    "money-base": section === "money",
    "time-base": section === "time",
    "food-base": section === "food",
  });

  useEffect(() => {
    document.querySelector("html").style.overflow = "hidden";
    document.querySelector("body").style.overflow = "hidden";
  });


  if (loading) {
    return (
      <Div100vh className={baseClass}>
        <nav />
        <Sidebar />
        <main><Loader /></main>
      </Div100vh>
    )
  }

  return (
    <Div100vh className={baseClass}>
      <Nav />
      <Sidebar />
      <main className={props.className}>
        {props.children}
      </main>
    </Div100vh>
  )
}

Base.propTypes = {
  children: PropTypes.node
}

export default Base;