import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { useMutation } from "@apollo/react-hooks";
import Toggle from "react-toggle";
import classNames from "classnames";
import { PINNED_TIME_CATEGORIES, TIME_CATEGORY } from "../queries";
import { CREATE_TIME_CATEGORY, UPDATE_TIME_CATEGORY } from "../mutations";
import WhiteBox from "../../core/components/WhiteBox";
import ColorPicker from "../../core/components/ColorPicker";
import { createErrorObject } from "../../core/forms";

const TimeCategoryForm = props => {
  /**
   * An interface for creating or editing a time category.
   */

  const category = props.category;

  const colors = [
    "#ff9ff3", "#feca57", "#ff6b6b", "#48dbfb", "#1dd1a1",
    "#f368e0", "#ff9f43", "#ee5253", "#0abde3", "#10ac84",
    "#00d2d3", "#54a0ff", "#5f27cd", "#576574"
  ];

  const history = useHistory();
  const [name, setName] = useState(category ? category.name : "");
  const [description, setDescription] = useState(category ? category.description : "");
  const [color, setColor] = useState(category ? category.color : colors[Math.floor(Math.random() * colors.length)]);
  const [pinned, setPinned] = useState(category ? category.pinned : true);
  const [errors, setErrors] = useState({
    name: "", units: "", description: "", color: "", pinned: "", general: ""
  });

  const [createTimeCategory, createTimeCategoryMutation] = useMutation(CREATE_TIME_CATEGORY, {
    onError: ({graphQLErrors}) => {
      setErrors(createErrorObject(errors, graphQLErrors));
    },
    refetchQueries: [{query: PINNED_TIME_CATEGORIES}],
    onCompleted: ({createTimeCategory: {timeCategory: {id}}}) => {
      history.push(`/time/${id}/`);
    }
  });

  const [updateTimeCategory, updateTimeCategoryMutation] = useMutation(UPDATE_TIME_CATEGORY, {
    onError: ({graphQLErrors}) => {
      setErrors(createErrorObject(errors, graphQLErrors));
    },
    refetchQueries: [
      {query: TIME_CATEGORY, variables: {id: category && category.id}}
    ],
    onCompleted: ({updateTimeCategory: {timeCategory: {id}}}) => {
      history.push(`/time/${id}/`);
    }
  });

  const formSubmit = e => {
    e.preventDefault();
    if (category) {
      updateTimeCategory({
        variables: {id: category.id, name, color, description, pinned}
      });
    } else {
      createTimeCategory({
        variables: {name, color, description, pinned}
      });
    }
  }

  const className = classNames({
    "time-category-form": true, "model-form": true
  })

  return (
    <WhiteBox className={className}>
      {category ? <h1>Edit Time Category</h1> : <h1>Create New Time Category</h1>}

      <form onSubmit={formSubmit}>
        {errors.general && <div className="general-error error">{errors.general}</div>}
        <div className="top-row">
          <div className="input">
            <label htmlFor="name">Category Name</label>
            {errors.name && <div className="error">{errors.name}</div>}
            <input
              id="name"
              value={name}
              onChange={e => setName(e.target.value)}
              disabled={createTimeCategoryMutation.loading || updateTimeCategoryMutation.loading}
              required
              placeholder="e.g Work, Sleep, Fun etc."
            />
          </div>
          <div className="input">
            <label htmlFor="description">Description</label>
            {errors.description && <div className="error">{errors.description}</div>}
            <input
                id="description"
                value={description}
                onChange={e => setDescription(e.target.value)}
                disabled={createTimeCategoryMutation.loading || updateTimeCategoryMutation.loading}
            />
            </div>
        </div>

        <div className="bottom-row">
          <div className="input">
            <label>Color</label>
            <ColorPicker color={color} colors={colors} setColor={setColor} />
          </div>

          <div className="input toggle-input has-advanced-toggle">
            <Toggle
                id="pinned"
                checked={pinned}
                onChange={() => setPinned(!pinned)}
            />
            <label htmlFor="pinned">Pinned Category</label>
          </div>      


        </div>
        
        


        

    

        <input
          type="submit"
          value="Save" 
          //disabled={createTimeCategoryMutation.loading || updateTimeCategoryMutation.loading}
        />
      </form>

    </WhiteBox>
  )
}

TimeCategoryForm.propTypes = {
  category: PropTypes.object,
}

export default TimeCategoryForm;