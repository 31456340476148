import React, { useContext, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/react-hooks";
import moment from "moment-timezone";
import Select from "react-select";
import { TIME_CATEGORY, TIME_CATEGORY_SESSIONS, TIME_CATEGORIES_FOR_DAY } from "../queries";
import { CREATE_SESSION } from "../mutations";
import Modal from "../../core/components/Modal";
import { UserContext } from "../../core/context";
import { createErrorObject } from "../../core/forms";

const QuickAddSessionModal = props => {
  /**
   * A full page interface for quickly adding some session.
   */

  const { showModal, setShowModal, categories, date } = props;
  const [error, setError] = useState("");
  const [startHour, setStartHour] = useState("");
  const [startMinute, setStartMinute] = useState("");
  const [endHour, setEndHour] = useState("");
  const [endMinute, setEndMinute] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState(null);

  const categoryOptions = categories.map(category => ({
    value: category.id, label: category.name
  }));
  const selectedCategory = !categories.length ? null : category ? (
    categoryOptions.filter(option => option.value === category)[0]
  ) : categoryOptions[0];
  const actuallySelectedCategory = selectedCategory && selectedCategory.value;
  const categoryChanged = option => setCategory(option.value);

  const [createSession, createSessionMutation] = useMutation(CREATE_SESSION, {
    onError: ({graphQLErrors}) => {
      setError(createErrorObject(error, graphQLErrors));
    },
    refetchQueries: [
      {query: TIME_CATEGORY, variables: {id: actuallySelectedCategory}},
      {query: TIME_CATEGORIES_FOR_DAY, variables: {date}},
      {query: TIME_CATEGORY_SESSIONS, variables: {id: actuallySelectedCategory}},
    ],
    onCompleted: () => {
      setStartHour("");
      setStartMinute("");
      setEndHour("");
      setEndMinute("");
      setDescription("");
      setShowModal(false);
    }
  });

  const user = useContext(UserContext);
  const timezone = user.timezone || moment.tz.guess();
  
  const startNow = () => {
    const now = moment().tz(timezone);
    setStartHour(now.format("HH"));
    setStartMinute(now.format("mm"));
  }

  const endNow = () => {
    const now = moment().tz(timezone);
    setEndHour(now.format("HH"));
    setEndMinute(now.format("mm"));
  }

  const startMinuteElement = useRef(null);
  const endHourElement = useRef(null);
  const endMinuteElement = useRef(null);
  const descriptionElement = useRef(null);

  const startHourEdited = e => {
    const started2 = startHour.length === 2 || (
      startHour === "9" && e.target.value === "10"
    );
    setStartHour(e.target.value);
    if (e.target.value.length === 2 && !started2) {
      setTimeout(() => startMinuteElement.current.select(), 0);
    }
  }

  const startMinuteEdited = e => {
    const started2 = startMinute.length === 2 || (
      startMinute === "9" && e.target.value === "10"
    );
    setStartMinute(e.target.value);
    if (e.target.value.length === 2 && !started2) {
      setTimeout(() => endHourElement.current.select(), 0);
    }
  }

  const endHourEdited = e => {
    const started2 = endHour.length === 2 || (
      endHour === "9" && e.target.value === "10"
    );
    setEndHour(e.target.value);
    if (e.target.value.length === 2 && !started2) {
      setTimeout(() => endMinuteElement.current.select(), 0);
    }
  }

  const endMinuteEdited = e => {
    const started2 = endMinute.length === 2 || (
      endMinute === "9" && e.target.value === "10"
    );
    setEndMinute(e.target.value);
    if (e.target.value.length === 2 && !started2) {
      setTimeout(() => descriptionElement.current.select(), 0);
    }
  }

  const formSubmit = e => {
    e.preventDefault();
    const startHourInt = parseInt(startHour);
    const startMinuteInt = parseInt(startMinute);
    const endHourInt = parseInt(endHour);
    const endMinuteInt = parseInt(endMinute);
    const yearInt = parseInt(date.slice(0, 4));
    const monthInt = parseInt(date.slice(5, 7));
    const dayInt = parseInt(date.slice(8, 10));
    
    const startDt = moment({
      y: yearInt, M: monthInt - 1, d: dayInt, h: startHourInt, m: startMinuteInt
    });
    if (parseInt(startDt.format("HH")) < user.dayEnds) startDt.add(1, "day");
    const endDt = moment({
      y: yearInt, M: monthInt - 1, d: dayInt, h: endHourInt, m: endMinuteInt
    });
    while (endDt <= startDt) {
      endDt.add(1, "day");
    }

    const startTs = startDt.tz(timezone, true).valueOf() / 1000;
    const endTs = endDt.tz(timezone, true).valueOf() / 1000;
    const duration = (endTs - startTs) / 60;

    createSession({
      variables: {
        duration: duration,
        description,
        start: startTs,
        timezone,
        category: actuallySelectedCategory
      }
    });
  }

  return (
    <Modal setShowModal={setShowModal} showModal={showModal} className="quick-add-session-modal">
      <form onSubmit={formSubmit}>

        <div className="times">
          <div className="time">
            <button className="now" onClick={startNow} type="button" tabIndex="-1" />
            <div className="inputs">
              <input
                type="number"
                value={startHour}
                onChange={startHourEdited}
                min="0"
                max="23"
                disabled={createSessionMutation.loading}
                required
              />
              <input
                type="number"
                value={startMinute}
                onChange={startMinuteEdited}
                min="0"
                max="59"
                disabled={createSessionMutation.loading}
                ref={startMinuteElement}
                required
              />
            </div>
            
          </div>
          <div className="to">to</div>
          <div className="time">
            <button className="now" onClick={endNow} type="button" tabIndex="-1" />
            <div className="inputs">
              <input
                type="number"
                value={endHour}
                onChange={endHourEdited}
                max="23"
                disabled={createSessionMutation.loading}
                ref={endHourElement}
                required
              />
              <input
                type="number"
                value={endMinute}
                onChange={endMinuteEdited}
                max="59"
                disabled={createSessionMutation.loading}
                ref={endMinuteElement}
                required
              />
            </div>
            
          </div>
        </div>
        <input
          value={description}
          onChange={e => setDescription(e.target.value)}
          disabled={createSessionMutation.loading}
          placeholder="Description"
          ref={descriptionElement}
        />
        <Select
          options={categoryOptions}
          value={selectedCategory}
          onChange={categoryChanged}
          disabled={createSessionMutation.loading}
          className="react-select"
          classNamePrefix="react-select"
        />
        <input type="submit" value="Save" />
      </form>
    </Modal>
  )
}

QuickAddSessionModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  date: PropTypes.string.isRequired
}

export default QuickAddSessionModal;







