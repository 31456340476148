import React from "react";
import { Link, useLocation } from "react-router-dom";
import homeIcon from "../../images/home-icon.svg";
import quantitiesIcon from "../../images/quantities-icon.svg";
import quantitiesIconActive from "../../images/quantities-icon-active.svg";
import foodIcon from "../../images/food-icon.svg";
import foodIconActive from "../../images/food-icon-active.svg";
import timeIcon from "../../images/time-icon.svg";
import timeIconActive from "../../images/time-icon-active.svg";
import moneyIcon from "../../images/money-icon.svg";
import moneyIconActive from "../../images/money-icon-active.svg";
import mealIcon from "../../images/meal-icon.svg";
import plusIcon from "../../images/plus.svg";

const Sidebar = () => {
  /**
   * The navigational sidebar at the left of every logged in page.
   */

  const location = useLocation();
  const section = location.pathname.split("/")[1];

  return (
    <div className="sidebar">
      <Link to="/" className="logo"><img src={homeIcon} alt="home" /><span>lytiko</span></Link>
      <div className="sections">
        <Link to="/quantities/">
          <img src={section === "quantities" ? quantitiesIconActive : quantitiesIcon} alt="quantities" /><span>Quantities</span>
        </Link>
        <Link to="/food/">
          <img src={section === "food" ? foodIconActive : foodIcon} alt="food" /><span>Food</span>
        </Link>
        <Link to="/time/">
          <img src={section === "time" ? timeIconActive : timeIcon} alt="time" /><span>Time</span>
        </Link>
        <Link to="/money/">
          <img src={section === "money" ? moneyIconActive : moneyIcon} alt="money" /><span>Money</span>
        </Link>
        {section === "quantities" && (<Link to="/quantities/new/" className="tool">
          <img src={plusIcon} alt="new" /><span>New Quantity</span>
        </Link>)}
        {section === "food" && (<>
          <Link to="/food/new/" className="tool">
            <img src={plusIcon} alt="new" />
            <img src={foodIconActive} alt="" className="specifier"/>
            <span>New Food</span>
          </Link>
          <Link to="/food/meals/new/" className="tool secondary-tool">
            <img src={plusIcon} alt="new" />
            <img src={mealIcon} alt="" className="specifier"/>
            <span>New Meal</span>
          </Link>
        </>)}
        {section === "time" && (<Link to="/time/new/" className="tool">
          <img src={plusIcon} alt="new" /><span>New Category</span>
        </Link>)}
        {section === "money" && (<Link to="/money/new/" className="tool">
          <img src={plusIcon} alt="new" /><span>New Account</span>
        </Link>)}

      </div>
    </div>
  )
}

export default Sidebar;