import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classNames from "classnames";
import downArrow from "../../images/down-arrow.svg";

const Dropdown = props => {
  /**
   * A dropdown appearing in the top-right corner of something, togglable with a
   * click of the arrow button. It can optionally let its parent know when it is
   * displaying the dropdown if you provide a setShowingDropdown prop.
   */

  const { links, setShowingDropdown } = props;

  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownElement = useRef(null);

  const arrowClicked = e => {
    e.stopPropagation();
    setShowDropdown(!showDropdown);
    if (setShowingDropdown) setShowingDropdown(!showDropdown);
  }

  const clickOutside = e => {
    if (dropdownElement.current && !dropdownElement.current.contains(e.target)) {
      setShowDropdown(false);
      if (setShowingDropdown) setShowingDropdown(false);
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickOutside)
  });

  const className = classNames({"dropdown-container": true, visible: showDropdown})

  return (
    <div className={className} ref={dropdownElement}>
      <img src={downArrow} className="arrow" alt="options" onClick={arrowClicked} />
      <div autoFocus className="white-box dropdown">
        {links.map((link, index) => {
          if (link.href) {
            return (
              <Link className="option" to={link.href} key={index}>
                <img src={link.icon} alt={link.text.toLowerCase()} />{link.text}
              </Link>
            )
          } else {
            return (
              <div className="option" onClick={link.action} key={index}>
                <img src={link.icon} alt={link.text.toLowerCase()} />{link.text}
              </div>
            )
          }
        })}
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  links: PropTypes.array.isRequired,
  setShowingDropdown: PropTypes.func
};

export default Dropdown;