import React, { useEffect, useContext } from "react";
import { useQuery } from "@apollo/react-hooks";
import Loader from "../../core/components/Loader";
import { ACCOUNTS } from "../queries";
import leftArrow from "../../images/left-arrow.svg";
import Base from "../../core/components/Base";
import AccountSummary from "../components/AccountSummary";
import { UserContext } from "../../core/context";

const MoneyPage = () => {
  /**
   * All a user's accounts on one page. If there are no accounts, it will
   * indicate how to create one.
   */

  const user = useContext(UserContext);
  const { loading, data } = useQuery(ACCOUNTS, {
    variables: {last: user.showAccountSummaries ? 150 : 1}
  });
  const accounts = loading ? [] : data.user.accounts.edges.map(edge => edge.node);
  
  useEffect(() => {
    document.title = "Money - lytiko";
  });

  if (loading) {
    return (
      <Base className="accounts-page">
        <Loader />
      </Base>
    )
  }

  if (accounts.length === 0) {
    return (
      <Base className="accounts-page">
        <div className="no-accounts">
          <div>You are currently not tracking any accounts</div>
          <div className="pointer"><img src={leftArrow} alt="" />Start tracking now</div>
        </div>
      </Base>
    )
  }

  return (
    <Base className="accounts-page">
      <div className="accounts-grid">
        {accounts.map(account => {
          return (
            <AccountSummary
              account={account}
              key={account.id}
              showSummary={user.showAccountSummaries}
            />
          )
        })}
      </div>
    </Base>
  )
}

export default MoneyPage;