import React, { useContext } from "react";
import moment from "moment-timezone";
import WhiteBox from "../../core/components/WhiteBox";
import { UserContext } from "../../core/context";

const Timelines = props => {

  const { categories, selectedSession, setSelectedSession } = props;
  const user = useContext(UserContext);
  const timezone = user.timezone || moment.tz.guess();

  // We just want categories with sessions
  const categoriesWithSessions = categories.filter(
    category => category.sessions.edges.length
  );
  const categoryCount = categoriesWithSessions.length;

  if (categoryCount === 0) {
    return (
      <div className="timelines">
        
      </div>
    )
  }

  // Get a list of all sessions sorted by start time
  const allSessions = categoriesWithSessions.reduce(
    (prev, curr) => [...prev, ...curr.sessions.edges.map(edge => edge.node)], []
  ).sort((a, b) => a.start - b.start);

  // Constants
  const timelineHeight = 40;
  const sessionPadding = 2;
  const timelinePadding = 10;
  const timelineSpacing = 0;
  const secondsPerPixel = 30;

  // Height values
  const timelineTotalHeight = timelineHeight + (2 * timelinePadding);
  const svgHeight = (categoryCount * timelineTotalHeight) + (
    (categoryCount - 1) * timelineSpacing
  );

  // Width values
  const firstTime = allSessions[0].start;
  const lastTime = allSessions.map(session => session.start + session.duration * 60).sort().reverse()[0];
  const startTime = firstTime - (firstTime % 3600);
  const endTime = lastTime - (lastTime % 3600) + (lastTime % 3600 ? 3600 : 0);
  const secondsSpan = endTime - startTime;
  const svgWidth = secondsSpan / secondsPerPixel;

  // Hours
  const hourCount = (endTime - startTime) / 3600;
  const hours = [...Array(hourCount - 1).keys()].map(hour => startTime + ((hour + 1) * 3600));
  const hourWidth = svgWidth / hourCount;

  

  
  
  return (
    <WhiteBox className="timelines">
      <svg height={svgHeight} width={svgWidth + 12}>
        {categoriesWithSessions.map((category, index) => {
          const sessions = category.sessions.edges.map(edge => edge.node);
          const y = (index * (timelineTotalHeight + timelineSpacing)) + timelinePadding;
          return (
            <React.Fragment key={index}>
              <rect className="timeline"  x={0} y = {y} width={svgWidth} height={timelineHeight} />
              {sessions.map((session, index) => {
                const x = ((session.start - startTime) / secondsSpan) * secondsSpan / secondsPerPixel;
                const width = (session.duration * 60 / secondsSpan) * secondsSpan / secondsPerPixel;
                return (
                  <rect
                    key={index}
                    className="session"
                    onClick={() => setSelectedSession(session.id)}
                    x={x}
                    y={y + sessionPadding}
                    rx="2px"
                    width={width}
                    height={timelineHeight - (2 * sessionPadding)}
                    fill={!selectedSession || selectedSession === session.id ? category.color : category.color + "80"}
                  />
                )
              })}
            </React.Fragment>
          )
        })}
        {hours.map((hour, index) => {
          const x = (index + 1) * hourWidth;
          const hourString = moment(hour * 1000).tz(timezone).format("H");
          return (
            <React.Fragment key={index}>
              <text x={x} y={timelinePadding - 2} textAnchor="middle">{hourString}</text>
              <line x1={x} x2={x} y1={timelinePadding} y2={svgHeight - timelinePadding} />
              <text x={x} y={svgHeight - timelinePadding + 9} textAnchor="middle">{hourString}</text>
            </React.Fragment>
          )
        })}
      </svg>
    </WhiteBox>
  );
};

Timelines.propTypes = {
  
};

export default Timelines;