import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import moment from "moment";
import { useMutation } from "@apollo/react-hooks";
import { DELETE_MEAL } from "../mutations";
import alertIcon from "../../images/alert.svg";
import Modal from "../../core/components/Modal";
import { UserContext } from "../../core/context";
import { dtToUtcDt } from "../../core/time";
import { FOOD_DAY_WITH_MEALS } from "../queries";

const DeleteMealModal = props => {
  /**
   * A full page interface asking if a meal should be deleted.
   */

  const { showModal, setShowModal, meal } = props;
  const history = useHistory();
  const [error, setError] = useState("");
  const user = useContext(UserContext);
  const date = dtToUtcDt(meal.datetime, meal.timezone, user.dayEnds);

  const [deleteMeal, deleteMealMutaion] = useMutation(DELETE_MEAL, {
    onError: () => {
      setError("Sorry there was an error, meal couldn't be deleted.");
    },
    refetchQueries: [{
      query: FOOD_DAY_WITH_MEALS,
      variables: {date: moment.utc(date).format("YYYY-MM-DD")}
    }],
    awaitRefetchQueries: true,
    onCompleted: () => {
      history.push("/food?date=" + moment.utc(date).format("YYYY-MM-DD"));
      setShowModal(false);
    }
  });

  const formSubmit = e => {
    e.preventDefault();
    deleteMeal({variables: {id: meal.id}});
  }

  return (
    <Modal setShowModal={setShowModal} showModal={showModal} className="delete-modal delete-meal-modal">
      <form onSubmit={formSubmit}>
        <img src={alertIcon} className="icon" alt="alert" />
        {error && <div className="error">{error}</div>}
        <div className="proposal">Delete Meal</div>
        <div className="warning">This will permanently delete the meal.</div>
        <input type="submit" value="Delete" disabled={deleteMealMutaion.loading} />
      </form>
    </Modal>
  )
}


DeleteMealModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  meal: PropTypes.object.isRequired
}

export default DeleteMealModal;