import gql from "graphql-tag";

export const CREATE_TIME_CATEGORY = gql`mutation(
  $name: String!, $color: String!, $description: String, $pinned: Boolean
) {
  createTimeCategory(name: $name, color: $color, description: $description, pinned: $pinned) {
    timeCategory { id name description color }
  }
}`;

export const UPDATE_TIME_CATEGORY = gql`mutation(
  $id: ID!, $name: String!, $color: String!, $description: String, $pinned: Boolean
) {
  updateTimeCategory(id: $id, name: $name, color: $color, description: $description, pinned: $pinned) {
    timeCategory { id name description color }
  }
}`;

export const DELETE_TIME_CATEGORY = gql`mutation($id: ID!) {
  deleteTimeCategory(id: $id) {success}
}`;

export const CREATE_SESSION = gql`mutation(
  $start: Int!, $duration: Int!, $description: String, $timezone: String!, $category: ID!
) {
  createSession(
    start: $start, duration: $duration, timezone: $timezone
    description: $description, category: $category
  ) { session { category { id } } }
}`;


export const UPDATE_SESSION = gql`mutation(
  $id: ID!, $start: Int!, $duration: Int!, $description: String, $timezone: String!
) {
  updateSession(
    start: $start, duration: $duration, timezone: $timezone
    description: $description, id: $id
  ) { session { category { id } } }
}`;

export const DELETE_SESSION = gql`mutation($id: ID!) {
  deleteSession(id: $id) {success}
}`;

export const UPDATE_TIME_SETTINGS = gql`mutation($showEmptyTimeCategories: Boolean!) {
  updateTimeSettings(showEmptyTimeCategories: $showEmptyTimeCategories) {
    user { showEmptyTimeCategories }
  }
}`;