import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/react-hooks";
import Toggle from "react-toggle";
import "react-toggle/style.css"
import { USER } from "../../core/queries";
import { UPDATE_TIME_SETTINGS } from "../mutations";
import WhiteBox from "../../core/components/WhiteBox";
import { createErrorObject } from "../../core/forms";

const TimeSettingsForm = props => {
  /**
   * An interface for letting a user modify their time settings.
   */

  const { user } = props;
  const loading = Object.keys(user).length === 0;

  const [errors, setErrors] = useState({
    showEmptyTimeCategories: "", general: ""
  });

  const [updateTimeSettings] = useMutation(UPDATE_TIME_SETTINGS, {    
    onError: ({graphQLErrors}) => {
      setErrors(createErrorObject(errors, graphQLErrors));
    },
    onCompleted: () => setErrors("")
  });

  if (loading) {
    return <WhiteBox loading={true} />
  }

  const emptyCategoriesToggled = e => {
    const showEmptyTimeCategories = e.target.checked;
    updateTimeSettings({
      variables: {
        showEmptyTimeCategories: showEmptyTimeCategories
      },
      optimisticResponse: {
        updateTimeSettings: {
          __typename: "updateTimeSettingsMutation",
          user: {
            __typename: "UserType", showEmptyTimeCategories: showEmptyTimeCategories
          }
        }
      },
      update: (proxy) => {
        let newData = proxy.readQuery({ query: USER});
        newData.user.showEmptyTimeCategories = showEmptyTimeCategories;
        proxy.writeQuery({ query: USER, data: newData});
      }
    })
  }

  return (
    <WhiteBox className="time-settings-form">
      <h2>Time Settings</h2>

      {errors.general && <div className="general-error error">{errors.general}</div>}
      {errors.showEmptyTimeCategories && <div className="error">{errors.showEmptyTimeCategories}</div>}

      <div className="inputs">
        <div className="input toggle-input">
          <Toggle
              id="noSessions"
              checked={user.showEmptyTimeCategories}
              onChange={emptyCategoriesToggled}
          />
          <label htmlFor="noSessions">Show Categories with no Sessions</label>
        </div>
      </div>
    </WhiteBox>
  )
}

TimeSettingsForm.propTypes = {
  user: PropTypes.object.isRequired
}

export default TimeSettingsForm;