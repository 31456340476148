import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import WhiteBox from "../../core/components/WhiteBox";
import TablePaginator from "../../core/components/TablePaginator";
import TransactionRow from "./TransactionRow";

const TransactionsTable = props => {
  /**
   * A list of transactions.
   */

  const {account, tablePage, setTablePage, rowsPerPage, setRowsPerPage, refetch} = props;
  const transactions = account.transactions.edges.map(edge => edge.node);

  const [showingDropdown, setShowingDropdown] = useState(false);
  const className = classNames({
    "table-container": true, "showing-dropdown": showingDropdown
  })
  
  return (
    <WhiteBox className="transactions-table">
      {account.transactionCount > rowsPerPage && <TablePaginator
        rowCount={account.transactionCount}
        tablePage={tablePage} setTablePage={setTablePage}
        rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage}
      />}
      <div className={className}>
        <div className="table">
          {transactions.map(transaction => {
            return (
              <TransactionRow
                transaction={transaction}
                account={account}
                key={transaction.id}
                setShowingDropdown={setShowingDropdown}
                refetch={refetch}
              />
            )
          })}
        </div>
      </div>
      {transactions.length > rowsPerPage && <TablePaginator
        rowCount={transactions.length}
        tablePage={tablePage} setTablePage={setTablePage}
        rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage}
      />}
    </WhiteBox>
  )
}

TransactionsTable.propTypes = {
  account: PropTypes.object.isRequired,
  tablePage: PropTypes.number.isRequired,
  setTablePage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
}

export default TransactionsTable;