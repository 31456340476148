import gql from "graphql-tag";

export const CREATE_ACCOUNT = gql`mutation(
  $name: String!, $currency: String!, $description: String, $startBalance: Float!,
  $pinned: Boolean!, $hasTimes: Boolean! $startDate: String!,
) {
  createAccount(
    name: $name, currency: $currency, description: $description, pinned: $pinned
    hasTimes: $hasTimes, startDate: $startDate, startBalance: $startBalance
  ) {
    account { id name currency description pinned hasTimes startDate startBalance }
  }
}`;

export const UPDATE_ACCOUNT = gql`mutation(
  $id: ID!, $name: String!, $currency: String!, $description: String,
  $startBalance: Float!, $pinned: Boolean!, $startDate: String!
) {
  updateAccount(
    id: $id, name: $name, currency: $currency, description: $description,
    pinned: $pinned, startDate: $startDate, startBalance: $startBalance
  ) {
    account { id name currency description pinned hasTimes startDate startBalance }
  }
}`;

export const DELETE_ACCOUNT = gql`mutation($id: ID!) {
  deleteAccount(id: $id) { success } 
}`;

export const CREATE_TRANSACTION = gql`mutation(
  $account: ID!, $amount: Float!, $timezone: String!, $datetime: Int!, $description: String,
) {
  createTransaction(
    account: $account, amount: $amount, datetime: $datetime,
    timezone: $timezone, description: $description
  ) { transaction { id } }
}`;

export const UPDATE_TRANSACTION = gql`mutation(
  $id: ID!, $amount: Float!, $timezone: String!, $datetime: Int!, $description: String,
) {
  updateTransaction(
    id: $id, amount: $amount, datetime: $datetime,
    timezone: $timezone, description: $description
  ) {
    transaction { id account { id } }
  }
}`;

export const DELETE_TRANSACTION = gql`mutation($id: ID!) {
  deleteTransaction(id: $id) {success}
}`;

export const UPDATE_MONEY_SETTINGS = gql`mutation($showAccountSummaries: Boolean!) {
  updateMoneySettings(showAccountSummaries: $showAccountSummaries) {
    user { showAccountSummaries }
  }
}`;