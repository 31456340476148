import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { UserContext } from "../../core/context"
import DeleteTransactionModal from "./DeleteTransactionModal";
import Dropdown from "../../core/components/Dropdown";
import editIcon from "../../images/edit.svg";
import deleteIcon from "../../images/delete.svg";
import { timeString, dayString } from "../../core/time";
import classNames from "classnames";
import { addCurrency } from "../../core/numbers";

const TransactionRow = props => {
  /**
   * A row representing a transaction.
   */

  const {transaction, account, setShowingDropdown, refetch} = props;

  const [showModal, setShowModal] = useState(false);

  const user = useContext(UserContext);

  const deleteClicked = () => {
    setShowModal(true);
  }

  const links = [
    {href: `/money/transactions/${transaction.id}/edit/`, icon: editIcon, text: "Edit"},
    {action: deleteClicked, icon: deleteIcon, text: "Delete"}
  ]

  const amountClassName = classNames({
    cell: true, amount: true,
    positive: transaction.amount > 0, negative: transaction.amount < 0
  })

  return (
    <div className="transaction-row" >
      <div className={amountClassName}>
        {addCurrency(Number(transaction.amount).toLocaleString(), account.currency)}
      </div>
      <div className="cell">{!account.hasTimes ? dayString(transaction.datetime * 1000, "D") : timeString(transaction.datetime * 1000, transaction.timezone, user.timezone)}</div>
      <div className="cell description">{transaction.description}</div>
      <div className="cell balance">
        {addCurrency(Number(transaction.postBalance).toLocaleString(), account.currency)}
      </div>
      <div className="cell">
        <Dropdown links={links} setShowingDropdown={setShowingDropdown} />
      </div>
      <DeleteTransactionModal
        account={account}
        transaction={transaction}
        showModal={showModal}
        setShowModal={setShowModal}
        refetch={refetch}
      />
    </div>
  );
}

TransactionRow.propTypes = {
  transaction: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  setShowingDropdown: PropTypes.func.isRequired
}

export default TransactionRow;