import React, { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import { QUANTITY } from "../queries";
import Base from "../../core/components/Base";
import BaseNotFoundPage from "../../core/pages/BaseNotFoundPage";
import WhiteBox from "../../core/components/WhiteBox";
import QuantityForm from "../components/QuantityForm";

const EditQuantityPage = () => {
  /**
   * Page for editing a quantity
   */

  const quantityId = useRouteMatch("/quantities/:id").params.id;

  const {loading, data, error} = useQuery(QUANTITY, {variables: {id: quantityId}});
  const quantity = loading || error ? {measurements: {edges: []}} : data.user.quantity;

  
  useEffect(() => {
    if (!loading) {
      document.title = `${quantity.name} - lytiko`;
    }
  });

  if (error) {
    try {
      const message = JSON.parse(error.graphQLErrors[0].message);
      if (message.quantity === "Does not exist") {
        return <BaseNotFoundPage />
      }
    } catch {  }
  }

  if (loading) {
    return (
      <Base className="edit-quantity-page">
        <WhiteBox className="quantity-form" loading={true} />
      </Base>
    )
  }

  return (
    <Base className="edit-quantity-page">
      <QuantityForm quantity={quantity} loading={loading} />
    </Base>
  )
}

export default EditQuantityPage;