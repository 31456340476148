import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import Toggle from "react-toggle";
import classNames from "classnames";
import { UserContext } from "../../core/context";
import { seriesToMovingAverage, createSeries, createChartConf } from "../../core/charts";
import WhiteBox from "../../core/components/WhiteBox";
import LytikoChart from "../../core/components/LytikoChart";
import Loader from "../../core/components/Loader";

const QuantityChart = props => {
  /**
   * A data visualisation for a quantity.
   */

  const { quantity, requestedResolution, setRequestedResolution, additionalSeries, seriesLoading} = props;
  const series = additionalSeries ? additionalSeries : quantity.series;
  const user = useContext(UserContext);

  const [showTrendline, setShowTrendline] = useState(true);
  const resolution = requestedResolution === 0 ? series.resolution : requestedResolution;
  

  if (quantity.series.data.length < 2) {
    return (
      <WhiteBox className="lytiko-chart">
        <div className="notice">Need at least two datapoints to display chart.</div>
      </WhiteBox>
    )
  }

  // Create chart conf
  const options = createChartConf(user.timezone || moment.tz.guess());

  // Add series
  options.series = [createSeries(series)]

  // Add trendline
  const canShowTrendline = series.data.length > 5 && quantity.seriesType !== 2 && (quantity.seriesType !== 3 || options.series[0].componentInfo);
  if (canShowTrendline && showTrendline) {
    options.series.push(seriesToMovingAverage(options.series[0]))
  }

  // Modify if required
  if (series.seriesType === 3 && !options.series[0].componentInfo) {
    options.yAxis.labels = {enabled: false};
    options.yAxis.gridLineWidth = 0;
  } else {
    options.yAxis.labels = {enabled: true};
    options.yAxis.gridLineWidth = 1;
  }

  const requestedResolutionChanged = resolution => {
    setRequestedResolution(resolution === quantity.series.resolution ? 0 : resolution)
  }


  const className = classNames({
    "quantity-chart": true, "has-trendline": canShowTrendline && showTrendline,
  })
  
  return (
    <WhiteBox className={className}>
      {seriesLoading ? (
        <div className="loading-chart"><Loader /></div> 
      ) : <LytikoChart options={options} />}
      <div className="chart-controls">
        <div className="trendline-toggle">
          {canShowTrendline && <div className="toggle-input">
            <Toggle
                id="trendline"
                checked={showTrendline}
                onChange={() => setShowTrendline(!showTrendline)}
            />
            <label htmlFor="trendline">Show Trendline</label>
          </div>}
        </div>
        
        <div className="resolutions">
          {!quantity.series.resolution && (
            <div onClick={() => requestedResolutionChanged(null)} className={`resolution${resolution === null ? " selected" : ""}`}>Minute</div>
          )}
          {(quantity.series.resolution === "D" || quantity.series.resolution === null) && (
            <div onClick={() => requestedResolutionChanged("D")} className={`resolution${resolution === "D" ? " selected" : ""}`}>Day</div>
          )}
          {("DW".includes(quantity.series.resolution) || quantity.series.resolution === null) && (
            <div onClick={() => requestedResolutionChanged("W")} className={`resolution${resolution === "W" ? " selected" : ""}`}>Week</div>
          )}
          {("DWM".includes(quantity.series.resolution) || quantity.series.resolution === null) && (
            <div onClick={() => requestedResolutionChanged("M")} className={`resolution${resolution === "M" ? " selected" : ""}`}>Month</div>
          )}
          {quantity.series.resolution !== "Y" && (
            <div onClick={() => requestedResolutionChanged("Y")} className={`resolution${resolution === "Y" ? " selected" : ""}`}>Year</div>
          )}
        </div>
      </div>
    </WhiteBox>
  )
}

QuantityChart.propTypes = {
  quantity: PropTypes.object.isRequired,
}

export default QuantityChart;