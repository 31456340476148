import React, {useState} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import WhiteBox from "../../core/components/WhiteBox";
import TablePaginator from "../../core/components/TablePaginator";
import MeasurementRow from "./MeasurementRow";

const MeasurementsTable = props => {
  /**
   * A list of measurements.
   */

  const {quantity, tablePage, setTablePage, rowsPerPage, setRowsPerPage} = props;
  const measurements = quantity.measurements.edges.map(edge => edge.node);
  measurements.sort((m1, m2) => m2.datetime - m1.datetime);

  const measurementsToDisplay = measurements.slice(
    (tablePage - 1) * rowsPerPage, tablePage * rowsPerPage
  )

  const [showingDropdown, setShowingDropdown] = useState(false);
  const className = classNames({
    "table-container": true, "showing-dropdown": showingDropdown
  })
  
  return (
    <WhiteBox className="measurements-table">
      {measurements.length > rowsPerPage && <TablePaginator
        rowCount={measurements.length}
        tablePage={tablePage} setTablePage={setTablePage}
        rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage}
      />}
      <div className={className}>
        <div className="table">
          {measurementsToDisplay.map(measurement => {
            return (
              <MeasurementRow
                measurement={measurement}
                quantity={quantity}
                key={measurement.id}
                setShowingDropdown={setShowingDropdown}
              />
            )
          })}
        </div>
      </div>
      {measurements.length > rowsPerPage && <TablePaginator
        rowCount={measurements.length}
        tablePage={tablePage} setTablePage={setTablePage}
        rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage}
      />}
    </WhiteBox>
  )
}

MeasurementsTable.propTypes = {
  quantity: PropTypes.object.isRequired,
  tablePage: PropTypes.number.isRequired,
  setTablePage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
}

export default MeasurementsTable;