import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import Base from "../components/Base";
import { POSSIBLE_COMPARITORS, COMPARISON } from "../queries";
import WhiteBox from "../components/WhiteBox";
import Select from "react-select";
import ComparisonChart from "../components/ComparisonChart";
import { useHistory } from "react-router";

const ComparePage = () => {
  /**
   * The page for comparing two entities.
   */

  useEffect(() => {
    document.title = "Compare - lytiko";
  });

  const history = useHistory();
  const params = new URLSearchParams(history.location.search)
  const comparitor1 = params.get("comparitor1")
  const comparitor2 = params.get("comparitor2");

  const [entity1, setEntity1] = useState(comparitor1);
  const [entity2, setEntity2] = useState(comparitor2);

  const { loading, data } = useQuery(POSSIBLE_COMPARITORS, {fetchPolicy: "network-only"});

  const comparisonQuery = useQuery(COMPARISON, {
    skip: !entity1 || !entity2,
    variables: {entity1, entity2}
  });

  const clear = e => {
    setEntity1(null);
    setEntity2(null);
  }
  
  if (loading) {
    return (
      <Base className="compare-page">
        <h1>Compare</h1>
        <WhiteBox className="selectors" loading={true}>
          <div className="selector" />
          <div className="selector" />
        </WhiteBox>
      </Base>
    )
  }

  const nutrients = [
    "Calories", "Carbohydrates", "Sugar", "Protein", "Fat", "Fibre", "Salt"
  ]
  const comparitors = data.user.quantities.edges.map(edge => edge.node).map(
    quantity => ({value: `quantity-${quantity.id}`, label: quantity.name})
  ).concat(data.user.foods.edges.map(edge => edge.node).map(
    food => ({value: `food-${food.id}`, label: food.name})
  )).concat(data.user.meals.count ? nutrients.map(
    nutrient => ({value: `nutrient-${nutrient}`, label: nutrient})
  ) : []).concat(data.user.timeCategories.edges.map(edge => edge.node).map(
    category => ({value: `timecategory-${category.id}`, label: category.name})
  )).concat(data.user.accounts.edges.map(edge => edge.node).map(
    account => ({value: `account-${account.id}`, label: account.name})
  ));

  if (comparitors.length < 2) {
    return (
      <Base className="compare-page">
        <h1>Compare</h1>
        <WhiteBox className="notice">
          You need at least two trackable entities to make a comparison.
        </WhiteBox>
      </Base>
    )
  }

  return (
    <Base className="compare-page">
      <h1>Compare</h1>

      <WhiteBox className="quantities">
        <Link to="/compare/" onClick={clear} className="clear">Clear</Link>
        <div className="selectors">
          <div className="selector">
            <label>Quantity 1</label>
            <Select
              options={comparitors}
              value={entity1 ? comparitors.filter(c => c.value === entity1)[0] : ""}
              onChange={e => setEntity1(e.value)}
            />
          </div>
          <div className="selector">
            <label>Quantity 2</label>
            <Select
              options={comparitors}
              onChange={e => setEntity2(e.value)}
              value={entity2 ? comparitors.filter(c => c.value === entity2)[0] : ""}
            />
          </div>
        </div>

      </WhiteBox>
      {comparisonQuery.data && !comparisonQuery.loading && (
        <ComparisonChart
          series1={comparisonQuery.data.user.comparison.series1}
          series2={comparisonQuery.data.user.comparison.series2}
          correlation={comparisonQuery.data.user.comparison.correlation}
        />
      )}
      {comparisonQuery.loading && <WhiteBox loading={true} />}
    </Base>
  )
}

export default ComparePage;